import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Progress from '@components/FormComponent/Progress';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing, transitions, typography }) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        listStyle: 'none',
    },
    title: {
        fontWeight: 500,
    },
}));

const PageSelect = props => {
    const { forms, isMarkdown, onChange, title, value } = props;
    const activeForm = forms?.[value];
    const hasMultiplePages = forms.length > 1;

    const typographyProps = {
        noWrap: true,
    };

    const classes = useStyles();
    const toggleActionDrawer = useToggleActionDrawer();

    /**
     *  EVENT HANDLER
     */

    const handleClick = () => {
        toggleActionDrawer({
            title,
            items: forms.map((form, index) => ({
                id: form.id,
                label: (
                    <div className={classes.root}>
                        <Progress forms={[form]} variant="icon" />
                        <Typography {...typographyProps}>{form.name}</Typography>
                    </div>
                ),
                onClick: event => onChange(event, index),
            })),
        });
    };

    return (
        <ListItem
            className={classes.root}
            disableGutters
            {...(hasMultiplePages && {
                button: true,
                onClick: handleClick,
            })}
        >
            <Progress className={classes.progress} forms={[activeForm]} variant="icon" />
            <ListItemText
                primary={activeForm.name}
                primaryTypographyProps={{ ...typographyProps, className: classes.title }}
                secondary={!isMarkdown ? activeForm.description : ''}
                secondaryTypographyProps={typographyProps}
            />
            {hasMultiplePages && <KeyboardArrowDownIcon />}
        </ListItem>
    );
};

PageSelect.propTypes = {
    forms: PropTypes.array.isRequired,
    isMarkdown: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string,
};

export default PageSelect;
