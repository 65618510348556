import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Trigger } from '@geomagic/core';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(({ palette, spacing }) => {
    const backgroundColor = palette.type === 'light' ? palette.grey[400] : palette.grey[600];
    return {
        root: {
            display: 'flex',
            flex: 1,
        },
        swiper: {
            flex: 1,
        },
        mobileStepper: {
            backgroundColor: 'transparent',
            padding: spacing(),
        },
        trigger: {
            background: backgroundColor,
            borderRadius: 0,
            color: palette.getContrastText(backgroundColor),
            minHeight: 32,
            minWidth: 32,
        },
    };
});

const Carousel = props => {
    const classes = useStyles(props);
    const {
        children,
        className,
        mobileStepperProps,
        stepperBackButtonProps,
        stepperNextButtonProps,
        swipeProps,
    } = props;
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = children.length;

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    const iconProps = { color: 'inherit', fontSize: 'large' };

    const stepperNextButton = (
        <Trigger
            className={classes.trigger}
            icon={<ChevronRightIcon {...iconProps} />}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            {...stepperNextButtonProps}
        />
    );

    const stepperBackButton = (
        <Trigger
            className={classes.trigger}
            icon={<ChevronLeftIcon {...iconProps} />}
            onClick={handleBack}
            disabled={activeStep === 0}
            {...stepperBackButtonProps}
        />
    );

    return (
        <div className={classNames(classes.root, className)}>
            <AutoPlaySwipeableViews
                className={classes.swiper}
                containerStyle={{ height: '100%' }}
                autoplay={false}
                index={activeStep}
                onChangeIndex={handleStepChange}
                {...swipeProps}
            >
                {children}
            </AutoPlaySwipeableViews>
            {children.length > 1 && (
                <MobileStepper
                    className={classNames(classes.mobileStepper)}
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={stepperNextButton}
                    backButton={stepperBackButton}
                    {...mobileStepperProps}
                />
            )}
        </div>
    );
};

Carousel.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    mobileStepperProps: PropTypes.object,
    stepperBackButtonProps: PropTypes.object,
    stepperNextButtonProps: PropTypes.object,
    swipeProps: PropTypes.object,
};

export default Carousel;
