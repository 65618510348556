import { Select } from '@geomagic/ol/interaction';
import { singleClick } from '@geomagic/ol/events/condition';

import clearFeatureStyle from './clearFeatureStyle';

/**
 * Add select interaction to OpenLayers map
 * @param {Object} params
 * @param {Object} params.map - OpenLayers map
 * @param {Array.<Object>} params.layers - Array of layer refs
 * @param {function} params.onSelect - Additional select interaction props (https://openlayers.org/en/latest/apidoc/module-ol_interaction_Select.html)
 * @param {Object} params.options - Function which is called after modifying
 * @returns {Object}
 */

const enableSelectInteraction = ({ map, layers, onSelect, options = {} }) => {
    const select = new Select({
        layers: layers.map(layerRef => layerRef.current),
        style: false,
        ...options,
        condition: singleClick,
    });

    select.on('select', event => {
        const { selected, deselected } = event;

        if (deselected.length > 0 || selected.length > 0) {
            clearFeatureStyle(layers);
        }

        onSelect(event, selected[0]);
    });

    map.addInteraction(select);

    return select;
};

export default enableSelectInteraction;
