import dayjs from 'dayjs';
import { i18n } from '@geomagic/i18n';
import { TIME_DISPLAY_FORMAT } from '@consts';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const getSecondaryText = ({ geolocationPositions: positions = [] }) => {
    const startTimestamp = positions[0]?.timestamp;
    const endTimestamp = positions[positions.length - 1]?.timestamp;

    if (startTimestamp && endTimestamp) {
        const start = dayjs(positions[0].timestamp);
        const end = dayjs(positions[positions.length - 1].timestamp);
        const duration = dayjs.duration(end.diff(start)).format(TIME_DISPLAY_FORMAT);

        const countLabel = i18n.t('locationRecording.label.listPoints', { variables: { count: positions.length } });
        const durationLabel = i18n.t('locationRecording.label.duration', { variables: { duration } });

        return `${countLabel} | ${durationLabel}`;
    }
};

export default getSecondaryText;
