import { extend } from '@geomagic/ol/extent';

/**
 * Get the features of a featureCollection
 * @param {Object} map - Openlayers map
 * @param {Object} featureCollection - GeoNAM featureCollection
 * @returns {Array}
 */

const getExtentFromFeatures = features => {
    const extent = features[0].getGeometry().getExtent();

    features.forEach((feature, index) => {
        if (index > 0) {
            extend(extent, feature.getGeometry().getExtent());
        }
    });

    return extent;
};

export default getExtentFromFeatures;
