import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { alpha, makeStyles } from '@material-ui/core/styles';

import MobileStepper from '@material-ui/core/MobileStepper';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { i18n } from '@geomagic/i18n';

import StepperButton from '@components/StepperButton';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing, typography }) => ({
    root: {
        flex: 1,
        backgroundColor: 'transparent',
    },
    mobileDot: {
        backgroundColor: ({ color = 'primary' }) =>
            color === 'default' ? alpha(palette.common.white, 0.25) : alpha(palette[`${color}`].main, 0.25),
    },
    mobileDotActive: {
        backgroundColor: ({ color = 'primary' }) =>
            color === 'default' ? palette.common.white : palette[`${color}`].main,
    },
    trigger: {
        color: ({ color = 'primary' }) => (color === 'default' ? palette.common.white : palette[`${color}`].main),
    },
}));

const ImageStepper = props => {
    const { activeStep, className, isMobile, maxSteps, mobileStepperProps, onBack, onNext, triggerProps } = props;

    const classes = useStyles(props);

    const StepperNextButtonComponent = (
        <StepperButton
            className={classes.trigger}
            text={i18n.t('button.forward')}
            icon={<KeyboardArrowRight />}
            onClick={onNext}
            isMobile={isMobile}
            iconLast
            disabled={activeStep === maxSteps - 1}
            {...triggerProps}
        />
    );

    const StepperBackButtonComponent = (
        <StepperButton
            className={classes.trigger}
            text={i18n.t('button.back')}
            icon={<KeyboardArrowLeft />}
            onClick={onBack}
            isMobile={isMobile}
            disabled={activeStep === 0}
            {...triggerProps}
        />
    );

    return (
        <MobileStepper
            className={classNames(classes.root, className)}
            classes={{ dot: classes.mobileDot, dotActive: classes.mobileDotActive }}
            steps={maxSteps}
            position="static"
            variant="dots"
            activeStep={activeStep}
            nextButton={StepperNextButtonComponent}
            backButton={StepperBackButtonComponent}
            {...mobileStepperProps}
        />
    );
};

ImageStepper.propTypes = {
    activeStep: PropTypes.number,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf(['default', 'primary', 'secondary']),
    isMobile: PropTypes.bool,
    maxSteps: PropTypes.number.isRequired,
    mobileStepperProps: PropTypes.object,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    triggerProps: PropTypes.object,
};

export default ImageStepper;
