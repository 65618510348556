import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import StandardPullToRefresh from 'react-simple-pull-to-refresh';

const useStyles = makeStyles(({ breakpoints, mixins, spacing, zIndex }) => {
    const height = 64;

    return {
        root: {
            position: 'relative',
            '& .ptr__children': {
                display: 'flex',
            },
        },
        refreshingContent: {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            height,
            justifyContent: 'center',
        },
        loadingComponent: {
            height: 32,
        },
    };
});

const PullToRefresh = props => {
    const {
        className,
        children = null,
        dragMultiplier = 0.75,
        indicatorSize = 40,
        isPullable,
        LoadingComponent,
        maxDrag = 350,
        onRefresh,
        style = {},
        topOffset = 0,
    } = props;

    const classes = useStyles(props);

    /**
     *  COMPONENTS
     */

    const RefreshingContentComponent = (
        <div className={classes.refreshingContent}>
            {LoadingComponent && <LoadingComponent className={classes.loadingComponent} />}
        </div>
    );

    return (
        <StandardPullToRefresh
            className={classNames(classes.root, className)}
            dragMultiplier={dragMultiplier}
            indicatorSize={indicatorSize}
            isPullable={isPullable}
            maxDrag={maxDrag}
            onRefresh={onRefresh}
            pullingContent={null}
            pullDownThreshold={80}
            refreshingContent={RefreshingContentComponent}
            style={style}
            topOffset={topOffset}
        >
            {children}
        </StandardPullToRefresh>
    );
};

PullToRefresh.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    dragMultiplier: PropTypes.number,
    indicatorSize: PropTypes.number,
    isPullable: PropTypes.bool,
    LoadingComponent: PropTypes.func,
    maxDrag: PropTypes.number,
    onRefresh: PropTypes.func,
    style: PropTypes.object,
    topOffset: PropTypes.number,
};

export default PullToRefresh;
