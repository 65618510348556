import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';

import getPrimaryText from './getPrimaryText';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
    root: {
        display: 'flex',
    },
    trigger: {
        [breakpoints.down('md')]: {
            height: MOBILE_TRIGGER_SIZE,
            width: MOBILE_TRIGGER_SIZE,
        },
    },
}));

const LocationRecordListActions = props => {
    const { data, onDeleteRecord, onShowOnMap } = props;
    const title = getPrimaryText(data);

    const classes = useStyles();
    const toggleActionDrawer = useToggleActionDrawer();

    /**
     *  EVENT HANDLER
     */

    const handleClickDelete = event => {
        event.stopPropagation();
        onDeleteRecord && onDeleteRecord(data, title);
    };

    const handleOpenDrawer = event => {
        event.stopPropagation();

        toggleActionDrawer({
            title,
            items: menuItems,
        });
    };

    const handleShowOnMap = event => {
        event.stopPropagation();
        onShowOnMap && onShowOnMap(data);
    };

    /**
     *  MENU ITEMS
     */

    const getMenuItems = item => {
        return [
            {
                id: 'showOnMap',
                label: i18n.t('label.showOnMap'),
                onClick: handleShowOnMap,
            },
            {
                color: 'secondary',
                id: 'delete',
                label: i18n.t('label.delete'),
                onClick: handleClickDelete,
            },
        ];
    };

    const menuItems = getMenuItems();

    return (
        <div className={classes.root}>
            <Trigger
                {...DEFAULT_TRIGGER_ICON_PROPS}
                className={classes.trigger}
                icon={<MoreVertIcon />}
                onClick={handleOpenDrawer}
                variant="icon"
            />
        </div>
    );
};

LocationRecordListActions.propTypes = {
    data: PropTypes.object.isRequired,
    onDeleteRecord: PropTypes.func.isRequired,
    onShowOnMap: PropTypes.func.isRequired,
};

export default LocationRecordListActions;
