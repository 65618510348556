import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        margin: spacing(1, 0.5),
        minWidth: 120,
        [breakpoints.down('sm')]: {
            flex: 'initial',
            marginTop: spacing(1.5),
            width: '100%',
        },
        [breakpoints.down('md')]: {
            marginBottom: spacing(1),
        },
    },
    readOnly: {
        pointerEvents: 'none',
    },
}));

const FormElementFieldInput = props => {
    const { context, data, path } = props;
    const { isReadOnly, onUpdate } = context;
    const { mandatory, name, unit, value } = data;

    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleChange = event => {
        const newValue = event.target.value;

        if (value !== newValue) {
            onUpdate({ value: newValue || null }, path, data);
        }
    };

    return (
        <div className={classNames(classes.root, { [classes.readOnly]: isReadOnly })}>
            <TextField
                defaultValue={value}
                fullWidth
                label={name}
                margin="none"
                onBlur={handleChange}
                required={mandatory}
                variant="outlined"
                {...(unit && { InputProps: { endAdornment: <InputAdornment position="end">{unit}</InputAdornment> } })}
            />
        </div>
    );
};

FormElementFieldInput.propTypes = {
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default FormElementFieldInput;
