import flatten from 'lodash/flatten';
import getFormByFeatureId from './getFormByFeatureId';

/**
 * Get a featureCollection by worldId
 * @param {Object} params
 * @param {number} params.parentId - GeoNAM parent entity id
 * @param {Array} params.forms - GeoNAM forms
 * @param {boolean} params.firstProps - Take the props of the first featureCollection that found
 * @returns {Object}
 */

const getFeatureCollection = ({ parentId, forms = [] }) => {
    const featureCollections = flatten(forms.map(form => (form.featureCollections ? form.featureCollections : [])));
    const features = featureCollections.reduce((prev, cur) => {
        return [
            ...prev,
            ...cur.features.map(({ id, properties, ...rest }) => {
                const entity = getFormByFeatureId(forms, id);

                return {
                    id,
                    properties: { ...properties, entity, parentId },
                    ...rest,
                };
            }),
        ];
    }, []);

    return features.length > 0 ? { type: 'FeatureCollection', features } : null;
};

export default getFeatureCollection;
