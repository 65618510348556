import handleFetchError from './handleFetchError';

const push = async ({ database, client, config, entityClasses, mapProps, userId }) => {
    let syncErrors = [];

    for (let i = 0; i < config.length; i++) {
        const { collectionName, onSync, refetchQuery, selector, transform } = config[i];

        try {
            const collection = database[collectionName];
            const modifiedDocs = await collection.find({ selector: { ...selector, userId } }).exec();

            if (modifiedDocs) {
                for (let i = 0; i < modifiedDocs.length; i++) {
                    const doc = modifiedDocs[i];

                    if (onSync) {
                        await onSync({
                            client,
                            database,
                            doc,
                            entityClasses,
                            mapProps,
                            refetchQuery,
                            selector,
                            syncErrors,
                            transform,
                        });
                    }
                }
            }
        } catch (error) {
            handleFetchError({ error });
        }
    }

    if (syncErrors.length > 0) {
        throw new Error(JSON.stringify(syncErrors));
    }
};

export default push;
