import GeoJSON from '@geomagic/ol/format/GeoJSON';

/**
 * Get the features of a featureCollection
 * @param {Object} map - Openlayers map
 * @param {Object} featureCollection - GeoNAM featureCollection
 * @returns {Array}
 */

const getFeatures = (map, features) => {
    const geoJSONFeatures = [];

    features.forEach(feature => {
        const dataProjection = 'EPSG:' + feature.srid;

        const geoJSON = new GeoJSON({
            dataProjection,
            featureProjection: map.getView().getProjection(),
        });

        geoJSONFeatures.push(geoJSON.readFeature(feature));
    });

    return geoJSONFeatures;
};

export default getFeatures;
