import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useSnackbar } from 'notistack';
import deepEqual from 'deep-equal';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useGlobalState } from '@geomagic/core';
import { MutationReLogin } from '@geomagic/geonam-graphql';
import { i18n } from '@geomagic/i18n';

import AppContainer from '@components/AppContainer';
import ServiceWorkerUpdate from '@components/ServiceWorkerUpdate';
import { DEFAULT_STATE_KEY, LOGIN_TOKEN_KEY, LOGIN_WITH_CREDENTIALS_PATH, LOGIN_WITH_TOKEN_PATH } from '@consts';
import initializeGraphqlClient from '@graphql/initialize';
import isNotLoggedInException from '@graphql/isNotLoggedInException';
import reAuthenticateUser from '@graphql/reAuthenticateUser';
import useOnlineStatus from '@utils/useOnlineStatus';

import appConfig from './appConfig';
import translations from './i18n';
import { STATE_LOGGED_OUT } from './state';

i18n.load(translations);

const getRedirectPath = (path, rootPath = '/') => {
    const isLoginPath = [LOGIN_WITH_CREDENTIALS_PATH, LOGIN_WITH_TOKEN_PATH].includes(path);

    return !isLoginPath && path ? path : rootPath;
};

const App = () => {
    const state = useGlobalState();
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const isOnline = useOnlineStatus();
    const clientRef = useRef();
    const graphqlErrorHandlerRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [isInitialized, setInitialized] = useState(false);

    const currentPathname = location.pathname;
    const previousPathname = location?.state?.previousPathname;
    const redirectPath = getRedirectPath(previousPathname);

    const { updateGlobalState, user } = state;

    /**
     *  EVENT HANDLER
     */

    const handleRedirect = useCallback(
        pathname => {
            if (pathname) {
                history.push({ pathname, state: { previousPathname: currentPathname } });
            }
        },
        [currentPathname, history]
    );

    const handleLogin = useCallback(
        (login, customRedirectPath) => {
            const { loginToken, status: loginStatus, user: loginUser } = login;

            if (loginStatus === 'NOT_LOGGED_IN' && !loginUser) {
                enqueueSnackbar(i18n.t('notification.loginError'), {
                    key: 'loginError',
                    preventDuplicate: true,
                    variant: 'error',
                });
            }

            if (loginStatus === 'SECOND_FACTOR_REQUIRED') {
                updateGlobalState({ twoFactorPending: true, loginStatus });
            }

            if (loginStatus === 'LOGGED_IN') {
                const redirectPath = getRedirectPath(customRedirectPath || previousPathname);
                window.localStorage.setItem(LOGIN_TOKEN_KEY, loginToken);
                updateGlobalState({ loginStatus, user: loginUser });
                handleRedirect(redirectPath);
            }
        },
        [enqueueSnackbar, handleRedirect, previousPathname, updateGlobalState]
    );

    const handleLogout = useCallback(async () => {
        const client = clientRef.current;

        handleRedirect(LOGIN_WITH_CREDENTIALS_PATH);
        updateGlobalState(STATE_LOGGED_OUT);
        window.localStorage.removeItem(LOGIN_TOKEN_KEY);
        await client.stop();
        await client.resetStore();
    }, [handleRedirect, updateGlobalState]);

    const handleReLogin = async () => {
        const client = clientRef.current;
        const token = window.localStorage.getItem(LOGIN_TOKEN_KEY) || '';
        const { data } = await client.mutate({ mutation: MutationReLogin, variables: { token } });
        const loginStatus = data?.reLogin.status;
        const user = data?.reLogin.user;

        if (user && loginStatus === 'LOGGED_IN') {
            updateGlobalState({ loginStatus, user });
        } else {
            handleLogout();
        }
    };

    const errorLink = useCallback(
        onError(({ graphQLErrors, networkError, operation, forward, response }) => {
            console.log(graphQLErrors);
            if (graphQLErrors) {
                if (isNotLoggedInException(graphQLErrors)) {
                    return reAuthenticateUser({
                        promise: handleReLogin,
                        operation,
                        forward,
                        onCatchError: handleLogout,
                    });
                }

                for (let error of graphQLErrors) {
                    if (graphqlErrorHandlerRef?.current?.onError) {
                        graphqlErrorHandlerRef.current.onError(error, operation);
                    }
                }
            }

            if (networkError) {
                console.log('NETWORK ERROR', networkError);
            }
        }),
        []
    );

    /**
     *  EFFECTS
     */

    useEffect(() => {
        const initialize = async () => {
            const _client = await initializeGraphqlClient(errorLink);
            clientRef.current = _client;
            setInitialized(true);
        };

        initialize();
    }, [errorLink]);

    useEffect(() => {
        const execute = event => {
            if (event.key === DEFAULT_STATE_KEY) {
                const { loginStatus, user: storedUser } = JSON.parse(event.newValue) || {};
                updateGlobalState({ loginStatus, user: storedUser, isSSODisabled: true });

                !storedUser ? handleLogout() : !deepEqual(user, storedUser) && handleRedirect(redirectPath);
            }
        };

        window.addEventListener('storage', execute);

        return () => {
            window.removeEventListener('storage', execute);
        };
    }, [handleLogout, handleRedirect, redirectPath, updateGlobalState, user]);

    /**
     *  APP STATE
     */

    const appState = {
        ...state,
        history,
        isMobile,
        isOnline,
        location,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onRedirect: handleRedirect,
        redirectPath,
    };

    if (!isInitialized) {
        return null;
    }

    return (
        <ApolloProvider client={clientRef.current}>
            <AppContainer
                appProps={{ viewCode: window.VIEW_CODE, worldCode: window.WORLD_CODE }}
                config={appConfig}
                graphqlErrorHandlerRef={graphqlErrorHandlerRef}
                state={appState}
            />
            <ServiceWorkerUpdate />
        </ApolloProvider>
    );
};

export default App;
