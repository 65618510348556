import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';

import { FORM_COMPONENT_MIN_HEIGHT } from '../consts';
import getKey from '../utils/getKey';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        margin: spacing(1, 0.5),
        overflow: 'hidden',
        [breakpoints.down('sm')]: {
            width: '100%',
        },
        [breakpoints.down('md')]: {
            marginBottom: spacing(1),
        },
    },
    buttonGroup: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
        [breakpoints.down('sm')]: {
            flex: 1,
        },
    },
    button: {
        minHeight: FORM_COMPONENT_MIN_HEIGHT,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&$selected': {
            backgroundColor: `${palette.primary.light} !important`,
            color: `${palette.getContrastText(palette.primary.main)} !important`,
        },
        '& span': {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [breakpoints.down('sm')]: {
            flex: 1,
        },
    },
    readOnly: {
        pointerEvents: 'none',
    },
    selected: {},
    mandatory: {
        alignSelf: 'flex-start',
        paddingLeft: spacing(0.5),
        marginTop: -spacing(0.5),
    },
}));

const EnumToggle = props => {
    const { context, data, path } = props;
    const { isReadOnly, onUpdate } = context;
    const { mandatory, statusValue, statusValues } = data;

    const [selectedStatusValue, setSelectedStatusValue] = useState(statusValue);
    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleChange = (event, value) => {
        const newStatusValue = statusValues.find(({ name: statusValueName }) => statusValueName === value) || null;

        if (statusValue !== newStatusValue) {
            setSelectedStatusValue(newStatusValue);
            onUpdate({ statusValue: newStatusValue }, path, data);
        }
    };

    return (
        <div className={classNames(classes.root, { [classes.readOnly]: isReadOnly })}>
            <ToggleButtonGroup
                className={classes.buttonGroup}
                exclusive
                onChange={handleChange}
                value={selectedStatusValue && selectedStatusValue.name}
            >
                {statusValues.map(({ name: statusValueName }, index) => (
                    <ToggleButton
                        key={getKey(statusValueName, index)}
                        className={classes.button}
                        classes={{ selected: classes.selected }}
                        value={statusValueName}
                    >
                        {statusValueName}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {mandatory && (
                <Typography className={classes.mandatory} variant="body2">
                    {'*'}
                </Typography>
            )}
        </div>
    );
};

EnumToggle.propTypes = {
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default EnumToggle;
