import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import UploadIcon from '@material-ui/icons/CloudUpload';

import { i18n } from '@geomagic/i18n';

import showModal from '@utils/showModal';
import DocumentListImageViewer from '@components/DocumentListImageViewer';
import DocumentUpload from '@components/DocumentUpload';
import { DEFAULT_DOCUMENT_ACCEPT_FORMATS } from '@consts';

import { FORM_COMPONENT_MIN_HEIGHT } from '../consts';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        margin: spacing(1, 0.5),
        minHeight: FORM_COMPONENT_MIN_HEIGHT,
        [breakpoints.down('md')]: {
            marginBottom: spacing(1),
        },
    },
    button: {
        background: palette.primary.light,
        color: palette.getContrastText(palette.primary.main),
    },
    buttonFileUpload: {
        background: palette.primary.light,
        border: 'none !important',
        borderBottomRightRadius: `${shape.borderRadius}px !important`,
        borderTopRightRadius: `${shape.borderRadius}px !important`,
        color: palette.getContrastText(palette.primary.main),
        '@media (hover: none)': {
            background: `${palette.primary.light} !important`,
            color: `${palette.getContrastText(palette.primary.main)} !important`,
        },
    },
}));

const FormElementDocuments = props => {
    const {
        accept = DEFAULT_DOCUMENT_ACCEPT_FORMATS,
        context,
        data,
        doc,
        documentLabel = i18n.t('assignment.button.documents'),
        parentName,
        path,
        PlaceholderIconComponent,
        placeholderSubtitle,
        placeholderTitle,
        UploadIconComponent = UploadIcon,
    } = props;
    const { entityClasses, isMobile, isReadOnly, onUpdate } = context;
    const { documents = [], mandatory } = data;
    const label = documentLabel.concat(!!documents.length ? ` (${documents.length})` : '', mandatory ? ' *' : '');

    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleChange = async newDocuments => {
        onUpdate({ documents: newDocuments }, path, data);
    };

    const handleShowDocuments = () => {
        showModal({
            title: `${documentLabel} ${parentName}`,
            content: (
                <DocumentListImageViewer
                    doc={doc}
                    documents={documents}
                    isMobile={isMobile}
                    PlaceholderIconComponent={PlaceholderIconComponent}
                    placeholderSubtitle={placeholderSubtitle}
                    placeholderTitle={placeholderTitle}
                    onDelete={handleChange}
                />
            ),
            isFullScreen: true,
        });
    };

    return (
        <div className={classes.root}>
            <ButtonGroup color="primary" disableElevation size="medium" variant="contained">
                <Button className={classes.button} disabled={documents.length < 1} onClick={handleShowDocuments}>
                    {label}
                </Button>
                {!isReadOnly && (
                    <DocumentUpload
                        accept={accept}
                        className={classes.buttonFileUpload}
                        doc={doc}
                        documents={documents}
                        entityClasses={entityClasses}
                        isMobile={isMobile}
                        icon={<UploadIconComponent />}
                        onChange={handleChange}
                    />
                )}
            </ButtonGroup>
        </div>
    );
};

FormElementDocuments.propTypes = {
    accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
    documentLabel: PropTypes.string,
    path: PropTypes.string.isRequired,
    parentName: PropTypes.string,
    PlaceholderIconComponent: PropTypes.object,
    placeholderSubtitle: PropTypes.string,
    placeholderTitle: PropTypes.string,
    UploadIconComponent: PropTypes.object,
};

export default FormElementDocuments;
