import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { TIMESTAMP_DISPLAY_FORMAT } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { DATE_DISPLAY_FORMAT } from '@consts';

import convertDateFormat from './convertDateFormat';
import getMomentLocale from './getMomentLocale';
import getNumberFormatObject from './getNumberFormatObject';
import getRawAttributeValue from './getRawAttributeValue';
import getTypedAttributeValue from './getTypedAttributeValue';

/**
 * Get a formatted value by specific GeoNAM attribute type
 * @param {Object} attributeType - GeoNAM attribute type
 * @param {string} value
 * @returns {*}
 */

const getFormattedAttributeValue = (attributeType, value) => {
    let format, locale;
    const { options, allowedValues } = attributeType;

    const isEnumeration = allowedValues && allowedValues.length;
    if (isEnumeration && value) {
        const selectedAllowedValue = allowedValues.find(
            allowedValue =>
                getTypedAttributeValue(attributeType, allowedValue.value) ===
                getTypedAttributeValue(attributeType, value)
        );
        if (selectedAllowedValue && selectedAllowedValue.name) {
            return selectedAllowedValue.name;
        }
    }

    switch (attributeType.dataType) {
        case 'BOOLEAN':
            const { trueLabel, falseLabel } = options;
            return value === 'true' || (typeof value === 'boolean' && value === true)
                ? trueLabel
                    ? trueLabel
                    : i18n.t('default.yes')
                : value === 'false' || (typeof value === 'boolean' && value === false)
                ? falseLabel
                    ? falseLabel
                    : i18n.t('default.no')
                : '';
        case 'INT':
        case 'FLOAT':
        case 'FRACTION':
        case 'CURRENCY':
            return value ? (
                <NumberFormat value={Number(value)} displayType={'text'} {...getNumberFormatObject(attributeType)} />
            ) : null;
        case 'DATE':
            locale = getMomentLocale();
            format =
                options && options.pattern ? convertDateFormat(options.pattern) : locale ? 'L' : DATE_DISPLAY_FORMAT;
            return value ? moment(value).format(format) : null;
        case 'TIMESTAMP':
            locale = getMomentLocale();
            format =
                options && options.pattern
                    ? convertDateFormat(options.pattern)
                    : locale
                    ? 'LT'
                    : TIMESTAMP_DISPLAY_FORMAT;
            return value ? moment(value).format(format) : null;
        default:
            return getRawAttributeValue(attributeType, value);
    }
};

export default getFormattedAttributeValue;
