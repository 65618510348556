const getDefaultSchema = params => ({
    ...params,
    title: 'GeoNAM offline entity schema',
    version: 3,
    primaryKey: 'uuid',
    type: 'object',
    properties: {
        uuid: {
            type: 'string',
        },
        createdOn: {
            type: 'number',
        },
        modifiedOn: {
            type: 'number',
        },
        entity: {
            type: 'object',
        },
        jsonPatch: {
            type: ['array', 'null'],
        },
        draft: {
            type: ['object', 'null'],
        },
        offlineMap: {
            type: ['object', 'null'],
        },
        relations: {
            type: ['array', 'null'],
        },
        remoteVersion: {
            type: 'number',
        },
        userId: {
            type: ['number', 'null'],
        },
    },
    attachments: {},
    indexes: ['createdOn', 'modifiedOn'],
});

export default getDefaultSchema;
