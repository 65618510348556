import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

import getPrimaryText from './getPrimaryText';
import getSecondaryText from './getSecondaryText';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
    root: {},
}));

const LocationRecordListText = props => {
    const { data } = props;
    const classes = useStyles();

    const listItemTypographyProps = {
        noWrap: true,
    };

    return (
        <ListItemText
            className={classes.root}
            primary={getPrimaryText(data)}
            primaryTypographyProps={{ ...listItemTypographyProps, variant: 'body1' }}
            secondary={getSecondaryText(data)}
            secondaryTypographyProps={listItemTypographyProps}
        />
    );
};

LocationRecordListText.propTypes = {
    data: PropTypes.object.isRequired,
};

export default LocationRecordListText;
