const STATE_SAVE_KEYS = ['lastFetch', 'locationRecording', 'loginStatus', 'preferences', 'user'];

const STATE_LOGGED_OUT = {
    loginStatus: null,
    user: null,
};

const STATE = {
    ...STATE_LOGGED_OUT,
    lastFetch: null,
    locationRecording: null,
    preferences: {
        isMapVisible: true,
    },
};

export { STATE, STATE_LOGGED_OUT, STATE_SAVE_KEYS };
