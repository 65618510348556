import gql from 'graphql-tag';

import FragmentChecklistItems from './FragmentCheckListItems';
import FragmentEntity from './FragmentEntity';
import FragmentJournal from './FragmentJournal';
import FragmentProcessInstances from './FragmentProcessInstances';

const FragmentDispatch = gql`
    ${FragmentChecklistItems}
    ${FragmentEntity}
    ${FragmentJournal}
    ${FragmentProcessInstances}

    fragment Dispatch on Entity {
        ...ChecklistItems
        ...Entity
        ...Journal
        ...ProcessInstances
    }
`;

export default FragmentDispatch;
