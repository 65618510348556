import React from 'react';
import { DEFAULT_PICKER_PROPS, DEFAULT_TEXT_FIELD_PROPS } from '@consts';
import SingleSelect from '../components/SingleSelect';

import getDateFormatObject from './getDateFormatObject';
import getFormattedAttributeValue from './getFormattedAttributeValue';
import getNumberFormatObject from './getNumberFormatObject';
import getTypedAttributeValue from './getTypedAttributeValue';

/**
 * Get Autoform schema and ui by attribute type
 * @param {Object} attributeType - GeoNAM attribute type
 * @param {Object} entity - GeoNAM entity
 * @returns {Object}
 */

const getAutoFormPropsByAttributeType = (attributeType, entity, isReadOnly) => {
    let enumList;
    let fieldUI = {};
    let type;
    let schemaProps = {};

    const { options } = attributeType;

    switch (attributeType.dataType) {
        case 'STRING':
        case 'LINK':
        case 'EMAIL':
            const { rows, regex, minLength, maxLength } = options;
            type = 'string';
            if (rows && rows > 1) {
                fieldUI.rows = rows;
                fieldUI.multiline = true;
            }
            fieldUI = {
                ...fieldUI,
                ...DEFAULT_TEXT_FIELD_PROPS,
            };
            schemaProps = {
                ...(regex && { pattern: regex }),
                ...(minLength && { minLength }),
                ...(maxLength && { maxLength }),
            };
            break;
        case 'INT':
        case 'FLOAT':
        case 'FRACTION':
        case 'CURRENCY':
            if (!enumList) {
                const { minValue, maxValue } = options;
                fieldUI = {
                    ...fieldUI,
                    ...getNumberFormatObject(attributeType),
                    isFormatted: true,
                    ...DEFAULT_TEXT_FIELD_PROPS,
                };
                schemaProps = {
                    ...(minValue && { minimum: minValue }),
                    ...(maxValue && { maximum: maxValue }),
                };
            }
            type = 'number';
            break;
        case 'BOOLEAN':
            type = 'boolean';
            break;
        case 'DATE':
        case 'TIMESTAMP':
            fieldUI = {
                ...fieldUI,
                ...getDateFormatObject(attributeType, entity),
                isDate: true,
                ...(attributeType.dataType === 'TIMESTAMP' && { isTime: true }),
                isKeyboardInput: true,
                pickerProps: {
                    ...DEFAULT_PICKER_PROPS,
                },
            };
            type = 'number';
            break;
        default:
            break;
    }

    if (attributeType.allowedValues && attributeType.allowedValues.length > 0) {
        enumList = attributeType.allowedValues.map(allowedValue =>
            getTypedAttributeValue(attributeType, allowedValue.value)
        );
        fieldUI = fieldUIProps => {
            const { updateField, value, label, error } = fieldUIProps;

            return (
                <SingleSelect
                    disableClearable={false}
                    disabled={attributeType.readOnly || isReadOnly}
                    error={error}
                    getOptionLabel={item => getFormattedAttributeValue(attributeType, item)}
                    getOptionSelected={(option, item) => {
                        return option === item;
                    }}
                    isAutocomplete={type === 'number' || enumList.length < 5 ? false : undefined}
                    label={label}
                    onChange={(event, newValue) => {
                        updateField(newValue);
                    }}
                    options={enumList}
                    required={attributeType.mandatory}
                    value={value}
                />
            );
        };
    }

    if (attributeType.readOnly || isReadOnly) {
        fieldUI.disabled = true;
    } else if (attributeType.readOnly || isReadOnly) {
        fieldUI = { disabled: true };
    }

    return {
        fieldSchema: {
            type,
            title: attributeType.name,
            enum: enumList,
            grouping: attributeType.grouping,
            ...schemaProps,
        },
        fieldUI,
    };
};

export default getAutoFormPropsByAttributeType;
