import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import getImportPathByType from './utils/getImportPathByType';

const MODULE_PATH = './elements/';

const useDynamicComponent = path => {
    const component = React.useRef();
    const [error, setError] = React.useState();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const getComponent = async () => {
            try {
                const comp = await import(/* webpackMode: "eager" */ `${path}`);
                component.current = comp.default;
            } catch (err) {
                console.log(err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        path && getComponent();
    }, [path]);

    return { Component: component.current, loading, error };
};

const FormElement = props => {
    const { context, data, ...elementProps } = props;
    const importPath = getImportPathByType(MODULE_PATH, data);
    const { Component, loading, error } = useDynamicComponent(importPath);

    if (error) {
        console.warn('COMPONENT NOT FOUND');
        return null;
    }

    return !loading && <Component context={context} data={data} {...elementProps} />;
};

FormElement.propTypes = {
    children: PropTypes.node,
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default FormElement;
