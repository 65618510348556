/**
 * A dialog for adding entries to the journal.
 *
 */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DefaultAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';

import { notifyUser, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';

import {
    DATE_FORMAT,
    MOBILE_TRIGGER_SIZE,
    DEFAULT_TEXT_FIELD_PROPS,
    DEFAULT_TRIGGER_ICON_PROPS,
    DATE_DISPLAY_FORMAT,
} from '@consts';
import getPatch from '@database/getPatch';
import useShowPrompt from '@utils/useShowPrompt';

import Chat from './Chat';

const useStyles = makeStyles(({ palette, shape, spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(0, 1),
    },
    bottomToolbar: {
        background: palette.background.default,
        width: '100%',
        padding: spacing(1),
    },
    user: {
        marginRight: spacing(),
    },
    textField: {
        background: palette.background.paper,
        border: `1px solid ${palette.divider}`,
        borderRadius: shape.borderRadius,
        margin: spacing(0),
        marginRight: spacing(),
    },
    showAllTrigger: {
        margin: 'auto',
        width: 'fit-content',
    },
    toolbarTrigger: {
        height: MOBILE_TRIGGER_SIZE,
        width: MOBILE_TRIGGER_SIZE,
    },
    headerTrigger: {
        marginRight: spacing(1),
    },
    actions: {
        display: 'flex',
    },
}));

const USER_COMMENT_TYPE = 'USER_COMMENT';

const getBaseEntry = message => {
    return {
        date: dayjs().format(DATE_FORMAT),
        message,
    };
};

const Journal = props => {
    const { data, user } = props;

    const classes = useStyles(props);
    const [, setJournalUpdated] = useState(false);

    const [message, setMessage] = useState('');
    const [activeCommentIndex, setActiveCommentIndex] = useState(undefined);

    const showPrompt = useShowPrompt();

    const chatRef = useRef();

    const entity = data?.getPatchedEntity();
    const journal = entity?.journal || [];

    const scrollToEnd = () => {
        const top = document.getElementById('chat')?.offsetHeight;
        chatRef.current.scrollTo({ top, behaviour: 'smooth' });
    };

    /**
     *  GENERAL PROPS
     */

    const toolbarTriggerProps = {
        className: classes.toolbarTrigger,
        ...DEFAULT_TRIGGER_ICON_PROPS,
    };

    /*
     *  EVENT HANDLER
     */

    const handleAddComment = event => {
        const order = Math.min(...journal.map(({ order }) => order), 0);

        const newEntry = {
            ...getBaseEntry(message),
            order: order - 1,
            type: USER_COMMENT_TYPE,
            user: { id: user.id, fullName: user.fullName },
        };

        const newPatch = {
            op: 'replace',
            path: `/journal`,
            value: [newEntry, ...journal],
        };

        handleUpdateJournal(newPatch).then(() => {
            notifyUser({
                type: 'success',
                message: i18n.t('journal.notification.commentAdded'),
            });
        });

        handleResetEdit();
        scrollToEnd();
    };

    const handleChangeMessage = event => {
        setMessage(event.target.value);
    };

    const handleClickEdit = index => () => {
        const { message: prevMessage } = journal[index] || {};

        setActiveCommentIndex(index);
        setMessage(prevMessage);
    };

    const handleDeleteComment = index => () => {
        showPrompt({
            title: i18n.t('journal.dialog.deleteComment.title'),
            content: i18n.t('journal.dialog.deleteComment.content'),
            onOk: () => {
                const updatedJournal = journal.filter((entry, idx) => index !== idx);

                const newPatch = {
                    op: 'replace',
                    path: `/journal`,
                    value: updatedJournal,
                };

                handleUpdateJournal(newPatch).then(() => {
                    notifyUser({
                        type: 'success',
                        message: i18n.t('journal.notification.commentDeleted'),
                    });
                });

                handleResetEdit();
            },
        });
    };

    const handleResetEdit = () => {
        setActiveCommentIndex(undefined);
        setMessage('');
    };

    const handleUpdateComment = event => {
        const updatedJournal = journal.map((entry, index) => {
            if (activeCommentIndex === index) {
                const currentEntry = journal[activeCommentIndex];

                return { ...currentEntry, ...getBaseEntry(message) };
            } else {
                return entry;
            }
        });

        const newPatch = {
            op: 'replace',
            path: `/journal`,
            value: updatedJournal,
        };

        handleUpdateJournal(newPatch).then(() => {
            notifyUser({
                type: 'success',
                message: i18n.t('journal.notification.commentChanged'),
            });
        });

        handleResetEdit();
    };

    const handleUpdateJournal = async newPatch => {
        const jsonPatch = data?.jsonPatch;
        await data.atomicUpdate(oldData => {
            oldData.jsonPatch = getPatch(jsonPatch, newPatch);
            return oldData;
        });
        setJournalUpdated(prevJournalUpdated => !prevJournalUpdated);
    };

    /**
     * EFFECTS
     */

    useEffect(() => {
        if (!!data) {
            scrollToEnd();
        }
    }, [data]);

    /*
     *  COMPONENTS
     */

    const getActions = () => {
        let Component;
        const isDisabled = !message;

        if (!isNaN(activeCommentIndex)) {
            const { message: prevMessage } = journal[activeCommentIndex] || {};
            const isDisabledSend = isDisabled || prevMessage === message;

            Component = (
                <Fragment>
                    <Trigger {...toolbarTriggerProps} onClick={handleResetEdit} icon={<ClearIcon />} />
                    <Trigger
                        {...toolbarTriggerProps}
                        disabled={isDisabledSend}
                        icon={<CheckIcon />}
                        onClick={handleUpdateComment}
                    />
                </Fragment>
            );
        } else {
            Component = (
                <Trigger
                    {...toolbarTriggerProps}
                    disabled={isDisabled}
                    icon={<SendIcon />}
                    onClick={handleAddComment}
                />
            );
        }

        return Component;
    };

    const getMessageHeader = (params, index) => {
        const { id: commentId, user: messageUser, date, isSelf } = params;
        const isDisabled = !isNaN(activeCommentIndex) && activeCommentIndex !== index;

        return (
            <Fragment>
                <div>
                    {!isSelf && (
                        <Typography variant="caption" className={classes.user}>
                            {messageUser.fullName}
                        </Typography>
                    )}
                    <Typography variant="caption" color="textSecondary">
                        {dayjs(date).format(DATE_DISPLAY_FORMAT)}
                    </Typography>
                </div>
                {isSelf && !commentId && (
                    <div>
                        <Trigger
                            className={classes.headerTrigger}
                            disabled={isDisabled}
                            icon={<EditIcon fontSize="inherit" />}
                            onClick={handleClickEdit(index)}
                        />
                        <Trigger
                            className={classes.headerTrigger}
                            disabled={isDisabled}
                            icon={<DeleteIcon fontSize="inherit" />}
                            onClick={handleDeleteComment(index)}
                        />
                    </div>
                )}
            </Fragment>
        );
    };

    return (
        <div className={classes.root}>
            <Fragment>
                <ContentRoot className={classes.content} scrollable ref={chatRef}>
                    <Chat id="chat" chat={journal} user={user} onFormatHeader={getMessageHeader} reverse />
                </ContentRoot>
                <DefaultAppBar position="static" color="inherit">
                    <Toolbar className={classes.bottomToolbar}>
                        <TextField
                            {...DEFAULT_TEXT_FIELD_PROPS}
                            className={classNames(classes.textField)}
                            autoFocus
                            multiline
                            fullWidth
                            value={message}
                            onChange={handleChangeMessage}
                        />
                        <div className={classes.actions}>{getActions()}</div>
                    </Toolbar>
                </DefaultAppBar>
            </Fragment>
        </div>
    );
};

Journal.propTypes = {
    data: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default Journal;
