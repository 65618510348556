/**
 * A component to add a checklist item.
 *
 * The item can be added by pressing the icon button or the enter key.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddOutlined';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { MOBILE_TRIGGER_SIZE } from '@consts';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    addItem: {
        maxWidth: 400,
        padding: spacing(),
        [breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    },
    trigger: {
        [breakpoints.down('md')]: {
            height: MOBILE_TRIGGER_SIZE,
            width: MOBILE_TRIGGER_SIZE,
        },
    },
}));

const AddChecklistItem = props => {
    const { handleAddNewItem } = props;
    const classes = useStyles(props);

    const [value, setValue] = useState('');

    /**
     *  EVENT HANDLER
     */

    const handleAddValue = () => {
        if (value.length > 0) {
            handleAddNewItem(value);
            setValue('');
        }
    };

    const handleKeyPressAddOption = event => {
        if (event.keyCode === 13) {
            handleAddValue();
        }
    };

    /**
     *  COMPONENTS
     */

    const EndAdornmentComponent = (
        <Tooltip title={i18n.t('checklist.tooltip.addNewItem')}>
            <span>
                <Trigger disabled={value.length === 0} icon={<AddIcon />} onClick={handleAddValue} />
            </span>
        </Tooltip>
    );

    return (
        <TextField
            className={classes.addItem}
            fullWidth
            onChange={event => setValue(event.target.value)}
            onKeyDown={handleKeyPressAddOption}
            value={value}
            placeholder={i18n.t('checklist.placeholder.newItem')}
            variant="outlined"
            InputProps={{
                endAdornment: EndAdornmentComponent,
            }}
        />
    );
};

AddChecklistItem.propTypes = {
    handleAddNewItem: PropTypes.func.isRequired,
};

export default AddChecklistItem;
