import { DEFAULT_CENTER_OPTIONS } from '../consts';

/**
 * Center view by coordinates
 * @param {Object} params
 * @param {Object} params.view - OpenLayers view
 * @param {Array.<number>} params.coordinates - Coordinates to center
 * @param {Object} params.options - Animation options witout center (https://openlayers.org/en/latest/apidoc/module-ol_View.html#~AnimationOptions)
 * @returns {void}
 */

const centerView = ({ view, coordinates, options }) => {
    view.animate({ ...DEFAULT_CENTER_OPTIONS, ...options, center: coordinates });
};

export default centerView;
