import gql from 'graphql-tag';

import FragmentDispatch from '../fragments/FragmentDispatch';

const QueryDispatches = gql`
    ${FragmentDispatch}

    query QueryEntities(
        $filter: FilterInput!
        $pagination: PaginationInput
        $srid: Int
        $withHistory: Boolean = false
        $worldId: NID
    ) {
        entities(filter: $filter, pagination: $pagination) {
            values {
                ...Dispatch
            }
            totalValueCount
        }
    }
`;

export default QueryDispatches;
