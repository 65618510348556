import getExtendedEntity from './getExtendedEntity';

const updateDoc = async ({ doc, entity, mapProps, modifiedOn, remoteVersion, userId }) => {
    const extendedEntity = getExtendedEntity(entity, mapProps);

    const updatedDoc = await doc.atomicUpdate(oldData => {
        oldData.entity = extendedEntity;
        oldData.modifiedOn = modifiedOn;
        oldData.draft = null;
        oldData.jsonPatch = null;
        oldData.remoteVersion = remoteVersion;
        oldData.userId = userId;
        return oldData;
    });

    return updatedDoc;
};

export default updateDoc;
