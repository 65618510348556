/**
 * Add long click handler to openlayers map
 * @param {Object} layers - Openlayers map
 * @param {function} onLongClick - Event handler for long click on the map
 * @param {number} delay - Delay for longPress interaction
 * @returns {void}
 */

const enableLonglickInteraction = (map, onLongClick, delay = 500) => {
    let longClick = false;
    let startTime;
    let endTime;

    map.on('click', function(event) {
        const features = map.getFeaturesAtPixel(event.pixel, feature => feature);

        if (longClick) {
            onLongClick(event, features);
        }
    });

    map.on('pointerdown', function() {
        startTime = new Date().getTime();
    });
    map.on('pointerup', function() {
        endTime = new Date().getTime();
        longClick = endTime - startTime < delay ? false : true;
    });
};
export default enableLonglickInteraction;
