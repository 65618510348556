const translations = {
    de: {
        // Default

        'default.finished': 'Abgeschlossen',
        'default.inProgress': 'In Bearbeitung',
        'default.open': 'Offen',
        'default.type': 'Typ',

        // Button keys

        'button.add': 'Hinzufügen',
        'button.back': 'Zurück',
        'button.backToHome': 'Zurück zur Startseite',
        'button.cancel': 'Abbrechen',
        'button.close': 'Schließen',
        'button.delegate': 'Zuweisen',
        'button.dispatches': 'Meldungen (__amount__)',
        'button.forward': 'Weiter',
        'button.handOff': 'Abgeben',
        'button.logout': '__username__ abmelden',
        'button.ok': 'Ok',
        'button.refresh': 'Aktualisieren',
        'button.reload': 'Neu laden',
        'button.save': 'Speichern',
        'button.takeOver': 'Übernehmen',
        'button.uploadDocument': 'Dokument hinzufügen',
        'button.uploadImage': 'Bild hinzufügen',

        // Dialog keys

        'dialog.addDocument.title': 'Dokument hinzufügen',
        'dialog.deleteDocument.title': 'Dokument löschen',
        'dialog.deleteDocument.content': 'Wollen Sie dieses Dokument wirklich löschen?',
        'dialog.deleteImage.title': 'Bild löschen',
        'dialog.deleteImage.content': 'Wollen Sie dieses Bild wirklich löschen?',
        'dialog.removeGeometry.title': 'Geometrie löschen',
        'dialog.removeGeometry.content': 'Wollen Sie diese Geometrie wirklich löschen?',
        'dialog.tasks.title': 'Aufgaben für __name__',

        // Label keys

        'label.delete': 'Löschen',
        'label.download': 'Herunterladen',
        'label.draft': 'Entwurf',
        'label.editWebApp': 'In __webDavAppName__ bearbeiten',
        'label.fileName': 'Dateiname',
        'label.lastFetch': 'Wann wurden zuletzt Daten abgerufen?',
        'label.message': 'Logbucheintrag',
        'label.noLastFetch': 'Es wurden keine Daten abgerufen',
        'label.offlineDocuments': 'Gespeicherte Bilder',
        'label.preference.general': 'Allgemeine Einstellungen',
        'label.preference.map': 'Karteneinstellungen',
        'label.showOnMap': 'Auf der Karte anzeigen',
        'label.ssoLogin': 'Anmeldung mit Single Sign-on',
        'label.sync': 'Synchronisierung',
        'label.updateAvailable': 'Neue Version verfügbar',
        'label.user': 'Nutzer',

        // Map keys

        'map.default.background': 'Hintergrund',
        'map.default.layers': 'Ebenen',
        'map.dialog.navigation.title': 'Navigation starten',
        'map.dialog.navigation.content.coordinate': 'Wollen Sie die Navigation zur Koordinate __coordinate__ starten?',
        'map.dialog.navigation.content.feature': 'Wollen Sie die Navigation zum Objekt __feature__ starten?',
        'map.label.errorTreeLayerLoading': 'Ebene noch nicht geladen',
        'map.label.errorZoomLevelBoundsZoomOut': 'Nicht sichtbar, zoomen sie heraus',
        'map.label.errorZoomLevelBoundsZoomIn': 'Nicht sichtbar, zoomen sie hinein',
        'map.tooltip.saveSelectedGeometry': 'Ausgewählte Geometrie speichern',
        'map.tooltip.removeSelectedGeometry': 'Ausgewählte Geometrie löschen',

        // Offline map keys

        'offlineMap.dialog.remove.title': 'Offlinekarte löschen',
        'offlineMap.dialog.remove.content': 'Wollen Sie die Offlinekarte wirklich löschen?',
        'offlineMap.label': 'Offlinekarte',
        'offlineMap.label.create': 'Offlinekarte erstellen',
        'offlineMap.label.delete': 'Offlinekarte löschen',
        'offlineMap.label.update': 'Offlinekarte aktualisieren',
        'offlineMap.notification.download.error':
            'Offlinekarte konnte nicht erstellt werden. Es sind keine kompatiblen Hintergrundkarten / thematische Ebenen konfiguriert.',
        'offlineMap.notification.fetchError': 'Es kann keine Verbindung zum Server hergestellt werden.',
        'offlineMap.notification.loading': 'Offlinekarte wird erstellt.',
        'offlineMap.notification.loadingFinished': 'Offlinekarte wurde erfolgreich erstellt.',

        // Notification keys

        'notification.addedGeometry': 'Geometrie wurde hinzugefügt.',
        'notification.closedDraft': 'Entwurf wurde abgeschlossen.',
        'notification.couldNotDownload': 'Dokument konnte nicht heruntergeladen werden.',
        'notification.deletedDocument': 'Dokument wurde gelöscht.',
        'notification.fileNameAlreadyExisists': 'Dieser Dateiname existiert bereits.',
        'notification.imageAlreadyExisists': 'Dieses Bild existiert bereits.',
        'notification.imageAlreadyExisistsAndVersioned': 'Dieses Bild existiert bereits und wird versioniert.',
        'notification.loginError': 'Anmeldedaten inkorrekt',
        'notification.noSyncChanges': 'Es gibt keine Daten zum synchronisieren.',
        'notification.populateMandatoryFields': 'Bitte füllen Sie alle Pflichtfelder aus',
        'notification.refetchedData': 'Daten aus der Instandhaltung wurden abgerufen.',
        'notification.refetchFailed': 'Daten aus der Instandhaltung konnten nicht abgerufen werden.',
        'notification.removedGeometry': 'Geometrie wurde gelöscht.',
        'notification.syncedData': 'Daten wurden synchronisiert.',
        'notification.updatedGeometry': 'Geometrie wurde aktualisiert.',
        'notification.uploadDocument': 'Dokument wird hinzugefügt.',
        'notification.uploadedDocument': 'Dokument wurde hinzugefügt.',
        'notification.uploadGPX': 'GPX-Datei wird hinzugefügt.',
        'notification.uploadedGPX': 'GPX-Datei wurde hinzugefügt.',
        'notification.uploadedImage': 'Bild wurde hinzugefügt.',

        // Placeholder keys

        'placeholder.loading': 'Wird geladen',
        'placeholder.noEntries.subtitle': 'Derzeit sind keine Einträge vorhanden',
        'placeholder.noEntries.title': 'Keine Einträge',
        'placeholder.noOptions': 'Keine Optionen',
        'placeholder.noUserAssigned': '<Kein Benutzer zugewiesen>',
        'placeholder.networkNotFound.subtitle': 'Bitte wenden Sie sich an den technischen Support',
        'placeholder.networkNotFound.title': 'Server nicht erreichbar',
        'placeholder.routeNotFound.subtitle':
            'Bitte überprüfen Sie aufgerufene Adresse oder gehen Sie zurück zur Startseite',
        'placeholder.routeNotFound.title': 'Seite nicht gefunden',
        'placeholder.uploadDocument.subtitle': 'Fügen Sie ein Dokument hinzu',
        'placeholder.uploadDocument.title': 'Dokument hinzufügen',
        'placeholder.uploadImage.subtitle': 'Fügen Sie ein Bild hinzu',
        'placeholder.uploadImage.title': 'Bild hinzufügen',

        // Status keys

        'status.CLOSED': 'Abgeschlossen',
        'status.CRITICAL': 'Kritisch',
        'status.IN_PROGRESS': 'In Bearbeitung',
        'status.NONE': 'Inaktiv',
        'status.OPEN': 'Offen',

        // Synchronization keys

        'synchronization.button.sendToService': 'Senden',
        'synchronization.dialog.conflicts.noSelected.content':
            'Sie haben keine Auswahl getroffen. Wollen Sie keine Daten aktualisieren?',
        'synchronization.dialog.conflicts.title': 'Daten aktualisieren',
        'synchronization.dialog.conflicts.update.content':
            'Wollen Sie die ausgewählten Daten wirklich aktualisieren? Alle lokalen Änderungen werden zurückgesetzt.',
        'synchronization.dialog.sendToService.content': 'Wollen Sie die Fehleranalyse an __mail__ versenden?',
        'synchronization.dialog.sendToService.title': 'Fehleranalyse versenden',
        'synchronization.label.conflicts': 'Aktualisierungen',
        'synchronization.label.modifiedOn': 'Aktualisiert am: __modifiedOn__',
        'synchronization.mail.subject.syncError': 'Care-App Synchronisationsfehler Log | __date__',
        'synchronization.notification.fetchError': 'Es kann keine Verbindung zum Server hergestellt werden.',
        'synchronization.notification.pull.loading': 'Daten werden abgerufen.',
        'synchronization.notification.pull.loadingFinished': 'Daten wurden abgerufen.',
        'synchronization.notification.push.loading': 'Daten werden synchronisiert.',
        'synchronization.notification.push.loadingFinished': 'Daten wurden synchronisiert.',
        'synchronization.notification.push.errors': 'Einige Daten konnten nicht synchronisiert werden.',

        // Tooltip keys

        'tooltip.acceptChange': 'Bestätigen',
        'tooltip.cancelChange': 'Abbrechen',
        'tooltip.clear': 'Leeren',
        'tooltip.close': 'Schließen',
        'tooltip.createDispatch': 'Meldung erstellen',
        'tooltip.download': 'Herunterladen',
        'tooltip.edit': 'Bearbeiten',
        'tooltip.open': 'Öffnen',
        'tooltip.uploadDocument': 'Dokument hinzufügen',
        'tooltip.uploadImage': 'Bild hinzufügen',

        // Type keys

        'type.assignment': 'Prüfung',
        'type.assignment.plural': 'Prüfungen',
        'type.assignments': 'Prüfungen',
        'type.dispatch': 'Meldung',
        'type.dispatches': 'Meldungen',
        'type.dispatch.plural': 'Meldungen',
        'type.dispatchesOf': 'Meldungen von __name__',

        // SubViews

        // Care

        'assignment.button.documents': 'Dokumente',
        'assignment.button.executeAction': 'Abschließen',
        'assignment.button.pictures': 'Bilder',
        'assignment.button.refresh': 'Aktualisieren',
        'assignment.button.show': 'Anzeigen',
        'assignment.description.formNotFinished':
            'Aufgabe kann nicht geschlossen werden, solange das Formular noch nicht ausgefüllt ist.',
        'assignment.dialog.description.title': 'Beschreibung __name__',
        'assignment.dialog.removeDocument.title': 'Dokument löschen',
        'assignment.dialog.removeDocument.content': 'Wollen Sie dieses Dokument wirklich löschen?',
        'assignment.label.availableAssignments': 'Verfügbar',
        'assignment.label.checkpoint': '__checkpoints__ Prüfpunkt',
        'assignment.label.checkpoints': '__checkpoints__ Prüfpunkte',
        'assignment.label.documents': 'Dokumente __elementName__',
        'assignment.label.lastModifiedAssigment': 'Zuletzt bearbeitet',
        'assignment.label.newAssignment': 'Neu',
        'assignment.label.preference.mapVisible.title': 'Karte initial anzeigen',
        'assignment.label.preference.mapVisible.subtitle':
            'Sie sehen die Karte und die Prüfung standardmäßig im geteilten Bildschirm',
        'assignment.label.version': 'Version __version__',
        'assignment.notification.openDraft': 'Bitte erst alle Entwürfe abschließen',
        'assignment.notification.updateFormElementError': 'Das Formular für die Prüfung __name__ hat sich geändert.',
        'assignment.notification.wrongType':
            'Auftragsposition konnte nicht abgeschlossen werden. Die Konfiguration des Prozesses ist fehlerhaft',
        'assignment.placeholder.noAssignments.title': 'Keine Prüfungen',
        'assignment.placeholder.noAssignments.subtitle': 'Zurzeit sind Ihnen keine Prüfungen zugewiesen',
        'assignment.placeholder.noCheckpoints.title': 'Keine Prüfpunkte',
        'assignment.placeholder.noCheckpoints.subtitle': 'Es wurden keine Prüfpunkte definiert',
        'assignment.placeholder.noOfflineDocuments.title': 'Keine Bilder',
        'assignment.placeholder.noOfflineDocuments.subtitle': 'Sie haben keine Bilder die Offline gespeichert wurden',
        'assignment.tooltip.retrieveData': 'Abrufen der Daten aus der Instandhaltung',
        'assignment.tooltip.syncData':
            'Anzahl der Offline-Änderungen. Wenn Sie wieder Online sind, können Sie die Daten über diese Aktion synchronisieren.',

        // Checklist

        'checklist.dialog.title': 'Checkliste für __name__',
        'checklist.label.menuItem': 'Checkliste (__amount__)',
        'checklist.label.name': 'Listeneintrag',
        'checklist.placeholder.newItem': 'Neuer Listeneintrag',
        'checklist.placeholder.noItems': 'Keine Listeneinträge',
        'checklist.tooltip.addNewItem': 'Listeneintrag hinzufügen',
        'checklist.tooltip.deleteItem': 'Listeneintrag löschen',
        'checklist.tooltip.editItem': 'Listeneintrag ändern',

        // Dispatch

        'dispatch.button.closeDraft': 'Entwurf beenden',
        'dispatch.button.uploadImage': 'Bild hinzufügen',
        'dispatch.description.checklistItemsNotChecked':
            'Aufgabe kann nicht geschlossen werden, solange nicht alle Listeneinträge erledigt sind.',
        'dispatch.dialog.closeDraft.content': 'Wollen Sie den Entwurf __name__ wirklich beenden?',
        'dispatch.dialog.closeDraft.title': 'Entwurf beenden',
        'dispatch.dialog.removeDraft.content': 'Wollen Sie den Entwurf __name__ wirklich löschen?',
        'dispatch.dialog.removeDraft.title': 'Entwurf löschen',
        'dispatch.label.drafts': 'Entwürfe',
        'dispatch.label.ownCreatedDispatches': 'Von mir erstellte Meldungen',
        'dispatch.label.removeDraft': 'Entwurf löschen',
        'dispatch.label.tabGeneral': 'Allgemein',
        'dispatch.label.tabMap': 'Karte',
        'dispatch.label.tabDocuments': 'Dokumente (__count__)',
        'dispatch.notification.closedDraft': 'Entwurf wurde abgeschlossen.',
        'dispatch.notification.removedDraft': 'Entwurf wurde gelöscht.',
        'dispatch.notification.savedFormData': 'Formular wurde gespeichert.',
        'dispatch.notification.valuesEqual': 'Es gibt keine Änderungen zum speichern.',
        'dispatch.placeholder.noDispatches.subtitle': 'Zurzeit sind Ihnen keine Meldungen zugewiesen',
        'dispatch.placeholder.noDispatches.title': 'Keine Meldungen',
        'dispatch.placeholder.uploadDocument.subtitle': 'Fügen Sie Ihrer Meldung ein Dokument hinzu',
        'dispatch.placeholder.uploadDocument.title': 'Dokument hinzufügen',

        // Journal

        'journal.notification.commentAdded': 'Kommentar hinzugefügt',
        'journal.notification.commentChanged': 'Kommentar geändert',
        'journal.notification.commentDeleted': 'Kommentar gelöscht',
        'journal.tooltip.addComment': 'Kommentar hinzufügen',
        'journal.label': 'Logbuch (__amount__)',
        'journal.dialog.title': 'Logbuch für __name__',
        'journal.dialog.deleteComment.content': 'Wollen Sie den ausgewählten Kommentar wirklich löschen?',
        'journal.dialog.deleteComment.title': 'Kommentar löschen',

        // Location Recording

        'locationRecording.dialog.deleteRecord.title': '__record__ löschen',
        'locationRecording.dialog.deleteRecord.content': 'Wollen Sie diese Route wirklich löschen?',
        'locationRecording.dialog.stopRecord.title': 'Aufzeichnung beenden',
        'locationRecording.dialog.stopRecord.content': 'Wollen Sie diese Aufzeichnung wirklich beenden?',
        'locationRecording.label.alreadyRunning':
            'Es läuft bereits eine Aufzeichnung. Bitte stoppen Sie die aktuelle Aufzeichnung.',
        'locationRecording.label.duration': 'Dauer: __duration__',
        'locationRecording.label.listPoints': 'Routenpunkte: __count__',
        'locationRecording.label.pause': 'Pause',
        'locationRecording.label.points': '__count__ Punkt(e)',
        'locationRecording.label.record': 'Aufzeichnung __date__',
        'locationRecording.label.recording': 'Aufzeichnung',
        'locationRecording.label.savedRoutes': 'Gespeicherte Routen',
        'locationRecording.notification.deleteRecordNotPossible':
            'Aufzeichnung kann nicht gelöscht werden, da die Aufnahme aktiv ist.',
        'locationRecording.notification.deletedRecord': 'Route wurde gelöscht.',
        'locationRecording.notification.deniedLocation':
            'Bitte aktivieren Sie die Positionsbestimmung auf Ihrem Gerät.',
        'locationRecording.placeholder.noRecords.subtitle': 'Sie haben noch keine Routen gespeichert',
        'locationRecording.placeholder.noRecords.title': 'Keine Routen',

        // Process

        'process.description.notOnline': 'Wenn Sie wieder Online sind, können Sie die Aufgabe abschließen.',
        'process.dialog.delegateTask.title': 'Zuweisen: __taskName__',
        'process.dialog.processAction.content': 'Wollen Sie die Aktion ausführen?',
        'process.dialog.processCompletion.content': 'Wollen Sie die Aufgabe "__taskName__" abschließen?',
        'process.label.delgateTask': 'Aufgabe zuweisen',
        'process.label.menuItem': 'Aufgaben (__amount__)',
        'process.notification.claimTask': 'Aufgabe übernehmen',
        'process.notification.completeTask': 'Die Aufgabe "__taskName__" wird abgeschlossen.',
        'process.notification.completedTask': 'Die Aufgabe __taskName__ wurde abgeschlossen.',
        'process.notification.delegateTask': 'Aufgabe zuweisen',
        'process.notification.delegatedTask': 'Die Aufgabe wurde zugewiesen.',
        'process.notification.delegatedError': 'Bei der Zuweisung ist ein Fehler aufgetreten.',
        'process.notification.disclaimTask': 'Aufgabe abgeben',
        'process.notification.executeAction': 'Die Aktion wird ausgeführt.',
        'process.notification.executedAction': 'Die Aktion wurde ausgeführt.',
        'process.notification.taskClaimed': 'Die Aufgabe wurde übernommen.',
        'process.notification.taskDisclaimed': 'Die Aufgabe wurde abgegeben.',
        'process.placeholder.noTasks.content': 'Es gibt keine Aufgaben für __entityName__',
        'process.placeholder.noTasks.title': 'Keine Aufgaben',
    },
    en: {
        // Default

        'default.finished': 'Finished',
        'default.inProgress': 'In Progress',
        'default.open': 'Open',
        'default.type': 'Type',

        // Button keys

        'button.add': 'Add',
        'button.back': 'Back',
        'button.backToHome': 'Back to homepage',
        'button.cancel': 'Cancel',
        'button.close': 'Close',
        'button.delegate': 'Delegate',
        'button.dispatches': 'Dispatches (__amount__)',
        'button.forward': 'Forward',
        'button.handOff': 'Hand off',
        'button.logout': 'Logout __username__',
        'button.ok': 'Ok',
        'button.refresh': 'Update',
        'button.reload': 'Reload',
        'button.save': 'Save',
        'button.takeOver': 'Take over',
        'button.uploadDocument': 'Add document',
        'button.uploadImage': 'Add image',

        // Dialog keys

        'dialog.addDocument.title': 'Add document',
        'dialog.deleteDocument.title': 'Delete document',
        'dialog.deleteDocument.content': 'Are you sure you want to delete this document?',
        'dialog.deleteImage.title': 'Delete image',
        'dialog.deleteImage.content': 'Are you sure you want to delete this image?',
        'dialog.removeGeometry.title': 'Remove geometry',
        'dialog.removeGeometry.content': 'Are you sure you want to remove this geometry?',
        'dialog.tasks.title': 'Tasks for __name__',

        // Label keys

        'label.delete': 'Delete',
        'label.download': 'Download',
        'label.draft': 'Draft',
        'label.editWebApp': 'Edit in __webDavAppName__',
        'label.fileName': 'File name',
        'label.lastFetch': 'When was the last data retrieved?',
        'label.message': 'Journal entry',
        'label.noLastFetch': 'No data has been retrieved',
        'label.offlineDocuments': 'Stored images',
        'label.preference.general': 'General preferences',
        'label.preference.map': 'Map preferences',
        'label.showOnMap': 'Show on map',
        'label.ssoLogin': 'Login with Single Sign-on',
        'label.sync': 'Synchronization',
        'label.updateAvailable': 'New version available',
        'label.user': 'User',

        // Map keys

        'map.default.background': 'Background',
        'map.default.layers': 'Layers',
        'map.dialog.navigation.title': 'Start navigation',
        'map.dialog.navigation.content.coordinate':
            'Do you want to start navigating to the coordinate __coordinate__ ?',
        'map.dialog.navigation.content.feature': 'Do you want to start navigating to the object __feature__?',
        'map.label.errorTreeLayerLoading': 'Layer not yet loaded',
        'map.label.errorZoomLevelBoundsZoomIn': 'Not sure, please zoom in',
        'map.label.errorZoomLevelBoundsZoomOut': 'Not sure, please zoom out',
        'map.tooltip.saveSelectedGeometry': 'Save selected geometry',
        'map.tooltip.removeSelectedGeometry': 'Remove selected geometry',

        // Offline map keys

        'offlineMap.dialog.remove.title': 'Remove offline map',
        'offlineMap.dialog.remove.content': 'Are you sure you want to remove this offline map',
        'offlineMap.label': 'Offline map',
        'offlineMap.label.create': 'Create offline map',
        'offlineMap.label.delete': 'Remove offline map',
        'offlineMap.label.update': 'Refresh offline map',
        'offlineMap.notification.download.error':
            'Offline map could not be created. There are no compatible background maps / thematic layers configured.',
        'offlineMap.notification.fetchError': 'A connection to the server cannot be established.',
        'offlineMap.notification.loading': 'Offline map is being created.',
        'offlineMap.notification.loadingFinished': 'Offline map was created successfully.',

        // Notification keys

        'notification.addedGeometry': 'Geometry has been added.',
        'notification.closedDraft': 'Draft has been closed.',
        'notification.couldNotDownload': 'Document could not be downloaded.',
        'notification.deletedDocument': 'Document has been removed.',
        'notification.fileNameAlreadyExisists': 'this file name already exists.',
        'notification.imageAlreadyExisists': 'This image already exists.',
        'notification.imageAlreadyExisistsAndVersioned': 'This image already exists and will be versioned.',
        'notification.loginError': 'Login is not correct',
        'notification.noSyncChanges': 'No data to synchronize.',
        'notification.populateMandatoryFields': 'Populate mandatory fields',
        'notification.refetchedData': 'Retrieved data from maintenance.',
        'notification.refetchFailed': 'Could not retrieve data from maintenance.',
        'notification.removedGeometry': 'Geometry has been removed.',
        'notification.syncedData': 'Data has been synchronized.',
        'notification.updatedGeometry': 'Geometry has been updated.',
        'notification.uploadDocument': 'Document is added.',
        'notification.uploadedDocument': 'Document has been added.',
        'notification.uploadedImage': 'Image has been added.',

        // Placeholder keys

        'placeholder.loading': 'Loading',
        'placeholder.noEntries.subtitle': 'There are no entries available',
        'placeholder.noEntries.title': 'No entries',
        'placeholder.noOptions': 'No options',
        'placeholder.noUserAssigned': '<No user assigned>',
        'placeholder.networkNotFound.subtitle': 'Please contact the technical support',
        'placeholder.networkNotFound.title': 'Can not reach server',
        'placeholder.routeNotFound.subtitle': 'Please check the requested address or go back to homepage',
        'placeholder.routeNotFound.title': 'Page not found',
        'placeholder.uploadDocument.subtitle': 'Add a new document',
        'placeholder.uploadDocument.title': 'Add document',
        'placeholder.uploadImage.subtitle': 'Add a new image',
        'placeholder.uploadImage.title': 'Add image',

        // Status keys

        'status.CLOSED': 'Finished',
        'status.CRITICAL': 'Critical',
        'status.IN_PROGRESS': 'In progress',
        'status.NONE': 'Inactive',
        'status.OPEN': 'Open',

        // Synchronization keys

        'synchronization.button.sendToService': 'Send',
        'synchronization.dialog.conflicts.noSelected.content':
            'You do not have selected data. Do you not want to update anything?',
        'synchronization.dialog.conflicts.title': 'Update data',
        'synchronization.dialog.conflicts.update.content':
            'Do you really want to update the selected data? All local changes are rolled back.',
        'synchronization.dialog.sendToService.content': 'Do you want to send the error analysis to __mail__?',
        'synchronization.dialog.sendToService.title': 'Send error analysis',
        'synchronization.label.conflicts': 'Updates',
        'synchronization.label.modifiedOn': 'Updated at: __modifiedOn__',
        'synchronization.mail.subject.syncError': 'Care app synchronization error log | __date__',
        'synchronization.notification.fetchError': 'A connection to the server cannot be established.',
        'synchronization.notification.pull.loading': 'Data is being retrieved.',
        'synchronization.notification.pull.loadingFinished': 'Data has been retrieved.',
        'synchronization.notification.push.loading': 'Data is being synchronized.',
        'synchronization.notification.push.loadingFinished': 'Data has been synchronized.',
        'synchronization.notification.push.errors': 'Some data could not be synchronized.',

        // Tooltip keys

        'tooltip.acceptChange': 'Confirm',
        'tooltip.cancelChange': 'Cancel',
        'tooltip.clear': 'Clear',
        'tooltip.close': 'Close',
        'tooltip.createDispatch': 'Create dispatch',
        'tooltip.download': 'Download',
        'tooltip.edit': 'Edit',
        'tooltip.open': 'Open',
        'tooltip.uploadDocument': 'Add Document',
        'tooltip.uploadImage': 'Add Image',

        // Type keys

        'type.assignment': 'Assignment',
        'type.assignment.plural': 'Assignments',
        'type.assignments': 'Assignments',
        'type.dispatch': 'Dispatch',
        'type.dispatches': 'Dispatches',
        'type.dispatch.plural': 'Dispatches',
        'type.dispatchesOf': 'Dispatches of __name__',

        // SubViews

        // Care

        'assignment.button.documents': 'Documents',
        'assignment.button.executeAction': 'Complete',
        'assignment.button.pictures': 'Images',
        'assignment.button.refresh': 'Refresh',
        'assignment.button.show': 'Show',
        'assignment.description.formNotFinished': 'Task cannot be closed as long as the form is not finished.',
        'assignment.dialog.description.title': 'Description __name__',
        'assignment.dialog.removeDocument.title': 'Remove document',
        'assignment.dialog.removeDocument.content': 'Are you sure you want to remove this document?',
        'assignment.label.availableAssignments': 'Available',
        'assignment.label.checkpoint': '__checkpoints__ Checkpoint',
        'assignment.label.checkpoints': '__checkpoints__ Checkpoints',
        'assignment.label.documents': 'Documents __elementName__',
        'assignment.label.lastModifiedAssigment': 'Last edited',
        'assignment.label.newAssignment': 'New',
        'assignment.label.preference.mapVisible.title': 'Show map initially',
        'assignment.label.preference.mapVisible.subtitle':
            'You will see the map and assignment in the split screen by default',
        'assignment.label.version': 'Version __version__',
        'assignment.notification.openDraft': 'Please close all draft first.',
        'assignment.notification.updateFormElementError': 'The form for the assignment __name__ has changed.',
        'assignment.notification.wrongType':
            'Assignment cannot be completed. The configuration of the process is faulty.',
        'assignment.placeholder.noAssignments.title': 'No assignments',
        'assignment.placeholder.noAssignments.subtitle': 'You are currently not assigned any assignments',
        'assignment.placeholder.noCheckpoints.title': 'No checkpoints',
        'assignment.placeholder.noCheckpoints.subtitle': 'No checkpoints have been defined',
        'assignment.placeholder.noOfflineDocuments.title': 'No images',
        'assignment.placeholder.noOfflineDocuments.subtitle': 'You do not have any images that were saved offline',
        'assignment.tooltip.retrieveData': 'Retrieve data from maintenance',
        'assignment.tooltip.syncData':
            'Number of offline changes. When you are back online, you can use this action to synchronize the data.',

        // Checklist

        'checklist.dialog.title': 'Checklist of __name__',
        'checklist.label.menuItem': 'Checklist (__amount__)',
        'checklist.label.name': 'Checklist item',
        'checklist.placeholder.newItem': 'New checklist item',
        'checklist.placeholder.noItems': 'No checklist items',
        'checklist.tooltip.addNewItem': 'Add checklist item',
        'checklist.tooltip.deleteItem': 'Delete checklist item',
        'checklist.tooltip.editItem': 'Edit checklist item',

        // Dispatch

        'dispatch.button.closeDraft': 'Finish draft',
        'dispatch.button.uploadImage': 'Add image',
        'dispatch.description.checklistItemsNotChecked':
            'Task cannot be closed as long as not all checklist items are checked.',
        'dispatch.dialog.closeDraft.content': 'Are you sure you want to finish draft __name__?',
        'dispatch.dialog.closeDraft.title': 'Finish draft',
        'dispatch.dialog.removeDraft.content': 'Are you sure you want to remove draft __name__?',
        'dispatch.dialog.removeDraft.title': 'Remove draft',
        'dispatch.label.drafts': 'Drafts',
        'dispatch.label.ownCreatedDispatches': 'Dispatches created by me',
        'dispatch.label.removeDraft': 'Remove draft',
        'dispatch.label.tabGeneral': 'General',
        'dispatch.label.tabMap': 'Map',
        'dispatch.label.tabDocuments': 'Documents (__count__)',
        'dispatch.notification.closedDraft': 'Draft has been closed.',
        'dispatch.notification.removedDraft': 'Draft has been removed.',
        'dispatch.notification.savedFormData': 'Form has been saved.',
        'dispatch.notification.valuesEqual': 'There are no changes to save.',
        'dispatch.placeholder.noDispatches.title': 'No dispatches',
        'dispatch.placeholder.noDispatches.subtitle': 'You are currently not assigned any dispatches',
        'dispatch.placeholder.uploadDocument.subtitle': 'Add a document to your dispatch',
        'dispatch.placeholder.uploadDocument.title': 'Add document',

        // Journal

        'journal.notification.commentAdded': 'Comment added',
        'journal.notification.commentChanged': 'Comment changed',
        'journal.notification.commentDeleted': 'Comment deleted',
        'journal.tooltip.addComment': 'Add comment',
        'journal.label': 'Journal (__amount__)',
        'journal.dialog.title': 'Journal of __name__',
        'journal.dialog.deleteComment.content': 'Are you sure you want to delete this comment?',
        'journal.dialog.deleteComment.title': 'Delete comment',

        // Location Recording

        'locationRecording.dialog.deleteRecord.title': 'Delete __record__',
        'locationRecording.dialog.deleteRecord.content': 'Are you sure you want to delete this route?',
        'locationRecording.dialog.stopRecord.title': 'Stop record',
        'locationRecording.dialog.stopRecord.content': 'Are you sure you want to stop this record?',
        'locationRecording.label.alreadyRunning': 'A recording is already running. Please stop the current recording.',
        'locationRecording.label.duration': 'Duration: __duration__',
        'locationRecording.label.listPoints': 'Recorded points: __count__',
        'locationRecording.label.pause': 'Pause',
        'locationRecording.label.points': '__count__ point(s)',
        'locationRecording.label.record': 'Record __date__',
        'locationRecording.label.recording': 'Recording',
        'locationRecording.label.savedRoutes': 'Recorded routes',
        'locationRecording.notification.deleteRecordNotPossible':
            'Recording cannot be deleted because recording is active.',
        'locationRecording.notification.deletedRecord': 'Record has been removed.',
        'locationRecording.notification.deniedLocation': 'Please activate positioning on your device.',
        'locationRecording.placeholder.noRecords.subtitle': 'You do not have any routes saved yet.',
        'locationRecording.placeholder.noRecords.title': 'No Routes',

        // Process

        'process.description.notOnline': 'You need to be online to close a task.',
        'process.dialog.delegateTask.title': 'Delegate: __taskName__',
        'process.dialog.processAction.content': 'Do you want to excute the process action?',
        'process.dialog.processCompletion.content': 'Do you want to complete the task "__taskName__"?',
        'process.label.delgateTask': 'Delegate task',
        'process.label.menuItem': 'Tasks (__amount__)',
        'process.notification.claimTask': 'Claim task',
        'process.notification.completeTask': 'Complete the task __taskName__.',
        'process.notification.completedTask': 'The task __taskName__ has been completed.',
        'process.notification.delegateTask': 'Delegate task',
        'process.notification.delegatedTask': 'The task has been delegated.',
        'process.notification.delegatedError': 'An error occured during delegation.',
        'process.notification.disclaimTask': 'Disclaim task',
        'process.notification.executeAction': 'Execute process action',
        'process.notification.executedAction': 'The process action has been executed.',
        'process.notification.taskClaimed': 'The task has been claimed.',
        'process.notification.taskDisclaimed': 'The task has been disclaimed.',
        'process.placeholder.noTasks.content': 'There are no tasks for  __entityName__',
        'process.placeholder.noTasks.title': 'No tasks',
    },
};

export default translations;
