import { getReference } from '@geomagic/geonam';

import getUpdatedDoc from './getUpdatedDoc';
import handleFetchError from './handleFetchError';
import handleSyncError from './handleSyncError';
import isIncludingInPatch from './isIncludingInPatch';
import createEntity from './updates/createEntity';
import resetDoc from './resetDoc';
import updateChecklist from './updates/updateChecklist';
import updateDocuments from './updates/updateDocuments';
import updateEntity from './updates/updateEntity';
import updateGeometries from './updates/updateGeometries';
import updateJournal from './updates/updateJournal';

const getDefaultSync = async ({ client, doc, entityClasses, mapProps, refetchQuery, syncErrors }) => {
    let entityReference = getReference(doc?.entity);

    try {
        const isDraft = !!doc?.draft;

        const updateProps = {
            client,
            doc,
            entityClasses,
            entity: doc?.entity,
            patchedEntity: doc.getPatchedEntity(),
        };

        if (isDraft) {
            const createdEntityReference = await createEntity(updateProps);

            if (createdEntityReference) {
                entityReference = createdEntityReference;
            }
        } else {
            const updateEntityPointer = '/attributeValues';

            if (isIncludingInPatch(doc, updateEntityPointer)) {
                await updateEntity({ ...updateProps, entityReference, path: updateEntityPointer });
            }
        }

        const updateGeometriesPointer = '/featureCollections/0/features';

        if (isIncludingInPatch(doc, updateGeometriesPointer)) {
            await updateGeometries({ ...updateProps, entityReference, path: updateGeometriesPointer });
        }

        const updateDocumentsPointer = '/documents';

        if (isIncludingInPatch(doc, updateDocumentsPointer)) {
            await updateDocuments({ ...updateProps, entityReference, path: updateDocumentsPointer });
        }

        const updateJournalPointer = '/journal';

        if (isIncludingInPatch(doc, updateJournalPointer)) {
            await updateJournal({ ...updateProps, entityReference, path: updateJournalPointer });
        }

        const updateChecklistPointer = '/checklistItems';

        if (isIncludingInPatch(doc, updateChecklistPointer)) {
            await updateChecklist({ ...updateProps, entityReference, path: updateChecklistPointer });
        }

        const updatedEntity = refetchQuery && (await getUpdatedDoc(client, refetchQuery, entityReference, mapProps));

        if (updatedEntity) {
            await resetDoc(doc, updatedEntity);
        }

        return entityReference;
    } catch (error) {
        handleFetchError({ error });
        handleSyncError({ entityReference, error, syncErrors });
    }
};

export default getDefaultSync;
