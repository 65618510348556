import gql from 'graphql-tag';

import { FragmentProcessHistory } from '@geomagic/geonam-graphql';

export const FragmentProcessInstance = gql`
    ${FragmentProcessHistory}

    fragment ProcessInstance on ProcessInstance {
        businessKey
        description
        id
        name
        status
        tasks {
            actions {
                closeAction
                commands {
                    confirmation
                    command
                    name
                    type
                }
                description
                disabled
                id
                name
            }
            assignee
            candidateGroups
            description
            dueDate
            id
            name
            priority
            stage
        }
        ...ProcessHistory @include(if: $withHistory)
    }
`;

export default FragmentProcessInstance;
