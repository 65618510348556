import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import AddIcon from '@material-ui/icons/Add';

import { useRxDB } from 'rxdb-hooks';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import getEntityTemplate from '@database/getEntityTemplate';
import getDocTemplate from '@database/getDocTemplate';
import showModal from '@utils/showModal';

import DispatchDraftWizard from './DispatchDraftWizard';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {},
    fab: {
        bottom: spacing(2),
        position: 'absolute',
        right: spacing(2),
        zIndex: 3,
    },
}));

const AddDraftTrigger = props => {
    const {
        className,
        docRelations,
        entityClass,
        entityClasses,
        getPreviousMap,
        isMobile,
        mapProps,
        onAddedDraft,
        variant = 'fab',
        userId,
    } = props;
    const database = useRxDB();
    const classes = useStyles();

    /**
     *  EVENT HANDLER
     */

    const handleAddDraft = async event => {
        event.stopPropagation();
        const previousMap = getPreviousMap();

        if (entityClasses && entityClass) {
            const collection = await database['dispatches'];
            const entity = getEntityTemplate(entityClass);
            const doc = getDocTemplate({ entity, isDraft: true, mapProps, relations: docRelations, userId });

            const dispatch = await collection.insert(doc);

            if (dispatch) {
                onAddedDraft && (await onAddedDraft(entity));

                showModal({
                    title: i18n.t('label.draft'),
                    content: (
                        <DispatchDraftWizard
                            entityId={entity?.id}
                            entityClass={entityClass}
                            entityClasses={entityClasses}
                            mapProps={mapProps}
                            isMobile={isMobile}
                            previousMap={previousMap}
                        />
                    ),
                    isFullScreen: true,
                });
            }
        }
    };
    return (
        <Trigger
            className={classNames(classes.root, className, { [classes.fab]: variant === 'fab' })}
            color="primary"
            icon={<AddIcon />}
            onClick={handleAddDraft}
            variant={variant}
        />
    );
};

AddDraftTrigger.propTypes = {
    className: PropTypes.string,
    docRelations: PropTypes.array,
    entityClass: PropTypes.object,
    entityClasses: PropTypes.array,
    getPreviousMap: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    mapProps: PropTypes.object,
    onAddedDraft: PropTypes.func,
    variant: PropTypes.string,
};

export default AddDraftTrigger;
