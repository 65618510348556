import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import DefaultIcon from '@material-ui/icons/DonutLarge';

const useStyles = makeStyles(({ palette, spacing, transitions }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
}));

const DynamicAvatar = props => {
    const classes = useStyles(props);
    const [Component, setComponent] = useState();

    const { className, imp, componentProps, loadingComponent = null } = props;

    useEffect(() => {
        const getComponent = async () => {
            try {
                const component = await imp;
                setComponent(component.default);
            } catch (error) {
                setComponent(DefaultIcon);
            }
        };

        getComponent();
    }, [imp]);

    return Component ? (
        <Component className={classNames(classes.root, className)} {...componentProps} />
    ) : (
        loadingComponent
    );
};

DynamicAvatar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    imp: PropTypes.object.isRequired,
    componentProps: PropTypes.object,
    loadingComponent: PropTypes.node,
};

export default DynamicAvatar;
