import addGeometryTypeToFeatures from './addGeometryTypeToFeatures';
import getGeometryTypesByStyleId from './getGeometryTypesByStyleId';

/**
 * Get a feature collection with styles
 * @param {Object} entity - GeoNAM entity
 * @param {Array.<Object>} entityClasses - Array of GeoNAM entity classes
 * @returns {Object}
 */

const getFeatureCollection = (entity, entityClasses) => {
    let collection = null;

    if (entity && entity.entityType && entityClasses) {
        const featureCollection = entity?.featureCollections[0];
        const features = featureCollection?.features || [];
        const { typesByStyleId } = getGeometryTypesByStyleId(entity.entityType, entityClasses);

        collection = {
            ...featureCollection,
            features: addGeometryTypeToFeatures(features, typesByStyleId),
        };
    }

    return collection;
};

export default getFeatureCollection;
