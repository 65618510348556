export const MAP_SRID = 3857;
export const VIEW_CODE = 'vew.default';
export const WORLD_CODE = 'map.wld.default';

export const DEFAULT_UPLOAD_SRID = 25833;
export const DEFAULT_SIDEBAR_WIDTH = 300;
export const DEFAULT_DURATION = 500;

export const DEFAULT_CENTER_OPTIONS = {
    duration: DEFAULT_DURATION,
};

export const DEFAULT_FIT_OPTIONS = {
    duration: DEFAULT_DURATION,
    padding: [50, 50, 50, 50],
};

export const DEFAULT_NAVIGATION_URLS = {
    // eslint-disable-next-line no-template-curly-in-string
    all: 'http://maps.google.com/maps?saddr=&daddr=${latlon}',
    // eslint-disable-next-line no-template-curly-in-string
    android: 'http://maps.google.com/maps?saddr=&daddr=${latlon}',
    // eslint-disable-next-line no-template-curly-in-string
    ios: 'http://maps.apple.com/?daddr=${latlon}',
    // eslint-disable-next-line no-template-curly-in-string
    windowsPhone: 'ms-drive-to:?destination.latitude=${lat}&destination.longitude=${lon}&destination.name=${name}',
};
