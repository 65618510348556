import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';

import { i18n } from '@geomagic/i18n';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    return {
        root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: spacing(),
        },
    };
});

const GeneralPreferences = props => {
    const { className, onUpdatePreferences, preferences: initialPreferences } = props;

    const [preferences, setPreferences] = useState(initialPreferences);
    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleChangeSwitch = key => event => {
        const newPreferences = { ...preferences, [key]: event.target.checked };
        setPreferences(newPreferences);
        onUpdatePreferences(newPreferences);
    };

    return (
        <List className={classNames(classes.root, className)}>
            <ListItem disableGutters>
                <ListItemText
                    primary={i18n.t('assignment.label.preference.mapVisible.title')}
                    secondary={i18n.t('assignment.label.preference.mapVisible.subtitle')}
                />
                <ListItemSecondaryAction>
                    <Switch
                        checked={preferences?.isMapVisible || false}
                        color="primary"
                        onChange={handleChangeSwitch('isMapVisible')}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};

GeneralPreferences.propTypes = {
    className: PropTypes.string,
    onUpdatePreferences: PropTypes.func.isRequired,
    preferences: PropTypes.object.isRequired,
};

export default GeneralPreferences;
