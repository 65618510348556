import getLayerGroups from './getLayerGroups';

const olDefaultStyle = {
    fill: { color: 'rgba(255, 255, 255, 0.4)' },
    stroke: { color: '#3399CC', width: 2 },
};

/**
 * Transform GeoNAM layers to @geomagic/map format
 * @param {Array} layers - Layers from GeoNAM view
 * @param {Object} store - @geomagic/map LayerSelectionStore (window.localStorage)
 * @returns {Array}
 */

const transformLayers = (view = {}, layerSelectionStore) => {
    const { baseLayers = [], overlayLayers = [] } = view;
    const transformedLayers = [];
    const { selectedLayers } = layerSelectionStore.getLayerSelection();

    const { id: visibleBackgroundLayerId } = baseLayers.find(({ visible }) => visible) || {};
    const newSelectedLayers = [];

    baseLayers.forEach(baseLayer => {
        const { name, id, sourceType, sourceOptions, options, filter, filterable } = baseLayer;
        const type = sourceType === 'XYZ' ? 'OSM' : sourceType; // TODO implement XYZ source in @geomagic/map

        const isVisible = visibleBackgroundLayerId === id;

        const transformedLayer = {
            id,
            _id: String(id),
            layerPackage: 'default',
            name,
            type,
            isBackground: true,
            values: JSON.stringify(sourceOptions),
            layerValues: JSON.stringify(options),
            isVisible,
            filter,
            filterable,
        };
        transformedLayers.push(transformedLayer);
    });

    overlayLayers.forEach(viewLayer => {
        const {
            name,
            id,
            visible,
            sourceType,
            sourceOptions,
            options,
            filter,
            filterable,
            grouping,
            seqNumber,
        } = viewLayer;
        const type = sourceType === 'XYZ' ? 'OSM' : sourceType; // TODO implement XYZ source in @geomagic/map
        const layersGroups = getLayerGroups(grouping, visible);

        if (layersGroups) {
            layersGroups.forEach(item => {
                const foundIndex = transformedLayers.findIndex(element => element._id === item._id);
                if (foundIndex === -1) {
                    transformedLayers.push(item);
                } else if (!transformedLayers[foundIndex].isVisible && item.isVisible) {
                    transformedLayers.splice(foundIndex, 1, item);
                }
            });
        }

        const transformedLayer = {
            id,
            _id: String(id),
            layerPackage: 'default',
            name,
            type,
            values: JSON.stringify(sourceOptions),
            layerValues: JSON.stringify(options),
            isVisible: visible,
            filter,
            filterable,
            seqNumber,
            ...(type === 'VectorTile' && {
                sourceType: 'MVT',
                layerValues: JSON.stringify({ ...options, style: olDefaultStyle }),
            }),
            ...(layersGroups && layersGroups.length > 0 && { parent: layersGroups[layersGroups.length - 1]._id }),
        };

        transformedLayers.push(transformedLayer);
    });

    transformedLayers.forEach(transformedLayer => {
        const { name, isBackground, isVisible } = transformedLayer;
        const selectedLayer = selectedLayers.find(layer => layer.layerId === name);
        if (!isBackground) {
            if (selectedLayer) {
                let isLayerGroupExcist = false;
                const foundetLayer = newSelectedLayers.find(layer => layer.layerId === selectedLayer.layerId);
                if (foundetLayer) {
                    if (!isLayerGroupExcist) isLayerGroupExcist = true;
                    foundetLayer.isVisible = selectedLayer.isVisible;
                }
                if (!isLayerGroupExcist) newSelectedLayers.push(selectedLayer);
            } else {
                newSelectedLayers.push({
                    layerId: name,
                    isVisible,
                });
            }
        }
    });
    layerSelectionStore.saveLayerSelection({ selectedLayers: newSelectedLayers });

    return transformedLayers;
};

export default transformLayers;
