import React, { useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { hideModal } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import GroupedList from '@components/GroupedList';
import updateDoc from '@database/updateDoc';
import useShowPrompt from '@utils/useShowPrompt';

import getGroups from './getGroups';

const useStyles = makeStyles(({ breakpoints, palette, spacing, zIndex }) => {
    return {
        root: {},
    };
});

const getCheckItems = conflicts => {
    return flatten(conflicts.map(({ group, items }) => items.map(({ doc }) => doc?.uuid)));
};

const ConflictResolution = props => {
    const { className, config, conflictRef, conflicts, mapProps } = props;
    const groups = getGroups(conflicts);

    const showPrompt = useShowPrompt();
    const [checkedItems, setCheckedItems] = useState(getCheckItems(conflicts));
    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleSelectCheckbox = ({ id, checked }) => {
        setCheckedItems(prev => (checked ? [...prev, id] : prev.filter(item => item !== id)));
    };

    const handleUpdateConflicts = () => {
        const execute = async () => {
            for (let i = 0; i < conflicts.length; i++) {
                const updated = [];
                const { id, items } = conflicts[i];
                const { after } = config.find(({ collectionName }) => collectionName === id) || {};
                const checkedConflicts = items.filter(({ doc }) => checkedItems.includes(doc?.uuid));

                for (let i = 0; i < checkedConflicts.length; i++) {
                    const { doc, entity, modifiedOn, remoteVersion, userId } = checkedConflicts[i];
                    const updatedDoc = await updateDoc({ doc, entity, mapProps, modifiedOn, remoteVersion, userId });

                    updated.push(updatedDoc);
                }

                after && (await after({ updated }));
            }

            hideModal();
        };

        if (checkedItems.length > 0) {
            showPrompt({
                title: i18n.t('synchronization.dialog.conflicts.title'),
                content: i18n.t('synchronization.dialog.conflicts.update.content'),
                onOk: execute,
            });
        } else {
            showPrompt({
                title: i18n.t('synchronization.dialog.conflicts.title'),
                content: i18n.t('synchronization.dialog.conflicts.noSelected.content'),
                onOk: () => hideModal(),
            });
        }
    };

    /**
     *  CONTEXT
     */

    useImperativeHandle(conflictRef, () => ({ onUpdateConflicts: handleUpdateConflicts }));

    /**
     *  ADDITIONAL CONTEXT PROPS
     */

    const additionalContextProps = {
        checkedItems,
        onSelectCheckbox: handleSelectCheckbox,
    };

    return (
        <GroupedList
            className={classNames(classes.root, className)}
            additionalProps={additionalContextProps}
            groups={groups}
            idKey="entity.id"
        />
    );
};

ConflictResolution.propTypes = {
    className: PropTypes.string,
    config: PropTypes.array.isRequired,
    conflictRef: PropTypes.object.isRequired,
    conflicts: PropTypes.array.isRequired,
    mapProps: PropTypes.object.isRequired,
};

export default ConflictResolution;
