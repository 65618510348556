import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ApolloProvider } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';

import { getEntityClass, getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import AccordionItem from '@components/AccordionItem';
import Dispatch from '@components/Dispatch';
import AddDraftTrigger from '@components/Dispatch/AddDraftTrigger';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import { CLASSNAME_DISPATCH, RELEVANT_DISPATCH_PATH } from '@graphql/consts';
import showModal from '@utils/showModal';

import Progress from './Progress';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
    root: {
        [breakpoints.down('sm')]: {
            borderRadius: '0 !important',
            borderLeft: 'none',
            borderRight: 'none',
        },
    },
    summary: {
        padding: spacing(0, 1),
        '&$disabled': {
            backgroundColor: alpha(palette.background.default, palette.action.disabledOpacity),
            opacity: 1,
        },
        [breakpoints.down('sm')]: {
            minHeight: '56px !important',
        },
    },
    detail: {
        padding: spacing(1, 2),
        [breakpoints.down('sm')]: {
            borderRadius: 0,
            padding: spacing(0, 1),
        },
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    typography: {
        '&$disabled': {
            opacity: palette.action.disabledOpacity,
        },
    },
    triggerGroup: {
        [breakpoints.down('md')]: {
            marginLeft: spacing(),
        },
    },
    trigger: {
        whiteSpace: 'noWrap',
        '&$disabled': {
            pointerEvents: 'auto',
        },
    },
    disabled: {},
}));

const getDatabaseDocRelations = (assignmentDoc, block, path) => {
    const assignment = assignmentDoc.getPatchedEntity();

    const relations = [
        {
            id: assignment.id,
            name: assignment.displayName,
            path: RELEVANT_DISPATCH_PATH,
            parentId: null,
            type: assignment?.className,
        },
        {
            id: block.id,
            name: block.name,
            path: `${path}${RELEVANT_DISPATCH_PATH}`,
            parentId: assignment.id,
            type: block.typename,
        },
    ];

    return relations;
};

const FormElementBlock = props => {
    const { childRef, children, context, data, doc, entities, expanded, infoTrigger, onSelect, path, ...other } = props;
    const itemRef = useRef(null);
    const {
        allRelevantDispatches = [],
        client,
        entityClasses,
        getPreviousMap,
        isMobile,
        isOnline,
        isReadOnly,
        mapProps,
        onUpateRelevantDispatches,
        user,
    } = context;
    const { name, description, dispatchesCreatable, id, relevantDispatches = [] } = data;

    const disabled = children?.length < 1;
    const docRelations = getDatabaseDocRelations(doc, data, path);
    const entityClassDispatch = getEntityClass(entityClasses, CLASSNAME_DISPATCH);
    const userId = user.id;

    const mergedRelevantDispatches = [
        ...relevantDispatches,
        ...allRelevantDispatches.filter(dispatch => dispatch?.blockId === id).map(item => getReference(item)),
    ];

    const classes = useStyles(props);

    const dispatchListLabel = i18n.t('button.dispatches', {
        variables: { amount: mergedRelevantDispatches?.length || '0' },
    });

    /**
     *  CLASSES
     */

    const classDisabled = { [classes.disabled]: disabled };
    const classExpanded = { [classes.expanded]: expanded };

    const accordionItemClasses = {
        root: classNames(classes.root, classExpanded),
        summary: classNames(classes.summary, { ...classDisabled, ...classExpanded }),
        detail: classes.detail,
        subtitle: classNames(classes.typography, classDisabled),
    };

    const triggerClasses = classNames(classes.trigger, classDisabled);

    /**
     *  DATABASE
     */

    const queryProps = { [ENTITY_SELECTOR_KEY]: { $in: mergedRelevantDispatches.map(({ id }) => id) } };

    /**
     *  EVENT HANDLER
     */

    const handleAddedDraft = async createdDispatch => {
        const newDispatch = getReference(createdDispatch);

        await onUpateRelevantDispatches({ newDispatch, blockId: id });
    };

    const handleOpenModal = event => {
        event.stopPropagation();

        showModal({
            title: i18n.t('type.dispatches'),
            content: (
                <ApolloProvider client={client}>
                    <Dispatch
                        client={client}
                        docRelations={docRelations}
                        entityClasses={entityClasses}
                        isMobile={isMobile}
                        isOnline={isOnline}
                        isReadOnly={isReadOnly}
                        mapProps={mapProps}
                        onAddedDraft={handleAddedDraft}
                        queryProps={queryProps}
                        user={user}
                    />
                </ApolloProvider>
            ),
            isFullScreen: true,
        });
    };

    /**
     *  COMPONENTS
     */

    const DispatchTriggerComponent =
        dispatchesCreatable || mergedRelevantDispatches?.length > 0 ? (
            <ButtonGroup
                className={classes.triggerGroup}
                size="small"
                variant="contained"
                color="primary"
                disableElevation
            >
                <Button className={triggerClasses} onClick={handleOpenModal} size="small">
                    {dispatchListLabel}
                </Button>
                {dispatchesCreatable && !isReadOnly && (
                    <AddDraftTrigger
                        className={triggerClasses}
                        docRelations={docRelations}
                        entityClass={entityClassDispatch}
                        entityClasses={entityClasses}
                        getPreviousMap={getPreviousMap}
                        isMobile={isMobile}
                        mapProps={mapProps}
                        onAddedDraft={handleAddedDraft}
                        userId={userId}
                    />
                )}
            </ButtonGroup>
        ) : null;

    const TitleComponent = (
        <div className={classes.titleContainer}>
            <Typography
                className={classNames(classes.typography, classDisabled)}
                component="div"
                noWrap
                variant="body2"
            >
                {name}
            </Typography>
            {infoTrigger &&
                React.cloneElement(infoTrigger, {
                    className: classNames(infoTrigger?.props?.className, triggerClasses),
                })}
        </div>
    );

    return (
        <AccordionItem
            classes={accordionItemClasses}
            disabled={disabled}
            expanded={expanded}
            id={data.id}
            onSelect={onSelect}
            panelAction={DispatchTriggerComponent}
            panelIcon={<Progress forms={[data]} variant="icon" />}
            ref={itemRef}
            title={TitleComponent}
            {...(description && !infoTrigger && { subtitle: description })}
            {...other}
        >
            {children}
        </AccordionItem>
    );
};

FormElementBlock.propTypes = {
    children: PropTypes.node,
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    entities: PropTypes.object,
    expanded: PropTypes.bool,
    infoTrigger: PropTypes.node,
    onSelect: PropTypes.func,
};

export default FormElementBlock;
