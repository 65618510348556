import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';

import ErrorBoundary from '@geomagic/nam-react-core/components/ErrorBoundary';
import NotFound from '@components/NotFound';
import { LOGIN_WITH_CREDENTIALS_PATH } from '@consts';
import sanitizePath from '@utils/sanitizePath';

const getRoutePath = (basePath, path = '/', pathParams) => {
    const componentPath = basePath ? `${basePath}${path}` : path;
    const routePath = pathParams ? `${componentPath}${pathParams}` : componentPath;

    return {
        componentPath: sanitizePath(componentPath),
        routePath: sanitizePath(routePath),
    };
};

const RouteContainer = props => {
    const { defaultPath, items = [], state } = props;
    const { basePath = '/', isOnline, LoginComponent, loginStatus, user } = state;

    const isLoggedIn = loginStatus === 'LOGGED_IN';
    const isPrivate = user && ((isOnline && isLoggedIn) || !isOnline);

    const getDefaultPath = () => {
        const path = defaultPath || items[0]?.path;
        return sanitizePath(`${basePath}${path}`);
    };

    const getPrivateRoutes = () => {
        let routes = [];

        if (isPrivate) {
            routes = items.map(item => {
                const { Component, id, path, pathParams } = item;
                const { componentPath, routePath } = getRoutePath(basePath, path, pathParams);

                return (
                    <Route
                        key={id}
                        path={routePath}
                        render={routeProps => {
                            return (
                                <ErrorBoundary>
                                    {Component ? (
                                        <Component
                                            key={id}
                                            {...state}
                                            basePath={componentPath}
                                            routeProps={routeProps}
                                        />
                                    ) : null}
                                </ErrorBoundary>
                            );
                        }}
                    />
                );
            });

            const redirectRoute = (
                <Route key="redirect" exact path={basePath}>
                    <Redirect to={getDefaultPath()} />
                </Route>
            );

            const notFoundRoute = (
                <Route key="notFound">
                    <NotFound />
                </Route>
            );

            return [...routes, redirectRoute, notFoundRoute];
        }
    };

    return (
        <Switch>
            {getPrivateRoutes()}
            <Route
                path={LOGIN_WITH_CREDENTIALS_PATH}
                render={routeProps => <LoginComponent {...state} {...routeProps} />}
            />
        </Switch>
    );
};

RouteContainer.propTypes = {
    basePath: PropTypes.string,
    defaultPath: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            path: PropTypes.string,
            Component: PropTypes.func,
        })
    ).isRequired,
    state: PropTypes.object,
};

export default RouteContainer;
