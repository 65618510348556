import gql from 'graphql-tag';
import FragmentFormElementDocuments from './FragmentFormElementDocuments';
import FragmentFormElementEnum from './FragmentFormElementEnum';
import FragmentFormElementField from './FragmentFormElementField';
import FragmentFormElementLocRecording from './FragmentFormElementLocRecording';
import FragmentFormElementPictures from './FragmentFormElementPictures';

const FragmentFormElementRow = gql`
    ${FragmentFormElementDocuments}
    ${FragmentFormElementEnum}
    ${FragmentFormElementField}
    ${FragmentFormElementLocRecording}
    ${FragmentFormElementPictures}

    fragment FormElementRow on FormElementRow {
        adjacentElements
        children {
            ...FormElementDocuments
            ...FormElementEnum
            ...FormElementField
            ...FormElementLocRecording
            ...FormElementPictures
        }
        description
        id
        name
        options
        seqNumber
        typename
    }
`;

export default FragmentFormElementRow;
