import { CLASSNAME_DISPATCH } from '@graphql/consts';
import getEntityFilter from '@graphql/getEntityFilter';
import QueryDispatches from '@graphql/queries/QueryDispatches';

const getQueryConfig = mapVariables => {
    return {
        query: QueryDispatches,
        variables: { filter: getEntityFilter([CLASSNAME_DISPATCH]), ...mapVariables },
    };
};

export default getQueryConfig;
