import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import { ContentRoot } from '@geomagic/layout';
import { i18n } from '@geomagic/i18n';

import getSridFromEPSGCode from '@components/Map/utils/getSridFromEPSGCode';
import getViewByCode from '@components/Map/utils/getViewByCode';
import getWorldByCode from '@components/Map/utils/getWorldByCode';
import RouteContainer from '@components/RouteContainer';
import useGeneralQueries from '@utils/useGeneralQueries';
import useSSO from '@utils/useSSO';

import { LOGIN_WITH_CREDENTIALS_PATH, LOGIN_WITH_TOKEN_PATH } from '@consts';

const useStyles = makeStyles(({ breakpoints, palette, spacing, zIndex }) => {
    return {
        loadingContainer: {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
        },
    };
});

const AppContainer = props => {
    const { appProps, config, state } = props;
    const { isOnline, isSSODisabled, location, loginStatus, onLogin, onRedirect, redirectPath, user } = state;
    const classes = useStyles();

    const {
        entityClasses,
        ssoSettings,
        systemInformation,
        worlds,
        loadingEntityClasses,
        loadingSSOSettings,
        loadingSystemInformation,
        loadingWorld,
    } = useGeneralQueries({ appProps, isOnline, user });

    const isLoading = loadingEntityClasses || loadingSystemInformation || loadingWorld;
    const world = getWorldByCode(worlds, appProps?.worldCode);
    const view = getViewByCode(world?.views, appProps?.viewCode);
    const srid = getSridFromEPSGCode(view?.epsgCode);

    // SSO

    const isLoginPath = [LOGIN_WITH_CREDENTIALS_PATH, LOGIN_WITH_TOKEN_PATH].includes(location.pathname);
    const portalUrl = ssoSettings?.redirectUrl;
    const tokenParamName = ssoSettings?.tokenParamName;
    const hasSSOSettings = !!(portalUrl && tokenParamName);

    /**
     *  EVENT HANDLER
     */

    const handleClickSSOLink = useCallback(() => {
        onRedirect(LOGIN_WITH_TOKEN_PATH);
    }, [onRedirect]);

    /**
     * EFFECTS
     */

    useEffect(() => {
        const isNotLoggedIn = !loginStatus || loginStatus === 'NOT_LOGGED_IN';
        if (!loadingSSOSettings && !isLoginPath && isNotLoggedIn && !isSSODisabled) {
            onRedirect(hasSSOSettings ? LOGIN_WITH_TOKEN_PATH : LOGIN_WITH_CREDENTIALS_PATH);
        }
    }, [hasSSOSettings, isLoginPath, isSSODisabled, loadingSSOSettings, loginStatus, onRedirect]);

    /**
     * SSO
     */

    useSSO({
        location,
        onLogin,
        path: LOGIN_WITH_TOKEN_PATH,
        portalUrl,
        redirectPath,
        tokenParamName,
        urlCheckErrorPath: LOGIN_WITH_CREDENTIALS_PATH,
    });

    /**
     *  COMPONENTS
     */

    const getSSOComponent = () => {
        return (
            <Link componenent={RouterLink} onClick={handleClickSSOLink} style={{ cursor: 'pointer' }}>
                {i18n.t('label.ssoLogin')}
            </Link>
        );
    };

    /**
     *  APP STATE
     */

    const componentConfig = useMemo(() => config(), [config]);
    const { apps, LoadingComponent, LoginComponent, LogoComponent } = componentConfig;

    const appState = {
        ...state,
        entityClasses,
        LoadingComponent,
        LoginComponent,
        Logo: LogoComponent,
        onLogin,
        srid,
        systemInformation,
        view,
        world,
        worlds,
        ...(hasSSOSettings && { ssoLink: getSSOComponent() }),
    };

    return (
        <ContentRoot scrollable={false} withPadding={false}>
            {isLoading ? (
                <div className={classes.loadingContainer}>{LoadingComponent && <LoadingComponent />}</div>
            ) : (
                <RouteContainer defaultPath="/" items={apps} state={appState} />
            )}
        </ContentRoot>
    );
};

AppContainer.propTypes = {
    appProps: PropTypes.object,
    config: PropTypes.func.isRequired,
    graphqlErrorHandlerRef: PropTypes.object.isRequired,
    state: PropTypes.object,
};

export default AppContainer;
