import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import { ENTITY_UNIQ_KEY } from './consts';

const getDiffs = (input, output, key = ENTITY_UNIQ_KEY) => {
    const added = differenceBy(output, input, key);
    const removed = differenceBy(input, output, key);

    const addedRemovedIds = [...added.map(item => item[key]), ...removed.map(item => item[key])];

    const updated = differenceWith(output, input, isEqual).filter(diff => !addedRemovedIds.includes(diff[key]));

    return {
        added,
        removed,
        updated,
    };
};

export default getDiffs;
