import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ palette, shape, spacing, typography }) => {
    return {
        root: {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
        },
        container: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 300,
        },
        icon: {
            color: palette.grey[300],
            fontSize: typography.pxToRem(120),
        },
        title: {
            marginBottom: spacing(),
            textAlign: 'center',
            whiteSpace: 'break-spaces',
        },
        subtitle: {
            marginBottom: spacing(),
            textAlign: 'center',
            whiteSpace: 'break-spaces',
        },
        action: {
            marginTop: spacing(),
        },
    };
});

const Placeholder = props => {
    const classes = useStyles(props);
    const { action, className, icon, iconProps, subtitle, subtitleProps, title, titleProps } = props;

    return (
        <Grow in timeout={500}>
            <div className={classNames(classes.root, className)}>
                <div className={classes.container}>
                    {React.cloneElement(icon, { className: classes.icon, fontSize: 'large', ...iconProps })}
                    <Typography className={classes.title} color="textPrimary" variant="h6" {...titleProps}>
                        {title}
                    </Typography>
                    <Typography className={classes.subtitle} color="textSecondary" variant="body2" {...subtitleProps}>
                        {subtitle}
                    </Typography>
                    <div className={classes.action}>{action}</div>
                </div>
            </div>
        </Grow>
    );
};

Placeholder.propTypes = {
    action: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.node,
    iconProps: PropTypes.object,
    subtitle: PropTypes.string,
    subtitleProps: PropTypes.object,
    title: PropTypes.string,
    titleProps: PropTypes.object,
};

export default Placeholder;
