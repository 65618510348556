import React from 'react';
import classNames from 'classnames';
import jss from 'jss';
import preset from 'jss-preset-default';

import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { showModal as showCustomModal } from '@geomagic/core';
import { PRIMARY_TRIGGER_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';

jss.setup(preset());

const sheet = jss
    .createStyleSheet({
        toolbar: {
            minHeight: 56,
            paddingLeft: 0,
            paddingRight: 8,
        },
        dialogTitle: {
            padding: 16,
            '&$fullScreen': {
                padding: 0,
            },
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: 0,
            '&$fullScreen': {
                marginTop: 56,
            },
        },
        title: {
            fontWeight: 'bold',
        },
        menuButton: {
            height: MOBILE_TRIGGER_SIZE,
            margin: 0,
            width: MOBILE_TRIGGER_SIZE,
        },
        fullScreen: {},
    })
    .attach();

/**
 * Provides an custom show modal function
 *
 * @param {Object} params
 * @param {*} params.content - modal content
 * @param {*} params.title - modal title
 * @param {function} params.onOk - modal ok function
 * @returns {void}
 */

const showModal = params => {
    const { isFullScreen, okAction, onOk, title, ...other } = params;
    const { classes } = sheet;

    const dialogContentClasses = { root: classNames(classes.dialogContent, { [classes.fullScreen]: isFullScreen }) };
    const dialogTitleClasses = {
        root: classNames(classes.dialogTitle, { [classes.fullScreen]: isFullScreen }),
        toolbar: classes.toolbar,
        menuButton: classes.menuButton,
    };

    showCustomModal({
        maxWidth: 'sm',
        DialogTitleProps: {
            classes: dialogTitleClasses,
            ...(isFullScreen && { iconLeft: <ChevronLeftIcon /> }),
        },
        DialogContentProps: {
            classes: dialogContentClasses,
        },
        isMobile: isFullScreen,
        okAction: okAction || onOk ? okAction : <div />,
        okActionProps: PRIMARY_TRIGGER_PROPS,
        onOk,
        title: (
            <Typography className={classes.title} noWrap variant="body1">
                {title}
            </Typography>
        ),
        withDivider: true,
        ...other,
    });
};

export default showModal;
