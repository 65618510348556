import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { FORM_COMPONENT_MIN_HEIGHT } from '../consts';
import getKey from '../utils/getKey';

const DEFAULT_LISTITEM_PROPS = {
    noWrap: true,
};

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        margin: spacing(1, 0.5),
        width: 200,
        [breakpoints.down('sm')]: {
            flex: 'initial',
            width: '100%',
        },
        [breakpoints.down('md')]: {
            marginBottom: spacing(1),
        },
    },
    readOnly: {
        pointerEvents: 'none',
    },
    select: {
        '& .MuiSelect-select': {
            alignItems: 'center',
            display: 'flex',
            height: FORM_COMPONENT_MIN_HEIGHT,
            paddingBottom: 0,
            paddingTop: 0,
        },
        '& label': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 'calc(100% - 32px)',
        },
    },
    menuItem: {
        minHeight: 40,
    },
}));

const EnumSelect = props => {
    const { context, data, path } = props;
    const { isMobile, isReadOnly, onUpdate } = context;
    const { mandatory, name, statusValue, statusValues } = data;
    const extendedStatusValues = [{ name: null }, ...statusValues];

    const [selectedStatusValue, setSelectedStatusValue] = useState(statusValue);
    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleChange = event => {
        const newStatusValue = statusValues.find(({ name: statusValueName }) => statusValueName === event.target.value);

        if (statusValue !== newStatusValue) {
            setSelectedStatusValue(newStatusValue);
            onUpdate({ statusValue: newStatusValue }, path, data);
        }
    };

    return (
        <div className={classNames(classes.root, { [classes.readOnly]: isReadOnly })}>
            <TextField
                className={classes.select}
                fullWidth
                label={name}
                margin="none"
                SelectProps={{ native: isMobile }}
                onChange={handleChange}
                required={mandatory}
                select
                value={selectedStatusValue ? selectedStatusValue.name : ''}
                variant="outlined"
            >
                {extendedStatusValues.map(({ label, name: statusValueName }, index) =>
                    isMobile ? (
                        <option key={getKey(statusValueName, index)} value={statusValueName}>
                            {statusValueName}
                        </option>
                    ) : (
                        <MenuItem
                            className={classes.menuItem}
                            key={getKey(statusValueName, index)}
                            value={statusValueName}
                        >
                            <ListItemText primary={statusValueName} primaryTypographyProps={DEFAULT_LISTITEM_PROPS} />
                        </MenuItem>
                    )
                )}
            </TextField>
        </div>
    );
};

EnumSelect.propTypes = {
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default EnumSelect;
