import gql from 'graphql-tag';
import { FragmentAttributeValues } from '@geomagic/geonam-graphql';

const FragmentDocument = gql`
    ${FragmentAttributeValues}

    fragment Document on Entity {
        ...AttributeValues
        id
        className
        displayName
        entityType {
            id
            className
        }
        properties(keys: ["contentType", "hash", "webDavUrl"])
    }
`;

export default FragmentDocument;
