import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pointer from 'json-pointer';
import { useSnackbar } from 'notistack';
import { useRxDB, useRxDocument } from 'rxdb-hooks';
import { makeStyles } from '@material-ui/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { hideModal, Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getPatch from '@database/getPatch';
import useShowPrompt from '@utils/useShowPrompt';

import DispatchDocuments from './DispatchDocuments';
import DispatchForm from './DispatchForm';
import DispatchMap from './DispatchMap';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    paper: {},
    tabs: {
        background: palette.background.default,
        borderBottom: `1px solid ${palette.divider}`,
    },
    tab: {
        textTransform: 'capitalize',
    },
    content: {
        background: palette.background.paper,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
    },
    triggerClose: {
        marginLeft: spacing(),
    },
}));

const getTabs = (documentsCount = 0) => {
    return [
        {
            id: 'form',
            name: i18n.t('dispatch.label.tabGeneral'),
            component: DispatchForm,
        },
        {
            id: 'map',
            name: i18n.t('dispatch.label.tabMap'),
            component: DispatchMap,
        },
        {
            id: 'documents',
            name: i18n.t('dispatch.label.tabDocuments', {
                variables: {
                    count: String(documentsCount),
                },
            }),
            component: DispatchDocuments,
        },
    ];
};

const getClosedElementPatch = ({ entity, path, updateId, update }) => {
    const items = pointer.get(entity, path);
    const updatedItems = items.map(item => (item.id === updateId ? { ...item, ...update } : item));

    return {
        op: 'replace',
        path,
        value: updatedItems,
    };
};

const DispatchDraftWizard = props => {
    const { className, entityId, entityClass, entityClasses, isMobile, mapProps, previousMap } = props;

    const database = useRxDB();
    const { result: dispatch } = useRxDocument('dispatches', entityId, {
        idAttribute: ENTITY_SELECTOR_KEY,
    });
    const entity = dispatch?.getPatchedEntity() || {};
    const { documents = [] } = entity;

    const draftRef = useRef();
    const showPrompt = useShowPrompt();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyles();

    const tabs = getTabs(documents.length);
    const TabComponent = tabs[activeStep].component || null;
    const entityTypes = entityClass?.entityTypes;
    const isDraft = !!dispatch?.draft;
    const isDraftClosed = !!dispatch?.draft?.closed;

    /**
     *  GENERAL PROPS
     */

    const triggerProps = {
        size: 'medium',
    };

    /**
     *  EVENT HANDLER
     */

    const handleChangeTab = (event, newValue) => {
        const execute = () => setActiveStep(newValue);

        handleFormValidation(execute);
    };

    const handleFormValidation = execute => {
        const onValidateForm = draftRef?.current?.onValidateForm;
        const formStepIndex = tabs.findIndex(({ id }) => id === 'form');

        if (!isDraftClosed && onValidateForm && activeStep === formStepIndex) {
            onValidateForm()
                .then(() => {
                    execute();
                })
                .catch(error => console.log(error));
        } else {
            execute();
        }
    };

    const handlePromptCloseDraft = () => {
        const execute = () => {
            const entity = dispatch.getPatchedEntity();
            const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
            const name = entityType?.name;

            showPrompt({
                title: i18n.t('dispatch.dialog.closeDraft.title'),
                content: i18n.t('dispatch.dialog.closeDraft.content', { variables: { name } }),
                onOk: () => handleCloseDraft(dispatch),
            });
        };

        handleFormValidation(execute);
    };

    const handleCloseDraft = async () => {
        const { uuid, relations } = dispatch;
        const update = { closed: true };

        if (relations) {
            const assignments = relations.filter(({ type }) => type === 'Assignment');

            for (var i = 0; i < assignments.length; i++) {
                const { id, path } = assignments[i];

                const collection = database.assignments;
                const selector = { [ENTITY_SELECTOR_KEY]: id };
                const assignmentDoc = await collection.findOne({ selector }).exec();
                const jsonPatch = assignmentDoc?.jsonPatch;
                const patchedEntity = assignmentDoc.getPatchedEntity();

                const newPatch = getClosedElementPatch({ entity: patchedEntity, path, updateId: uuid, update });

                await assignmentDoc.atomicUpdate(oldData => {
                    oldData.jsonPatch = getPatch(jsonPatch, newPatch);
                    return oldData;
                });
            }
        }

        await dispatch.atomicUpdate(oldData => {
            oldData.draft = { ...oldData.draft, closed: true };
            return oldData;
        });

        hideModal();

        enqueueSnackbar(i18n.t('dispatch.notification.closedDraft'), {
            key: 'closedDraft',
            preventDuplicate: true,
            variant: 'success',
        });
    };

    const handleUpdateDraft = async newPatch => {
        const jsonPatch = dispatch?.jsonPatch;

        await dispatch.atomicUpdate(oldData => {
            oldData.jsonPatch = getPatch(jsonPatch, newPatch);
            return oldData;
        });
    };

    /**
     *  COMPONENTS
     */

    const CloseComponent = (
        <div>
            {isDraft && !isDraftClosed && (
                <Trigger
                    className={classes.triggerClose}
                    onClick={handlePromptCloseDraft}
                    {...PRIMARY_TRIGGER_PROPS}
                    {...triggerProps}
                >
                    {i18n.t('dispatch.button.closeDraft')}
                </Trigger>
            )}
        </div>
    );

    const getStepComponent = () => {
        return (
            <TabComponent
                CloseComponent={CloseComponent}
                doc={dispatch}
                draftRef={draftRef}
                entityClass={entityClass}
                entityClasses={entityClasses}
                entityTypes={entityTypes}
                isDraft={isDraft}
                isMobile={isMobile}
                isReadOnly={isDraftClosed}
                mapProps={mapProps}
                onClose={handleCloseDraft}
                onChange={handleUpdateDraft}
                step={tabs[activeStep]}
                triggerProps={triggerProps}
                previousMap={previousMap}
            />
        );
    };

    return (
        <div className={classNames(classes.root, className)}>
            <Paper className={classes.paper} square>
                <Tabs
                    className={classes.tabs}
                    indicatorColor="primary"
                    onChange={handleChangeTab}
                    textColor="primary"
                    value={activeStep}
                    variant="fullWidth"
                >
                    {tabs.map(({ id, name }) => (
                        <Tab key={id} className={classes.tab} label={name} />
                    ))}
                </Tabs>
            </Paper>

            <div className={classes.content}>{dispatch && entityTypes && getStepComponent()}</div>
        </div>
    );
};

DispatchDraftWizard.propTypes = {
    className: PropTypes.string,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    entityClass: PropTypes.object,
    entityClasses: PropTypes.array,
    isMobile: PropTypes.bool,
    mapProps: PropTypes.object.isRequired,
    previousMap: PropTypes.object.isRequired,
};

export default DispatchDraftWizard;
