import pointer from 'json-pointer';
import { getAttributeTypesByClassAndType } from '@geomagic/geonam';
import { MutationUpdateEntity } from '@geomagic/geonam-graphql';

const transformer = ({ attributeType, value }) => ({
    attributeTypeId: attributeType.id,
    value,
});

const updateEntity = async ({ client, entityClasses, entityReference, entity, patchedEntity, path }) => {
    const attributeTypes = getAttributeTypesByClassAndType(entityClasses, entity?.className, entity?.entityType?.id);
    const patchedAttributeValues = pointer.get(patchedEntity, path);

    const attributeValues = patchedAttributeValues.map(transformer).filter(({ attributeTypeId }) => {
        const { readOnly } = attributeTypes.find(({ id }) => id === attributeTypeId);
        return !readOnly;
    });

    await client.mutate({
        mutation: MutationUpdateEntity,
        variables: { entity: { ...entityReference, attributeValues } },
    });
};

export default updateEntity;
