const removeDocs = async (docs = []) => {
    for (var i = 0; i < docs.length; i++) {
        try {
            const doc = docs[i];
            await doc.remove();
        } catch (error) {
            console.error(error);
        }
    }
};

export default removeDocs;
