import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
}));

const Accordion = React.forwardRef((props, ref) => {
    const { children, className, onSelect, selectedId } = props;
    const classes = useStyles(props);
    const [selectedItemId, setSelectedItemId] = useState(selectedId);
    const itemId = onSelect ? selectedId : selectedItemId;

    /**
     *  EVENT HANDLER
     */

    const handleSelect = React.useCallback(
        (event, isExpanded, id, selectedItemRef) => {
            const newId = isExpanded ? id : null;
            onSelect ? onSelect(event, isExpanded, newId, selectedItemRef) : setSelectedItemId(newId);
        },
        [onSelect]
    );

    return (
        <div className={classNames(classes.root, className)}>
            {React.Children.map(children, (child, idx) => {
                const expanded = child.props.id === itemId;

                return React.cloneElement(child, {
                    id: child.props.id,
                    expanded,
                    onSelect: handleSelect,
                });
            })}
        </div>
    );
});

Accordion.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onSelect: PropTypes.func,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Accordion;
