import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
    <Fragment>
        <rect x="2.02" y="2.02" width="19.98" height="2" />
        <rect x="1.94" y="19.96" width="20.06" height="2" />
    </Fragment>,
    'Rule'
);
