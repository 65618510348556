/**
 * Displays a data or a group item in a masonry/fitting style corresponding to the width.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const EXPAND_ICON_WIDTH = 24;

const useStyles = makeStyles(({ spacing, palette, typography }) => {
  const getStyles = (grid) => ({ width: `${100 / grid}%` });

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    dataGroupFields: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    field: {
      alignSelf: 'center',
      width: '100%',
    },
    group: {
      borderLeft: `1px dashed ${palette.action.active}`,
      marginLeft: EXPAND_ICON_WIDTH / 2,
      '&$marginLeft': {
        marginLeft: EXPAND_ICON_WIDTH,
      },
    },
    leftPadding: {
      paddingLeft: spacing(2),
    },
    listItemContainer: {
      flex: 1,
    },
    listItemIcon: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minWidth: EXPAND_ICON_WIDTH,
      '&$marginLeft': {
        marginLeft: EXPAND_ICON_WIDTH / 2,
      },
    },
    listItemText: {
      fontWeight: 'bold',
      color: palette.primary.main,
    },
    listItemTextError: {
      fontWeight: 'bold',
      color: palette.error.main,
    },
    marginLeft: {},
    sm: getStyles(1),
    md: getStyles(2),
    lg: getStyles(3),
    xl: getStyles(4),
  };
});

const getLength = (values) => {
  let length = 0;
  for (let index = 0; index < values.length; index++) {
    if (Array.isArray(values[index])) {
      length += values[index].length;
    } else {
      length += 1;
    }
  }
  return length;
};

const getRows = (field = {}) => {
  const { props } = field;
  return props?.options?.rows || props?.fieldUI?.rows;
};

const setIntitialState = (field, expandedGroups, setExpandedGroups) => {
  const { values = [], isGroup = false, key } = field;
  if (isGroup) {
    const isGroupOpen = expandedGroups[key];
    if (isGroupOpen === undefined) {
      setExpandedGroups((previousExpandedGroups) => ({ ...previousExpandedGroups, [key]: !!isGroupOpen }));
    }
    values.forEach((childField) => {
      setIntitialState(childField, expandedGroups, setExpandedGroups);
    });
  }
};

const DataGroupItem = (props) => {
  const classes = useStyles(props);
  const { dataGroup, depth = 0, elements, expandedGroups, getId, path, setExpandedGroups, width = 0 } = props;
  const { values = [], name, isGroup = false, isError = false, key } = dataGroup;
  const title = isGroup ? `${name} (${getLength(values)})` : '';

  const isGroupOpen = isGroup && expandedGroups[key];
  const { breakpoints } = useTheme();
  const { values: breakpoint } = breakpoints;
  let indexDecrement = 0;

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (isGroup && expandedGroups && setExpandedGroups) {
      setIntitialState(dataGroup, expandedGroups, setExpandedGroups);
    }
    //Only on monunt groups writes itself in expanded groups object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  EVENT HANDLER
   */

  const handleClickGroup = (event) => {
    event.stopPropagation();
    setExpandedGroups &&
      setExpandedGroups((previousExpandedGroups) => ({
        ...previousExpandedGroups,
        [key]: !previousExpandedGroups[key],
      }));
  };

  const getMasonryClasses = (rows, currentWidth, index, isDepth) => {
    const isMultiline = rows > 1;
    let fieldsPerRow = 1;

    if (currentWidth >= breakpoint.sm && currentWidth < breakpoint.md) {
      fieldsPerRow = 2;
    } else if (currentWidth >= breakpoint.md && currentWidth < breakpoint.lg) {
      fieldsPerRow = 3;
    } else if (currentWidth >= breakpoint.lg) {
      fieldsPerRow = 4;
    }

    if (isMultiline) {
      indexDecrement += (index % fieldsPerRow) + 1;
      fieldsPerRow = 1;
    }

    switch (fieldsPerRow) {
      case 2:
        return classNames(classes.md, { [classes.leftPadding]: isDepth || index % 2 !== 0 });
      case 3:
        return classNames(classes.lg, { [classes.leftPadding]: isDepth || index % 3 !== 0 });
      case 4:
        return classNames(classes.xl, { [classes.leftPadding]: isDepth || index % 4 !== 0 });
      default:
        return classNames(classes.sm, { [classes.leftPadding]: isDepth });
    }
  };

  /*
   *  ANIMATION
   */

  const variants = {
    expanded: {
      height: 'auto',
      transition: {
        when: 'beforeChildren',
      },
    },
    collapsed: {
      height: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const animation = {
    variants,
    initial: !isGroupOpen ? 'collapsed' : 'expanded',
    animate: !isGroupOpen ? 'collapsed' : 'expanded',
  };

  return (
    <div className={classes.root}>
      {isGroup && (
        <ListItem button classes={{ container: classes.listItemContainer }} disableGutters onClick={handleClickGroup}>
          <ListItemIcon className={classNames(classes.listItemIcon, { [classes.marginLeft]: depth > 0 })}>
            {isGroupOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </ListItemIcon>
          <ListItemText
            className={isError ? classes.listItemTextError : classes.listItemText}
            primary={
              <Typography variant="subtitle2" noWrap>
                {title}
              </Typography>
            }
          />
        </ListItem>
      )}
      {(isGroupOpen || !isGroup) && (
        <motion.div
          className={classNames(
            { [classes.group]: isGroup },
            { [classes.marginLeft]: isGroup && depth > 0 },
            classes.dataGroupFields
          )}
          {...(isGroup && animation)}
        >
          {values.map((value, index) => {
            const field = elements.find((element) => getId(element) === value);
            const rows = getRows(field);
            return field ? (
              <div
                key={value}
                className={classNames(
                  classes.field,
                  getMasonryClasses(rows, width - EXPAND_ICON_WIDTH, index - indexDecrement, isGroup)
                )}
              >
                {field}
              </div>
            ) : (
              <DataGroupItem
                dataGroup={value}
                depth={depth + 1}
                elements={elements}
                expandedGroups={expandedGroups}
                getId={getId}
                key={value.name}
                path={path.concat([value.name])}
                setExpandedGroups={setExpandedGroups}
                width={width - EXPAND_ICON_WIDTH}
              />
            );
          })}
        </motion.div>
      )}
    </div>
  );
};

DataGroupItem.propTypes = {
  dataGroup: PropTypes.object,
  depth: PropTypes.number,
  elements: PropTypes.array,
  expandedGroups: PropTypes.object,
  getId: PropTypes.func,
  getMasonryClasses: PropTypes.func,
  getRows: PropTypes.func,
  path: PropTypes.array,
  setExpandedGroups: PropTypes.func.isRequired,
  width: PropTypes.number,
};

export default DataGroupItem;
