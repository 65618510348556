import isEmpty from 'lodash/isEmpty';
import { DEFAULT_NUMBER_FORMAT } from '@consts';

import parseLocaleNumber from './parseLocaleNumber';

/**
 * Get a <NumberFormat /> props
 * @param {Object} attributeType - GeoNAM attribute type
 * @returns {Object}
 */

const getNumberFormatObject = attributeType => {
    let suffix = attributeType.unit ? ' ' + attributeType.unit : '';
    let format, removeFormatting;
    const { options } = attributeType;

    if (isEmpty(options)) {
        return {
            ...DEFAULT_NUMBER_FORMAT,
            suffix,
        };
    }

    const { groupingSize, multiplier, minFractionDigits, maxFractionDigits, minIntegerDigits } = options;
    if (multiplier > 1) {
        if (multiplier === 100) {
            suffix = ' %';
        }
        format = value => {
            const formattedValue = value
                ? Intl.NumberFormat(undefined, {
                      useGrouping: groupingSize > 0,
                      minimumIntegerDigits: minIntegerDigits,
                      minimumFractionDigits: minFractionDigits,
                      maximumFractionDigits: maxFractionDigits,
                  }).format(value * multiplier) + suffix
                : '';
            return formattedValue;
        };
        removeFormatting = formattedValue => {
            const suffixLastIndex = formattedValue.lastIndexOf(suffix);
            const value =
                suffix && suffixLastIndex !== -1 && suffixLastIndex === formattedValue.length - suffix.length
                    ? parseLocaleNumber(formattedValue.substring(0, suffixLastIndex))
                    : parseLocaleNumber(formattedValue);

            return value / multiplier;
        };
    } else {
        format = value => {
            const formattedValue = value
                ? Intl.NumberFormat(undefined, {
                      useGrouping: groupingSize > 0,
                      minimumIntegerDigits: minIntegerDigits,
                      minimumFractionDigits: minFractionDigits,
                      maximumFractionDigits: maxFractionDigits,
                  }).format(value) + suffix
                : '';
            return formattedValue;
        };
        removeFormatting = formattedValue => {
            const suffixLastIndex = formattedValue.lastIndexOf(suffix);
            const value =
                suffix && suffixLastIndex !== -1 && suffixLastIndex === formattedValue.length - suffix.length
                    ? parseLocaleNumber(formattedValue.substring(0, suffixLastIndex))
                    : parseLocaleNumber(formattedValue);

            return value;
        };
    }

    const thousandSeparator = groupingSize > 0 ? '.' : '';
    const decimalScale = maxFractionDigits;
    return {
        ...DEFAULT_NUMBER_FORMAT,
        thousandSeparator,
        decimalScale,
        suffix,
        fixedDecimalScale: minFractionDigits === maxFractionDigits,
        format,
        removeFormatting,
    };
};

export default getNumberFormatObject;
