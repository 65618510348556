import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import pointer from 'json-pointer';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useRxDB, useRxData } from 'rxdb-hooks';

import { getEntityType, getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';

import PullToRefresh from '@components/PullToRefresh';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getDefaultSync from '@database/getDefaultSync';
import getUpdatedDoc from '@database/getUpdatedDoc';
import getPatch from '@database/getPatch';
import resetDoc from '@database/resetDoc';
import { CLASSNAME_DISPATCH } from '@graphql/consts';
import QueryDispatch from '@graphql/queries/QueryDispatch';
import showModal from '@utils/showModal';
import useShowPrompt from '@utils/useShowPrompt';

import AddDraftTrigger from './AddDraftTrigger';
import DispatchDraftWizard from './DispatchDraftWizard';
import DispatchList from './DispatchList';
import Journal from '../Journal';
import Checklist from '../Checklist';
import Tasks from '../Tasks';

const useStyles = makeStyles(({ breakpoints, palette, spacing, zIndex }) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
        },
    };
});

const getFilteredElementPatch = ({ entity, path, filterId }) => {
    const items = pointer.get(entity, path);
    const filteredItems = items.filter(item => item.id !== filterId);

    return {
        op: 'replace',
        path,
        value: filteredItems,
    };
};

const queryConstructor = (queryProps, user) => collection => {
    return collection.find({ selector: { ...queryProps, userId: user.id }, sort: [{ modifiedOn: 'desc' }] });
};

const Dispatch = props => {
    const {
        client,
        docRelations,
        entityClasses,
        getPreviousMap,
        isLoading: isLoadingView,
        isMobile,
        isOnline,
        isReadOnly,
        LoadingComponent,
        mapProps,
        onAddedDraft,
        onCloseSwipeableArea,
        onFetchData,
        queryProps,
        user,
    } = props;

    const userId = user.id;
    const entityClassDispatch = entityClasses?.find(
        ({ className: entityClassName }) => entityClassName === CLASSNAME_DISPATCH
    );

    const database = useRxDB();
    const { result: dispatches, isFetching } = useRxData('dispatches', queryConstructor(queryProps, user));
    const showPrompt = useShowPrompt();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const isLoading = isLoadingView || isFetching;

    /**
     *  EVENT HANDLER
     */

    const handleClickDispatch = doc => {
        const { draft, entity } = doc;
        const patchedEntity = doc.getPatchedEntity();
        const entityType = getEntityType(entityClasses, patchedEntity?.className, patchedEntity?.entityType?.id);
        const title = !!draft ? entityType?.name : patchedEntity?.displayName;

        showModal({
            title: title,
            content: (
                <DispatchDraftWizard
                    entityId={entity?.id}
                    entityClass={entityClassDispatch}
                    entityClasses={entityClasses}
                    isMobile={isMobile}
                    mapProps={mapProps}
                />
            ),
            isFullScreen: true,
        });
    };

    const handleOpenChecklist = data => {
        const entity = data?.getPatchedEntity();
        const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
        const name = entity?.displayName || entityType?.name;
        showModal({
            title: i18n.t('checklist.dialog.title', { variables: { name } }),
            content: <Checklist data={data} entityClasses={entityClasses} />,
            isFullScreen: isMobile,
        });
    };

    const handleOpenJournal = data => {
        const entity = data?.getPatchedEntity();
        const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
        const name = entity?.displayName || entityType?.name;
        showModal({
            title: i18n.t('journal.dialog.title', { variables: { name } }),
            content: <Journal data={data} user={user} />,
            isFullScreen: true,
        });
    };

    const handleOpenTasks = data => {
        const entity = data?.getPatchedEntity();
        const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
        const name = entity?.displayName || entityType?.name;
        const { checklistItems = [] } = entity;

        const areChecklistItemsChecked = checklistItems.every(item => item.checked);
        const isCloseable = areChecklistItemsChecked && isOnline;
        showModal({
            title: i18n.t('dialog.tasks.title', { variables: { name } }),
            content: (
                <ApolloProvider client={client}>
                    <Tasks
                        closeableErrorText={i18n.t('dispatch.description.checklistItemsNotChecked')}
                        data={data}
                        entityClasses={entityClasses}
                        isCloseable={isCloseable}
                        isMobile={isMobile}
                        isOnline={isOnline}
                        onSyncEntity={handleSyncDispatch}
                        onUpdateEntity={handleUpdateDispatch}
                        user={user}
                    />
                </ApolloProvider>
            ),
            isFullScreen: isMobile,
        });
    };

    const handlePromptRemoveDraft = (name, doc) => {
        showPrompt({
            title: i18n.t('dispatch.dialog.removeDraft.title'),
            content: i18n.t('dispatch.dialog.removeDraft.content', { variables: { name } }),
            onOk: () => handleRemoveDraft(doc),
        });
    };

    const handleRemoveDraft = async doc => {
        const { uuid, relations } = doc;

        await doc.remove();

        if (relations) {
            const assignments = relations.filter(({ type }) => type === 'Assignment');

            for (var i = 0; i < assignments.length; i++) {
                const { id, path } = assignments[i];

                const newPatch = [];

                const collection = database.assignments;
                const selector = { [ENTITY_SELECTOR_KEY]: id };
                const assignmentDoc = await collection.findOne({ selector }).exec();
                const jsonPatch = assignmentDoc?.jsonPatch;
                const patchedEntity = assignmentDoc.getPatchedEntity();

                const patch = getFilteredElementPatch({ entity: patchedEntity, path, filterId: uuid });
                newPatch.push(patch);

                await assignmentDoc.atomicUpdate(oldData => {
                    oldData.jsonPatch = getPatch(jsonPatch, newPatch);
                    return oldData;
                });
            }
        }

        enqueueSnackbar(i18n.t('dispatch.notification.removedDraft'), {
            key: 'removedDraft',
            preventDuplicate: true,
            variant: 'success',
        });
    };

    const handleSyncDispatch = async doc => {
        const syncProps = {
            client,
            database,
            doc,
            entityClasses,
            mapProps,
            refetchQuery: QueryDispatch,
        };
        await getDefaultSync(syncProps);
    };

    const handleUpdateDispatch = async doc => {
        const entityReference = getReference(doc?.entity);
        const updatedEntity = await getUpdatedDoc(client, QueryDispatch, entityReference, mapProps);

        if (updatedEntity) {
            await resetDoc(doc, updatedEntity);
        }
    };

    return (
        <ContentRoot className={classes.root} scrollable={false} withPadding={false}>
            <PullToRefresh
                isPullable={!!(!isLoading && isOnline && !queryProps)}
                LoadingComponent={LoadingComponent}
                onRefresh={onFetchData}
            >
                <DispatchList
                    areMapActionsDisabled={!!queryProps}
                    client={client}
                    dispatches={dispatches}
                    entityClasses={entityClasses}
                    isLoading={isLoading}
                    isMobile={isMobile}
                    isOnline={isOnline}
                    mapProps={mapProps}
                    onClick={handleClickDispatch}
                    onCloseSwipeableArea={onCloseSwipeableArea}
                    onOpenChecklist={handleOpenChecklist}
                    onOpenJournal={handleOpenJournal}
                    onOpenTasks={handleOpenTasks}
                    onRemoveDraft={handlePromptRemoveDraft}
                    onSyncDispatch={handleSyncDispatch}
                    onUpdateDispatch={handleUpdateDispatch}
                    user={user}
                />
            </PullToRefresh>
            {!isReadOnly && (
                <AddDraftTrigger
                    docRelations={docRelations}
                    entityClass={entityClassDispatch}
                    entityClasses={entityClasses}
                    getPreviousMap={getPreviousMap}
                    isMobile={isMobile}
                    mapProps={mapProps}
                    onAddedDraft={onAddedDraft}
                    userId={userId}
                />
            )}
        </ContentRoot>
    );
};

Dispatch.propTypes = {
    client: PropTypes.object.isRequired,
    docRelations: PropTypes.array,
    entityClasses: PropTypes.array.isRequired,
    getPreviousMap: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isMobile: PropTypes.bool,
    isOnline: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    LoadingComponent: PropTypes.func,
    mapProps: PropTypes.object.isRequired,
    onAddedDraft: PropTypes.func,
    onCloseSwipeableArea: PropTypes.func,
    onFetchData: PropTypes.func,
    queryProps: PropTypes.object,
    user: PropTypes.object,
};

export default Dispatch;
