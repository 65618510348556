import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
    root: {
        display: 'flex',
        minWidth: 48,
    },
}));

const ConflictResolutionAvatar = props => {
    const { checkedItems, data, onSelectCheckbox } = props;
    const { doc } = data;
    const isChecked = checkedItems.find(item => item === doc.uuid);

    const classes = useStyles();

    /**
     *  EVENT HANDLER
     */

    const handleChange = event => {
        onSelectCheckbox({ id: doc.uuid, checked: event.target.checked });
    };

    return (
        <div className={classes.root}>
            <Checkbox checked={!!isChecked} color="primary" onChange={handleChange} />
        </div>
    );
};

ConflictResolutionAvatar.propTypes = {
    data: PropTypes.object.isRequired,
    onSelectCheckbox: PropTypes.func.isRequired,
};

export default ConflictResolutionAvatar;
