import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { transform } from '@geomagic/ol/proj';
import Geolocation from '@geomagic/ol/Geolocation';
import { get as getProjection } from '@geomagic/ol/proj.js';
import TrackLocationFeature from './TrackLocationFeature';
import MapErrorNotification from './MapErrorNotification';

const useStyles = makeStyles(
    ({ spacing }) =>
        createStyles({
            root: {
                display: 'flex',
                marginBottom: spacing(),
                pointerEvents: 'auto',
            },
            button: {
                width: 32,
                minWidth: 32,
                height: 32,
                borderRadius: 0,
            },
            icon: {
                fontSize: 18,
            },
        }),
    { name: 'LocationTracking' }
);

class LocationTrackingInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTracking: false,
            trackHeading: false,
            moveLocation: false,
            isTrackLocationError: false,
            currentLocation: {},
            rotation: '0',
            accuracy: 0,
            wasInitiallyCentered: false,
        };
    }

    handleOrientation = (trackedHeading, map) => {
        if (!isNaN(parseFloat(trackedHeading))) {
            const trackedNum = Number.parseFloat(trackedHeading).toFixed(10);
            const currentRotationNum = Number.parseFloat(map.getView().getRotation()).toFixed(10);
            this.setState({ rotation: trackedNum });
            if (trackedNum !== currentRotationNum && this.state.trackHeading) {
                map.getView().animate({ rotation: trackedHeading });
            }
        }
    };

    orientationFunc = event => {
        let heading = event.alpha * -0.0174532925;
        if (!isNaN(event.webkitCompassHeading)) {
            heading = event.webkitCompassHeading * 0.0174532925;
        }
        this.handleOrientation(heading, this.props.map);
    };

    centerLocation = loc => {
        const map = this.props.map;
        map.getView().setCenter(loc, null, true);

        if (this.state.trackHeading) {
            map.getView().animate({ rotation: '0' });
        }
    };

    initialCenter = loc => {
        const map = this.props.map;
        const { targetZoomOnTracking = 15 } = this.props || {};

        if (loc && (loc[0] !== 0 || loc[1] !== 0)) {
            const transformedLocation = transform(loc, 'EPSG:4326', map.getView().getProjection());
            if (
                map.getView().getCenter()[0] !== transformedLocation[0] ||
                map.getView().getCenter()[1] !== transformedLocation[1]
            ) {
                this.centerLocation(transformedLocation);
                map.getView().setZoom(targetZoomOnTracking);
                this.setState({ wasInitiallyCentered: true });
            }
            // we are already at this location, but not zoomed
            if (
                map.getView().getCenter()[0] === transformedLocation[0] &&
                map.getView().getCenter()[1] === transformedLocation[1]
            ) {
                map.getView().setZoom(targetZoomOnTracking);
                this.setState({ wasInitiallyCentered: true });
            }
        }
    };

    followCenter = loc => {
        const map = this.props.map;

        if (loc && (loc[0] !== 0 || loc[1] !== 0)) {
            const transformedLocation = transform(loc, 'EPSG:4326', map.getView().getProjection());
            if (
                map.getView().getCenter()[0] !== transformedLocation[0] ||
                map.getView().getCenter()[1] !== transformedLocation[1]
            ) {
                const throttledCenter = debounce(() => this.centerLocation(transformedLocation), 100);
                throttledCenter();
            }
        }
    };

    toggleTracking = () => {
        const { isFollowTracking, isTrackHeading } = this.props || {};

        if (!this.state.isTracking) {
            this.geolocation.setTracking(true);
            window.ondeviceorientation = this.orientationFunc;

            this.setState({
                isTracking: true,
                moveLocation: isFollowTracking,
                trackHeading: isTrackHeading,
            });
        } else {
            this.disableTracking();
        }
    };

    disableTracking = () => {
        const trackingOverlay = this.getTrackingOverlay();

        this.geolocation.setTracking(false);
        window.ondeviceorientation = null;
        trackingOverlay.getSource().clear();

        this.setState({
            isTracking: false,
            moveLocation: false,
            trackHeading: false,
            wasInitiallyCentered: false,
            currentLocation: {},
        });
    };

    getTrackingOverlay = () => {
        const layers = this.props.map.getLayers().getArray();

        return layers.find(layer => layer.get('name') === 'careTrackingOverlay');
    };

    handleChangeGeolocation = () => {
        const location = this.geolocation.getPosition();
        const accuracy = this.geolocation.getAccuracy();

        if (location) {
            this.setState({ currentLocation: { location, accuracy }, accuracy });

            if (!this.state.wasInitiallyCentered) {
                this.initialCenter(location);
            }

            if (this.state.moveLocation) {
                this.followCenter(location);
            }
        } else {
            this.setState({ currentLocation: {} });
        }
    };

    componentWillUnmount() {
        window.ondeviceorientation = null;
        this.geolocation.un('change', this.handleChangeGeolocation);
    }

    componentDidMount() {
        this.geolocation = new Geolocation({
            projection: getProjection('EPSG:4326'),
            tracking: false,
            trackingOptions: {
                maximumAge: 0,
            },
        });

        this.props.map.on('pointerdrag', () => {
            if (this.state.isTracking) {
                this.setState({ moveLocation: false });
            }
        });

        this.props.map.getView().on('change:resolution', event => {
            const location = this.geolocation.getPosition();
            if (location && this.state.moveLocation) {
                this.followCenter(location);
            }
        });

        this.geolocation.on(
            'error',
            throttle(err => {
                console.log('Fehler bei Positionsermittlung!');
                this.setState({ isTrackLocationError: true });
            }),
            5000
        );

        this.geolocation.on('change', this.handleChangeGeolocation);
    }

    render() {
        const { classes, className, map } = this.props;

        return (
            <Paper className={classNames(classes.root, className)} square>
                {!this.state.isTracking && !this.state.currentLocation.location ? (
                    <Button className={classes.button} onClick={this.toggleTracking}>
                        <LocationSearchingIcon className={classes.icon} />
                    </Button>
                ) : (
                    <Button className={classes.button} color="secondary" onClick={this.toggleTracking}>
                        {this.state.moveLocation ? (
                            <GpsFixedIcon className={classes.icon} />
                        ) : (
                            <LocationSearchingIcon className={classes.icon} />
                        )}
                    </Button>
                )}
                {this.state.currentLocation.location ? (
                    <TrackLocationFeature
                        isTrackLocation={this.state.moveLocation}
                        map={map}
                        trackingOverlay={this.getTrackingOverlay()}
                        rotation={this.state.rotation}
                        currentLocation={this.state.currentLocation}
                        accuracy={this.state.accuracy}
                    />
                ) : null}
                <MapErrorNotification
                    message="Positionsbestimmung nicht möglich"
                    isError={this.state.isTrackLocationError}
                    setError={() => this.setState({ isTrackLocationError: false })}
                />
            </Paper>
        );
    }
}

LocationTrackingInner.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    map: PropTypes.object.isRequired,
};

const LocationTracking = props => {
    const classes = useStyles(props);
    return <LocationTrackingInner {...props} classes={classes} />;
};
LocationTracking.propTypes = LocationTrackingInner.propTypes;

export default LocationTracking;
