import React from 'react';

import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import DispatchIcon from '@material-ui/icons/SpeakerNotes';

import { i18n } from '@geomagic/i18n';

import GeonamCircleIcon from '@geomagic/nam-react-core/icons/GeonamCircleIcon';

import AppBar from '@components/AppBar';
import Assignment from '@components/Assignment';
import Dispatch from '@components/Dispatch';
import Login from '@components/Login';
import Navigation from '@components/Navigation';
import ViewContainer from '@components/ViewContainer';

const getViewConfig = () => {
    return {
        AppBarComponent: AppBar,
        NavigationComponent: Navigation,
        views: [
            {
                id: 'assignment',
                name: i18n.t('type.assignments'),
                path: '/assignment',
                pathParams: '/:id?',
                Component: Assignment,
                Icon: AssignmentIcon,
            },
            {
                id: 'dispatch',
                name: i18n.t('type.dispatches'),
                path: '/dispatch',
                Component: Dispatch,
                Icon: DispatchIcon,
            },
        ],
    };
};

const getAppConfig = () => {
    return {
        apps: [
            {
                id: 'entity',
                path: '/',
                Component: props => <ViewContainer config={getViewConfig} state={props} />,
            },
        ],
        LoadingComponent: GeonamCircleIcon,
        LoginComponent: Login,
    };
};

export default getAppConfig;
