import loadImage from 'blueimp-load-image';

const resizeImage = async (file, options) => {
    const opts = {
        maxWidth: 1600,
        ...options,
        canvas: true,
    };

    return new Promise((resolve, reject) => {
        loadImage(
            file,
            canvas => {
                resolve(canvas);
            },
            opts
        );
    });
};

export default resizeImage;
