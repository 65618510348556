import getProgress from '@components/FormComponent/utils/getProgress';
import getDefaultFeatureStyle from '@components/Map/utils/getFeatureStyle';

const getFeatureStyle = (primaryColor, theme) => feature => {
    const { forms = [] } = feature?.get('entity') || {};
    const { progress, total } = getProgress(forms);

    let backgroundColor = primaryColor || theme?.palette?.primary[200];

    if (total > 0) {
        const isFinished = progress === total;
        backgroundColor = isFinished ? theme?.palette?.status.CLOSED : theme?.palette?.status.IN_PROGRESS;
    }

    const style = getDefaultFeatureStyle({ feature, backgroundColor });

    return style;
};

export default getFeatureStyle;
