export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_DISPLAY_FORMAT = 'DD.MM.YYYY';
export const TIME_DISPLAY_FORMAT = 'HH:mm:ss';
export const DATETIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT} ${TIME_DISPLAY_FORMAT}`;
export const DEFAULT_IMAGE_TYPE = 'image/jpeg';
export const DEFAULT_STATE_KEY = 'GlobalState';
export const LOGIN_TOKEN_KEY = 'LoginToken';
export const LOGIN_WITH_CREDENTIALS_PATH = '/login';
export const LOGIN_WITH_TOKEN_PATH = '/ssoLogin';
export const MOBILE_TRIGGER_SIZE = 40;

export const DEFAULT_DOCUMENT_ACCEPT_FORMATS = [
    'image/*',
    'application/msword',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
];

export const DEFAULT_LOCATION_ACCEPT_FORMATS = ['.gpx'];

export const DEFAULT_NUMBER_FORMAT = {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2,
};

export const DEFAULT_PICKER_PROPS = {
    inputVariant: 'outlined',
};

export const DEFAULT_SNACKBAR_PROPS = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
};

export const DEFAULT_TEXT_FIELD_PROPS = {
    variant: 'outlined',
    margin: 'normal',
};

export const DEFAULT_TRIGGER_ICON_PROPS = {
    color: 'default',
};

export const DEFAULT_TRIGGER_PROPS = {
    color: 'primary',
    disableElevation: true,
    size: 'small',
    variant: 'text',
};

export const SECONDARY_TRIGGER_PROPS = {
    color: 'primary',
    variant: 'text',
    size: 'small',
};

export const PRIMARY_TRIGGER_PROPS = {
    color: 'primary',
    variant: 'contained',
    size: 'small',
    disableElevation: true,
};
