import gql from 'graphql-tag';

const FragmentFormElementEnum = gql`
    fragment FormElementEnum on FormElementEnum {
        description
        displayedIfDefect
        id
        mandatory
        name
        seqNumber
        options
        statusValue {
            isDefectStatus
            name
        }
        statusValues {
            isDefectStatus
            name
        }
        typename
    }
`;

export default FragmentFormElementEnum;
