import getLineStringStyle from './getLineStringStyle';
import getPointStyle from './getPointStyle';
import getPolygonStyle from './getPolygonStyle';

/**
 * Get an object with styles for geometry types
 * @param {Object} params
 * @param {Object} params.feature
 * @param {string} params.backgroundColor
 * @param {Object} params.overrides
 * @returns {Object}
 */

const getFeatureStyle = params => {
    const { feature, backgroundColor, overrides = {}, zIndex } = params;
    const type = feature.getGeometry().getType();
    const styleOverrides = { backgroundColor, zIndex, ...overrides[type] };

    let style;

    if (type === 'Point') {
        style = getPointStyle(styleOverrides);
    }

    if (type === 'LineString') {
        style = getLineStringStyle(styleOverrides);
    }

    if (type === 'Polygon') {
        style = getPolygonStyle(styleOverrides);
    }

    return style;
};

export default getFeatureStyle;
