import isObject from 'lodash/isObject';
import { i18n } from '@geomagic/i18n';
import { transformExtent } from '@geomagic/ol/proj';

import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import useShowPrompt from '@utils/useShowPrompt';

import addToCache from './addToCache';
import fetchUrl from './fetchUrl';
import getVectorTileUrls from './getVectorTileUrls';

const BASE_KEY = 'offlineMap';
const BASE_EXTENT = { epsgCode: 'EPSG:4326', extent: [5.98865807458, 47.3024876979, 15.0169958839, 54.983104153] };

const useOfflineMapActions = params => {
    const enqueueSnackbar = useLoadingSnackbar();
    const showPrompt = useShowPrompt();

    const { baseUrl, doc, features, srid, vectorTileLayers = [] } = params;
    const { uuid, offlineMap } = doc;
    const offlineMapId = `${BASE_KEY}_${uuid}`;

    /**
     *  EVENT HANDLER
     */

    const getTileUrl = async () => {
        const urlsToCache = [];
        const cache = await window.caches.open(BASE_KEY);
        const baseFetchResult = await fetchUrl(baseUrl);

        if (baseFetchResult) {
            const { json: baseJson, response: baseResponse } = baseFetchResult;
            urlsToCache.push({ url: baseUrl, response: baseResponse });

            if (isObject(baseJson?.sources)) {
                const sources = baseJson.sources;
                const source = Object.keys(sources)[0];
                const { url: sourceUrl } = sources[source] || {};

                if (sourceUrl) {
                    const { json: dataJson, response: sourceResponse } = await fetchUrl(sourceUrl);
                    const tileUrl = dataJson?.tiles?.[0];
                    urlsToCache.push({ url: sourceUrl, response: sourceResponse });

                    for (let i = 0; i < urlsToCache.length; i++) {
                        const { url, response } = urlsToCache[i];
                        await cache.put(url, response);
                    }

                    return tileUrl;
                }
            }
        }
    };

    const handleCreate = async () => {
        enqueueSnackbar({
            loadingText: i18n.t('offlineMap.notification.loading'),
            finishedText: i18n.t('offlineMap.notification.loadingFinished'),
            func: handleFetch,
        });
    };

    const handlePromptDelete = () => {
        showPrompt({
            title: i18n.t('offlineMap.dialog.remove.title'),
            content: i18n.t('offlineMap.dialog.remove.content'),
            onOk: handleDelete,
        });
    };

    const handleDelete = async () => {
        const { cacheIds = [] } = doc.offlineMap;

        for (let i = 0; i < cacheIds.length; i++) {
            const id = cacheIds[i];
            await window.caches.delete(id);
        }

        await doc.atomicPatch({ offlineMap: null });
    };

    const handleFetch = async () => {
        const cacheIds = [];

        try {
            const tileUrl = await getTileUrl();

            if (tileUrl) {
                const urls = getVectorTileUrls({ features, minZoomLevel: 7, maxZoomLevel: 14, url: tileUrl });

                await handleFetchBaseTiles(tileUrl);
                await addToCache(offlineMapId, urls);
            }
        } catch (error) {
            console.log(error);
        }

        for (let i = 0; i < vectorTileLayers.length; i++) {
            const { id, sourceOptions } = vectorTileLayers[i];
            const mapId = `${offlineMapId}_${id}`;
            const url = sourceOptions?.url;

            if (url) {
                const urls = getVectorTileUrls({ features, minZoomLevel: 7, maxZoomLevel: 14, url });

                try {
                    await handleFetchBaseTiles(url + 1);
                    await addToCache(mapId, urls);

                    cacheIds.push(mapId);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        if (cacheIds.length > 0) {
            await doc.atomicPatch({ offlineMap: { cacheIds, modifiedOn: +new Date() } });
        } else {
            throw Error(i18n.t('offlineMap.notification.download.error'));
        }
    };

    const handleFetchBaseTiles = async url => {
        const { epsgCode, extent } = BASE_EXTENT;
        const transformedExtent = transformExtent(extent, epsgCode, `EPSG:${srid || 3857}`);

        const urls = getVectorTileUrls({
            extent: transformedExtent,
            minZoomLevel: 1,
            maxZoomLevel: 6,
            url,
        });

        await addToCache(BASE_KEY, urls);
    };

    const handleUpdate = async event => {
        await handleDelete();
        await handleCreate();
    };

    const getMenuItems = () => {
        let menuItems;

        if (features?.length > 0 && (baseUrl || vectorTileLayers.length > 0)) {
            if (!!offlineMap) {
                menuItems = [
                    {
                        id: 'refresh',
                        label: i18n.t('offlineMap.label.update'),
                        onClick: handleUpdate,
                    },
                    {
                        id: 'remove',
                        label: i18n.t('offlineMap.label.delete'),
                        onClick: handlePromptDelete,
                        color: 'secondary',
                    },
                ];
            } else {
                menuItems = [
                    {
                        id: 'create',
                        label: i18n.t('offlineMap.label.create'),
                        onClick: handleCreate,
                    },
                ];
            }
        }

        return menuItems;
    };

    return getMenuItems();
};

export default useOfflineMapActions;
