/**
 * The Dialog component returns a customized dialog window.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { ContentRoot } from '@geomagic/layout';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }) => ({
    dialog: { display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' },
    dialogTitle: {
        borderBottom: `1px solid ${palette.divider}`,
    },
    dialogContent: {},
    dialogActions: {
        borderTop: `1px solid ${palette.divider}`,
    },
    paper: {
        flex: 1,
    },
}));

const CustomDialog = props => {
    const classes = useStyles(props);
    const { actions, content, handleClose, maxWidth = 'sm', open = false, title, ...dialogProps } = props;

    return (
        <Dialog
            className={classes.dialog}
            maxWidth={maxWidth}
            onClose={handleClose}
            onClick={evt => {
                evt.stopPropagation();
            }}
            open={open}
            PaperProps={{ className: classes.paper }}
            {...dialogProps}
        >
            <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
            {content ? (
                <ContentRoot withPadding={false} withCustomScrollbar>
                    <DialogContent className={classes.dialogContent}>{content}</DialogContent>
                </ContentRoot>
            ) : null}

            <DialogActions className={classes.dialogActions}>{actions}</DialogActions>
        </Dialog>
    );
};

CustomDialog.propTypes = {
    actions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    handleClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.string,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default CustomDialog;
