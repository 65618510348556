/**
 * Get rgba by hex and alpha
 * @param {string} hex
 * @param {number} alpha
 * @returns {string}
 */

const hexToRGB = (hex, alpha) => {
    if (hex) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }
};

export default hexToRGB;
