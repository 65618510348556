import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';
import useOfflineMapActions from '@offlineMap/useOfflineMapActions';

import getFeatures from './getFeatures';
import getTasksAmount from '../Tasks/getTasksAmount';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
    root: {
        display: 'flex',
    },
    trigger: {
        [breakpoints.down('md')]: {
            height: MOBILE_TRIGGER_SIZE,
            width: MOBILE_TRIGGER_SIZE,
        },
    },
}));

const AssignmentListActions = props => {
    const { data, mapProps, onCloseSwipeableArea, onShowOnMap, onOpenTasks } = props;

    const entity = data.getPatchedEntity();
    const { vectorTileServerUrl } = mapProps;
    const features = getFeatures([data]);
    const hasFeatures = features?.length > 0;

    const offlineMapActions = useOfflineMapActions({
        baseUrl: vectorTileServerUrl,
        doc: data,
        features,
        srid: mapProps?.srid,
        vectorTileLayers: mapProps?.vectorTileLayers,
    });

    const toggleActionDrawer = useToggleActionDrawer();

    const classes = useStyles();

    /**
     *  EVENT HANDLER
     */

    const handleOpenDrawer = event => {
        event.stopPropagation();

        toggleActionDrawer({
            title: entity?.displayName,
            items: menuItems,
        });
    };

    const handleOpenTasks = event => {
        event.stopPropagation();
        onOpenTasks && onOpenTasks(data);
    };

    const handleShowOnMap = event => {
        event.stopPropagation();
        onCloseSwipeableArea();
        onShowOnMap(features, entity.id);
    };

    /**
     *  MENU ITEMS
     */

    const getMenuItems = () => {
        let items = [];

        if (onShowOnMap) {
            items.push({
                disabled: !hasFeatures,
                id: 'showOnMap',
                label: i18n.t('label.showOnMap'),
                onClick: handleShowOnMap,
            });
        }

        if (offlineMapActions) {
            items.push(...offlineMapActions);
        }

        items.push({
            id: 'tasks',
            label: i18n.t('process.label.menuItem', {
                variables: { amount: getTasksAmount(entity?.processInstances) || '0' },
            }),
            onClick: handleOpenTasks,
        });

        return items;
    };

    const menuItems = getMenuItems();

    return (
        <div className={classes.root}>
            {menuItems.length > 0 && (
                <Trigger
                    {...DEFAULT_TRIGGER_ICON_PROPS}
                    className={classes.trigger}
                    icon={<MoreVertIcon />}
                    onClick={handleOpenDrawer}
                />
            )}
        </div>
    );
};

AssignmentListActions.propTypes = {
    data: PropTypes.object.isRequired,
    entityClasses: PropTypes.array.isRequired,
    mapProps: PropTypes.object.isRequired,
    onCloseSwipeableArea: PropTypes.func.isRequired,
    onShowOnMap: PropTypes.func.isRequired,
};

export default AssignmentListActions;
