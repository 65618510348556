import { i18n } from '@geomagic/i18n';

const fetchUrl = async url => {
    try {
        const response = await fetch(url);

        if (response.status === 200) {
            const clonedResponse = response.clone();
            const json = await clonedResponse.json();
            return { json, response };
        }
    } catch (error) {
        throw new Error(i18n.t('offlineMap.notification.fetchError'));
    }
};

export default fetchUrl;
