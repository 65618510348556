import { i18n } from '@geomagic/i18n';

import AvatarComponent from './ConflictResolutionAvatar';
import TextComponent from './ConflictResolutionText';

const getGroups = conflictGroups => {
    return conflictGroups.map(({ id, items }) => ({
        id,
        label: i18n.t(`type.${id}`),
        items,
        AvatarComponent,
        TextComponent,
    }));
};

export default getGroups;
