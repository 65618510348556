import { applyPatch } from 'rfc6902';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';

const getPatchedEntity = ({ entity, jsonPatch }) => {
    const patchedEntity = cloneDeep(entity);

    if (isArray(jsonPatch)) {
        applyPatch(patchedEntity, jsonPatch);
    }

    return patchedEntity;
};

export default getPatchedEntity;
