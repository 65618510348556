import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

import FolderIcon from '@material-ui/icons/Folder';
import LinkIcon from '@material-ui/icons/Link';

import { CLASSNAME_FOLDER } from '@graphql/consts';
import getDocumentIconStyle from '../utils/getDocumentIconStyle';
import getFileExtension from '../utils/getFileExtension';

const DEFAULT_BADGE_SIZE = 16;
const DEFAULT_SIZE = 35;

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    const badgeColor = palette.type === 'light' ? palette.grey[400] : palette.grey[600];

    return {
        root: {
            backgroundColor: palette.primary.main,
            color: palette.getContrastText(palette.primary.main),
            display: 'flex',
            padding: spacing(0, 0.125),
            pointer: 'cursor',
            '&$folder': {
                background: 'none',
                color: palette.action.active,
            },
        },
        badge: {
            background: badgeColor,
            border: `2px solid ${palette.background.paper}`,
            color: palette.getContrastText(badgeColor),
            height: 'auto',
            margin: -2,
            minHeight: DEFAULT_BADGE_SIZE,
            minWidth: DEFAULT_BADGE_SIZE,
            padding: 0,
        },
        linkIcon: {
            fontSize: typography.pxToRem(16),
        },
        extension: {
            fontSize: ({ size }) => typography.pxToRem((size || DEFAULT_SIZE) / 3),
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: ({ size }) => `${size || DEFAULT_SIZE}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
        },
        folder: {},
    };
});

const FileAvatar = props => {
    const { className, entity, size = DEFAULT_SIZE } = props;
    const { isDocumentLinked, webDavUrl } = entity?.properties || {};
    const isFolder = entity?.className === CLASSNAME_FOLDER;

    const classes = useStyles(props);
    const theme = useTheme();

    return (
        <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            classes={{ badge: classes.badge }}
            color="secondary"
            overlap="circular"
            {...(isDocumentLinked && { badgeContent: <LinkIcon className={classes.linkIcon} /> })}
        >
            <Avatar
                className={classNames(classes.root, className, { [classes.folder]: isFolder })}
                style={{ ...(!isFolder && { ...getDocumentIconStyle(webDavUrl, theme) }), height: size, width: size }}
            >
                {isFolder ? (
                    <FolderIcon />
                ) : (
                    <Typography className={classes.extension} variant="caption">
                        {getFileExtension(entity?.name || entity?.displayName)}
                    </Typography>
                )}
            </Avatar>
        </Badge>
    );
};

FileAvatar.propTypes = {
    className: PropTypes.string,
    entity: PropTypes.object,
    size: PropTypes.number,
};

export default FileAvatar;
