import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { ListItem } from '@geomagic/layout';

const useStyles = makeStyles(({ palette, spacing, transitions }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: palette.background.paper,
    },
    skeleton: {
        height: 14,
        margin: spacing(0.5, 0),
    },
    listItem: {
        borderBottom: `1px solid ${palette.divider}`,
        flexShrink: 0,
    },
    avatar: {
        width: 32,
        height: 32,
        backgroundColor: 'transparent',
    },
}));

const ListSkeleton = ({ count = 5, withAvatar }) => {
    const classes = useStyles();
    const list = Array(count).fill();

    return list.map((item, idx) => {
        return (
            <ListItem
                key={idx}
                className={classes.listItem}
                classes={{ avatar: classes.avatar }}
                dense
                subtitle={<Skeleton className={classes.skeleton} />}
                title={<Skeleton className={classes.skeleton} />}
                {...(withAvatar && { media: <Skeleton variant="circle" width={32} height={32} /> })}
            />
        );
    });
};

ListSkeleton.propTypes = {
    count: PropTypes.number,
    withAvatar: PropTypes.bool,
};

export default ListSkeleton;
