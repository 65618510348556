import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
    <Fragment>
        <path d="M19.8,2H15.38V4H19.8a.2.2,0,0,1,.2.2V8.62h2V4.2A2.21,2.21,0,0,0,19.8,2Z" />
        <path d="M20,19.8a.2.2,0,0,1-.2.2H15.38v2H19.8A2.21,2.21,0,0,0,22,19.8V15.38H20Z" />
        <path d="M4,19.8V15.38H2V19.8A2.21,2.21,0,0,0,4.2,22H8.63V20H4.2A.2.2,0,0,1,4,19.8Z" />
        <path d="M4,4.2A.2.2,0,0,1,4.2,4H8.61V2H4.2A2.21,2.21,0,0,0,2,4.2V8.62H4Z" />
    </Fragment>,
    'Order'
);
