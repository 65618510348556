import uuidv4 from 'uuid/v4';
import { getReference } from '@geomagic/geonam';

/**
 * Get an template for draft
 * @param {Object} entityParams
 * @param {Object} draftParams
 * @param {Object} entityParams.entityReference - GeoNAM entity reference
 * @param {Object} entityParams.entityType - GeoNAM entity type
 * @returns {Object}
 */

const getEntityTemplate = entityClass => {
    if (entityClass) {
        const { className, entityTypes } = entityClass;

        return {
            id: uuidv4(),
            className,
            entityType: entityTypes.length > 0 ? getReference(entityTypes[0]) : null,
            attributeValues: [],
            documents: [],
            featureCollections: [],
            journal: [],
            checklistItems: [],
        };
    } else {
        console.error('ENTITYCLASS IS REQUIRED');
    }
};

export default getEntityTemplate;
