import gql from 'graphql-tag';

import FragmentProcessInstance from './FragmentProcessInstance';

export const FragmentProcessInstances = gql`
    ${FragmentProcessInstance}

    fragment ProcessInstances on Entity {
        processInstances {
            ...ProcessInstance
        }
    }
`;

export default FragmentProcessInstances;
