import gql from 'graphql-tag';

import { FragmentAttributeValues, FragmentEntityReference, FragmentProcessingStatus } from '@geomagic/geonam-graphql';

import FragmentDocument from './FragmentDocument';
import FragmentFeatureCollections from './FragmentFeatureCollections';

const FragmentEntity = gql`
    ${FragmentAttributeValues}
    ${FragmentDocument}
    ${FragmentEntityReference}
    ${FragmentFeatureCollections}
    ${FragmentProcessingStatus}

    fragment Entity on Entity {
        ...AttributeValues
        ...EntityReference
        ...FeatureCollections
        ...ProcessingStatus
        auditInfo {
            createdOn
            createdBy
            modifiedOn
            modifiedBy
        }
        criticalStatusMessage
        displayName
        documents {
            ...Document
        }
        entityType {
            id
            className
        }
        permissionTypes
        shortDescription
    }
`;

export default FragmentEntity;
