const resetDoc = async (doc, update) => {
    const updatedDoc = await doc.atomicUpdate(oldData => {
        oldData.entity = update;
        oldData.draft = null;
        oldData.jsonPatch = null;
        return oldData;
    });

    return updatedDoc;
};

export default resetDoc;
