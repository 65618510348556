import { useCallback, useEffect, useRef, useState } from 'react';

import addVectorLayer from './addVectorLayer';
import animateZoom from './animateZoom';
import clearFeatureStyle from './clearFeatureStyle';
import enableSelectInteraction from './enableSelectInteraction';
import flashFeature from './flashFeature';
import getFeatureStyle from './getFeatureStyle';
import getExtentFromFeatures from './getExtentFromFeatures';
import checkEquality from './checkEquality';
import clearFeaturesFromLayer from './clearFeaturesFromLayer';
import getFeatures from './getFeatures';
import addFeaturesToLayer from './addFeaturesToLayer';

/**
 * A hook to add features from a featureCollection to the map
 * @param {Object} params
 * @param {Object} params.mapRef - Ref of the map
 * @param {Object} params.featureCollection - GeoNAM feature collection
 * @param {Object} params.style - Geometry style
 * @param {Object} params.handler - Interaction event handler
 * @returns {Object}
 */

const useFeatures = (
    {
        mapRef,
        features,
        isSelectable = true,
        maxExtentZoomLevel,
        selectColor,
        style,
        withAnimation = true,
        withZoom = true,
        zoomType = 'centerAndResize',
    },
    handler = {}
) => {
    const featureLayerRef = useRef();
    const selectInteractionRef = useRef();
    const [selectedFeature, setSelectedFeature] = useState();
    const { onSelect } = handler;

    const clearSelection = useCallback(() => {
        selectInteractionRef.current && selectInteractionRef.current.getFeatures().clear();
        clearFeatureStyle([featureLayerRef]);
        setSelectedFeature(null);
    }, []);

    const selectFeatures = useCallback(
        selectedFeatures => {
            const map = mapRef?.current?.map;
            const featureLayer = featureLayerRef.current;

            clearSelection();

            if (map && selectedFeatures && selectedFeatures.length > 0) {
                const _features = selectedFeatures.map(feature =>
                    feature?.id ? featureLayer.getSource().getFeatureById(feature.id) : feature
                );

                if (_features.length > 1) {
                    _features.forEach(feature => {
                        const style = getFeatureStyle({ feature, backgroundColor: selectColor });
                        feature.setStyle(style);
                    });

                    if (withZoom) {
                        animateZoom({
                            map,
                            extent: getExtentFromFeatures(_features),
                            maxExtentZoomLevel,
                            type: zoomType,
                        });
                    }
                } else {
                    const feature = _features[0];
                    const style = getFeatureStyle({ feature, backgroundColor: selectColor });
                    feature.setStyle(style);

                    if (withZoom) {
                        animateZoom({
                            map,
                            extent: feature.getGeometry().getExtent(),
                            maxExtentZoomLevel,
                            type: zoomType,
                        });
                    }

                    if (withAnimation) {
                        flashFeature({ map, layer: featureLayer, feature, selectColor });
                    }
                }
            }
        },
        [clearSelection, mapRef, maxExtentZoomLevel, selectColor, withAnimation, withZoom, zoomType]
    );

    const animateFeatures = useCallback(() => {
        const map = mapRef?.current?.map;
        const featureLayer = featureLayerRef.current;

        if (map && featureLayer) {
            const featureSource = featureLayer.getSource();
            const _features = featureSource.getFeatures();

            if (_features?.length > 0) {
                animateZoom({
                    map,
                    extent: getExtentFromFeatures(_features),
                    maxExtentZoomLevel,
                    type: zoomType,
                });

                if (_features.length === 1) {
                    flashFeature({ map, layer: featureLayer, feature: _features[0], selectColor });
                }
            }
        }
    }, [mapRef, maxExtentZoomLevel, selectColor, zoomType]);

    useEffect(() => {
        const map = mapRef?.current?.map;
        let vectorLayer;

        if (map) {
            vectorLayer = addVectorLayer(map, style);
            featureLayerRef.current = vectorLayer;
        }

        return () => {
            map && map.removeLayer(vectorLayer);
        };
    }, [mapRef, style]);

    useEffect(() => {
        const map = mapRef?.current?.map;
        const featureLayer = featureLayerRef.current;

        if (map) {
            const newFeatures = getFeatures(map, features);
            const isSameIdAndCoordinates = checkEquality(featureLayer.getSource().getFeatures(), newFeatures);

            if (!isSameIdAndCoordinates) {
                clearFeaturesFromLayer(featureLayer);
                addFeaturesToLayer(featureLayer, newFeatures);
            }
        }
    }, [features, mapRef]);

    const featureLayer = featureLayerRef.current;

    useEffect(() => {
        let interaction;
        const map = mapRef?.current?.map;

        if (map && isSelectable) {
            interaction = enableSelectInteraction({
                map,
                layers: [featureLayerRef],
                onSelect: (evt, feature) => {
                    selectFeatures(feature ? [feature] : null);
                    setSelectedFeature(feature);
                    onSelect && onSelect(evt, feature);
                },
            });
        }

        return () => {
            map && map.removeInteraction(interaction);
        };
    }, [featureLayer, isSelectable, mapRef, onSelect, selectFeatures]);

    return {
        animateFeatures,
        clearSelection,
        featureLayerRef,
        selectedFeature,
        selectFeatures,
    };
};

export default useFeatures;
