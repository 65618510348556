/**
 * Get an extended entity with default featureCollection
 * @param {object} entity - GeoNAM entity
 * @param {Object} mapProps - GeoNAM mapProps (world, view, srid)
 * @returns {object}
 */

const getExtendedEntity = (entity, mapProps) => {
    let extendedEntity = entity;
    const hasFeatureCollectionsKey = entity.hasOwnProperty('featureCollections');

    if (hasFeatureCollectionsKey) {
        extendedEntity = {
            ...entity,
            ...(entity.featureCollections.length === 0 && {
                featureCollections: [
                    {
                        type: 'FeatureCollection',
                        features: [],
                        srid: mapProps?.srid,
                        worldId: mapProps?.world?.id,
                    },
                ],
            }),
        };
    }

    return extendedEntity;
};

export default getExtendedEntity;
