import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { ModalDialog } from '@geomagic/core';
import { submitForm } from '@geomagic/forms';
import { i18n } from '@geomagic/i18n';
import { getReference } from '@geomagic/geonam';
import { ContentRoot } from '@geomagic/layout';

import EntityForm from '@geomagic/nam-react-core/components/EntityForm';
import { DEFAULT_TEXT_FIELD_PROPS, MOBILE_TRIGGER_SIZE, PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles(({ palette, shape, spacing, transitions }) => ({
    toolbar: {
        minHeight: 56,
        paddingLeft: 0,
        paddingRight: spacing(),
    },
    dialogTitle: {
        padding: spacing(2),
        '&$fullScreen': {
            padding: 0,
        },
    },
    dialogContent: {
        height: '100%',
        padding: 0,
        '&$fullScreen': {
            marginTop: 56,
        },
    },
    title: {
        fontWeight: 'bold',
    },
    menuButton: {
        height: MOBILE_TRIGGER_SIZE,
        margin: 0,
        width: MOBILE_TRIGGER_SIZE,
    },
    fullScreen: {},
}));

const DocumentFormDialog = props => {
    const {
        className,
        entityClasses,
        entityTypes,
        fileProps,
        isFullScreen,
        onClose,
        onSubmit,
        open,
        setFileProps,
        withAttributesForm,
    } = props;
    const formId = 'documentForm';

    const { name: fileName = '' } = fileProps || {};
    const fileExtension = '.' + fileName.split('.').pop();
    const fileNameWithoutExtension = fileName.slice(0, -fileExtension.length);

    const formContextRef = useRef();
    const [entityTypeId, setEntityTypeId] = useState(entityTypes[0]?.id);
    const classes = useStyles();

    const dialogContentClasses = { root: classNames(classes.dialogContent, { [classes.fullScreen]: isFullScreen }) };
    const dialogTitleClasses = {
        root: classNames(classes.dialogTitle, { [classes.fullScreen]: isFullScreen }),
        toolbar: classes.toolbar,
        menuButton: classes.menuButton,
    };

    const dialogProps = {
        DialogTitleProps: {
            classes: dialogTitleClasses,
            ...(isFullScreen && { iconLeft: <ChevronLeftIcon /> }),
        },
        DialogContentProps: {
            classes: dialogContentClasses,
        },
        ...(!isFullScreen && { okActionProps: PRIMARY_TRIGGER_PROPS }),
    };

    /**
     *  EVENT HANDLER
     */

    const handleChangeSelect = event => {
        const id = Number(event.target.value);

        setEntityTypeId(id);
        formContextRef.current.replaceValues({});
    };

    const handleSubmit = attributeValues => {
        const entityType = entityTypes.find(entityType => entityType.id === entityTypeId);
        const entityTypeReference = getReference(entityType);

        onSubmit(attributeValues, entityTypeReference);
    };

    const handleSubmitForm = () => {
        submitForm(formId);
    };

    /**
     *  COMPONENTS
     */

    const TitleComponent = (
        <Typography className={classes.title} noWrap variant="body1">
            {i18n.t('dialog.addDocument.title')}
        </Typography>
    );

    return (
        <ModalDialog
            isMobile={isFullScreen}
            maxWidth="sm"
            okActionText={i18n.t('button.add')}
            onClose={onClose}
            onOk={withAttributesForm ? handleSubmitForm : () => handleSubmit({})}
            open={open}
            title={TitleComponent}
            withDivider
            {...dialogProps}
        >
            <ContentRoot scrollable withCustomScrollbar={!isFullScreen}>
                {withAttributesForm && (
                    <Select
                        {...DEFAULT_TEXT_FIELD_PROPS}
                        autoWidth
                        fullWidth
                        margin="dense"
                        native
                        onChange={handleChangeSelect}
                        value={entityTypeId}
                    >
                        {entityTypes.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </Select>
                )}
                <TextField
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{fileExtension}</InputAdornment>,
                    }}
                    label={i18n.t('label.fileName')}
                    onChange={event => {
                        setFileProps({ ...fileProps, name: event.target.value + fileExtension });
                    }}
                    required
                    value={fileNameWithoutExtension}
                    {...DEFAULT_TEXT_FIELD_PROPS}
                />
                {withAttributesForm && (
                    <EntityForm
                        entityClassName={className}
                        entityClasses={entityClasses}
                        entityTypeId={entityTypeId}
                        formId={formId}
                        isRequiredFieldsOnly
                        isSubmitOnEnter={false}
                        onSubmit={handleSubmit}
                    >
                        {(fields, formContext) => {
                            formContextRef.current = formContext;
                            return fields;
                        }}
                    </EntityForm>
                )}
            </ContentRoot>
        </ModalDialog>
    );
};

DocumentFormDialog.propTypes = {
    className: PropTypes.string.isRequired,
    entityClasses: PropTypes.array.isRequired,
    entityTypes: PropTypes.array.isRequired,
    isFullScreen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool,
    withAttributesForm: PropTypes.bool,
};

export default DocumentFormDialog;
