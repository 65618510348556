import { getBackendInfo } from '@geomagic/core';
import { DEFAULT_ENVIRONMENT } from '@graphql/consts';

/**
 * Get GeoNAM document download url
 * @returns {string}
 */

const getDownloadUrl = () => {
    const { backend } = getBackendInfo(DEFAULT_ENVIRONMENT);
    const url = `${backend}/seam/resource/rest/document/`;

    return url;
};

export default getDownloadUrl;
