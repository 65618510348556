import every from 'lodash/every';

/**
 * Check if an extent includes finite numbers
 * @param {Array.<number>} extent - Coordinates to center
 * @returns {boolean}
 */

const isFiniteExtent = (extent) => {
  return extent && every(extent, (ext) => isFinite(ext));
};

export default isFiniteExtent;
