import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Group from '@geomagic/ol/layer/Group';
import { ListItemSecondaryAction } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import LayerListItem from './LayerListItem';

const useStyles = makeStyles(({ palette, spacing }) => {
    const spacingWidth = 3;

    return {
        root: {
            width: '100%',
        },
        listItem: ({ level }) => ({
            paddingLeft: '0px',
            marginLeft: level * spacing(spacingWidth),
            width: `calc(100% - ${level * spacing(spacingWidth)}px)`,
            border: '0px',
            fontStyle: 'oblique',
        }),
        checkbox: {
            padding: '0px',
        },
        listItemIcon: {
            minWidth: '0px',
        },
    };
});

const GroupListItem = props => {
    const { childs = [], getState, isState, layer, level, map, onHandleLayerClick, openGroups } = props;

    const groupListItemTextElementRef = useRef();
    const [isGroupOpen, setGroupOpen] = useState(!!openGroups);
    const [isOverflowed, setIsOverflow] = useState(false);
    const classes = useStyles(props);

    /**
     *  EVENT HANDLER
     */

    const handleGroupClick = useCallback(() => {
        setGroupOpen(previous => !previous);
    }, [setGroupOpen]);

    /**
     *  EFFECTS
     */

    useEffect(() => {
        setIsOverflow(
            groupListItemTextElementRef.current.scrollWidth > groupListItemTextElementRef.current.clientWidth
        );
    }, []);

    return (
        <>
            <ListItem disableGutters button onClick={handleGroupClick} divider className={classes.listItem}>
                <ListItemIcon key={!isGroupOpen ? 'Visibility' : 'close'} className={classes.listItemIcon}>
                    {isGroupOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemIcon>

                <ListItemText
                    primary={
                        <Tooltip
                            title={layer.get('layerId')}
                            disableHoverListener={!isOverflowed}
                            placement="top-end"
                            interactive
                        >
                            <Typography variant="body1" noWrap ref={groupListItemTextElementRef}>
                                <b>{layer.get('layerId')}</b>
                            </Typography>
                        </Tooltip>
                    }
                    secondary={
                        <Typography variant="body2" color="textSecondary" noWrap>
                            {layer.get('layerType')}
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <Checkbox
                        className={classes.checkbox}
                        color={isState === -1 ? 'default' : 'primary'}
                        checked={isState === 1 || isState === -1}
                        onClick={evt => {
                            evt.stopPropagation();
                            onHandleLayerClick(layer);
                        }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isGroupOpen} timeout="auto" unmountOnExit>
                {childs.map(({ id, olLayer: childLayer, childs: layerChilds }) => {
                    if (childLayer instanceof Group) {
                        return (
                            <GroupListItem
                                key={id}
                                childs={layerChilds}
                                getState={getState}
                                isState={getState(childLayer)}
                                layer={childLayer}
                                level={level + 1}
                                map={map}
                                onHandleLayerClick={onHandleLayerClick}
                                openGroups={openGroups}
                            />
                        );
                    } else {
                        return (
                            <LayerListItem
                                key={id}
                                isState={getState(childLayer)}
                                layer={childLayer}
                                level={level + 1}
                                map={map}
                                onHandleLayerClick={onHandleLayerClick}
                            />
                        );
                    }
                })}
            </Collapse>
        </>
    );
};

GroupListItem.propTypes = {
    childs: PropTypes.array,
    getState: PropTypes.func.isRequired,
    isState: PropTypes.number.isRequired,
    layer: PropTypes.object.isRequired,
    level: PropTypes.number.isRequired,
    map: PropTypes.object.isRequired,
    onHandleLayerClick: PropTypes.func.isRequired,
    openGroups: PropTypes.bool,
};

export default GroupListItem;
