import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import DefaultAppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DownloadIcon from '@material-ui/icons/GetApp';
import OfflineIcon from '@material-ui/icons/WifiOff';
import UploadIcon from '@material-ui/icons/Publish';

import { Trigger } from '@geomagic/core';

import { MOBILE_TRIGGER_SIZE } from '@consts';

import AppBarMenu from './AppBarMenu';
import useAppBar from './useAppBar';

const useStyles = makeStyles(({ breakpoints, mixins, spacing, zIndex }) => ({
    root: {},
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 56,
        paddingLeft: spacing(),
        paddingRight: spacing(),
        '&$isDetail': {
            paddingLeft: 0,
        },
    },
    content: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        paddingRight: spacing(),
    },
    action: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    trigger: {
        height: MOBILE_TRIGGER_SIZE,
        width: MOBILE_TRIGGER_SIZE,
    },
    offlineIcon: {
        marginRight: spacing(),
    },
    isDetail: {},
}));

const AppBar = props => {
    const {
        basePath,
        history,
        isLoading,
        isMobile,
        isOnline,
        lastFetch,
        mapProps,
        onFetchData,
        onLogout,
        onSyncData,
        preferences,
        routeProps,
        syncCount,
        updateGlobalState,
        user,
    } = props;

    const pathname = routeProps?.location?.pathname;
    const isDetail = pathname !== basePath;

    const { actions, title } = useAppBar();
    const classes = useStyles();

    /**
     *  GENERAL PROPS
     */

    const triggerProps = {
        className: classes.trigger,
        color: 'inherit',
    };

    /**
     *  EVENT HANDLER
     */

    const handleRedirect = () => {
        history.push(basePath);
    };

    const handleUpdatePreferences = newPreferences => {
        updateGlobalState({ preferences: newPreferences });
    };

    /**
     *  COMPONENTS
     */

    const getSyncIconComponent = IconComponent => (
        <Badge badgeContent={syncCount} color="secondary">
            <IconComponent />
        </Badge>
    );

    const getSyncTriggerComponent = () => {
        let Component;

        if (isOnline) {
            const IconComponent = getSyncIconComponent(UploadIcon);
            Component = (
                <Fragment>
                    <Trigger {...triggerProps} disabled={isLoading} icon={<DownloadIcon />} onClick={onFetchData} />
                    <Trigger
                        {...triggerProps}
                        disabled={isLoading || syncCount === 0}
                        icon={IconComponent}
                        onClick={onSyncData}
                    />
                </Fragment>
            );
        } else {
            const IconComponent = getSyncIconComponent(OfflineIcon);
            Component = <div className={classes.offlineIcon}>{IconComponent}</div>;
        }

        return Component;
    };

    return (
        <DefaultAppBar className={classes.root} position="absolute">
            <Toolbar className={classNames(classes.toolbar, { [classes.isDetail]: isDetail })}>
                {isDetail && (
                    <div className={classes.action}>
                        <Trigger {...triggerProps} onClick={handleRedirect} icon={<ChevronLeftIcon />} />
                    </div>
                )}
                <div className={classes.content}>
                    {title && (
                        <Typography className={classes.title} onClick={handleRedirect} noWrap variant="body1">
                            {title}
                        </Typography>
                    )}
                </div>

                <div className={classes.action}>
                    {actions}
                    {!isDetail && getSyncTriggerComponent()}
                    <AppBarMenu
                        isMobile={isMobile}
                        isOnline={isOnline}
                        lastFetch={lastFetch}
                        mapProps={mapProps}
                        onLogout={onLogout}
                        onUpdatePreferences={handleUpdatePreferences}
                        preferences={preferences}
                        user={user}
                    />
                </div>
            </Toolbar>
        </DefaultAppBar>
    );
};

AppBar.propTypes = {
    basePath: PropTypes.string.isRequired,
    entityClasses: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    isMobile: PropTypes.bool,
    isOnline: PropTypes.bool,
    lastFetch: PropTypes.number,
    mapProps: PropTypes.object.isRequired,
    onFetchData: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onSyncData: PropTypes.func.isRequired,
    preferences: PropTypes.object.isRequired,
    routeProps: PropTypes.object.isRequired,
    syncCount: PropTypes.number,
    updateGlobalState: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default AppBar;
