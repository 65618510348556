import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import SettingsIcon from '@material-ui/icons/Settings';

import { TriggerMenu } from '@geomagic/core';
import { MutationLogout } from '@geomagic/geonam-graphql';
import { i18n } from '@geomagic/i18n';

import { DATETIME_DISPLAY_FORMAT, MOBILE_TRIGGER_SIZE } from '@consts';
import showModal from '@utils/showModal';

import getMenuItems from './getMenuItems';

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        [breakpoints.down('md')]: {
            height: MOBILE_TRIGGER_SIZE,
            width: MOBILE_TRIGGER_SIZE,
        },
    },
}));

const AppBarMenu = props => {
    const { isMobile, isOnline, lastFetch, onLogout, user } = props;
    const menuItems = getMenuItems();
    const classes = useStyles();

    /**
     *  GENERAL PROPS
     */

    const triggerProps = {
        className: classes.trigger,
        color: 'inherit',
    };

    /**
     *  MUTATIONS
     */

    const [handleLogout] = useMutation(MutationLogout);

    /**
     *  EVENT HANDLER
     */

    const handleClickListItem = ({ label, Component }) => () => {
        showModal({
            title: label,
            content: <Component {...props} />,
            isFullScreen: isMobile,
            onCancelText: i18n.t('button.close'),
        });
    };

    const handleClickLogout = event => {
        handleLogout().then(() => {
            onLogout();
        });
    };

    return (
        <TriggerMenu
            {...triggerProps}
            className={classes.root}
            icon={<SettingsIcon />}
            variant={!isMobile ? 'text' : 'icon'}
            triggerText={!isMobile ? user?.loginName : null}
        >
            <ListItem divider>
                <ListItemText
                    primary={i18n.t('label.lastFetch')}
                    secondary={
                        lastFetch ? dayjs(lastFetch).format(DATETIME_DISPLAY_FORMAT) : i18n.t('label.noLastFetch')
                    }
                />
            </ListItem>
            {menuItems.map(item => {
                return (
                    <ListItem key={item.id} button onClick={handleClickListItem(item)}>
                        <ListItemText primary={item.label} />
                    </ListItem>
                );
            })}
            {isOnline && user && (
                <ListItem button onClick={handleClickLogout}>
                    <ListItemText primary={i18n.t('button.logout', { variables: { username: user?.loginName } })} />
                </ListItem>
            )}
        </TriggerMenu>
    );
};

AppBarMenu.propTypes = {
    isMobile: PropTypes.bool,
    isOnline: PropTypes.bool,
    lastFetch: PropTypes.number,
    onLogout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default AppBarMenu;
