import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(({ palette, shape, spacing, typography }) => {
    const defaultBackground = palette.grey[palette.type === 'light' ? 300 : 500];

    return {
        root: {
            alignItems: 'center',
            background: palette.grey[400],
            borderRadius: 0,
            color: palette.getContrastText(palette.grey[300]),
            cursor: 'pointer',
            display: 'flex',
            minHeight: 32,
            minWidth: 32,
            justifyContent: 'center',
            padding: 0,
            '&:hover': {
                background: palette.primary.main,
                color: palette.getContrastText(palette.primary.main),
                '@media (hover: none)': {
                    background: defaultBackground,
                    color: palette.getContrastText(defaultBackground),
                },
            },
        },
        triggerBack: {
            left: 0,
        },
        triggerForward: {
            right: 0,
        },
        icon: {
            fontSize: typography.pxToRem(32),
        },
        disabled: {
            background: `${defaultBackground} !important`,
            opacity: 0.4,
        },
    };
});

const PageTrigger = props => {
    const classes = useStyles(props);
    const { disabled, onClick, variant } = props;

    const iconProps = { className: classes.icon, color: 'inherit' };

    return (
        <Button
            className={classNames(classes.root, classes.triggerForward, { [classes.disabled]: disabled })}
            disabled={disabled}
            onClick={onClick}
        >
            {variant === 'back' && <ChevronLeftIcon {...iconProps} />}
            {variant === 'forward' && <ChevronRightIcon {...iconProps} />}
        </Button>
    );
};

PageTrigger.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['back', 'forward']).isRequired,
};

export default PageTrigger;
