import dayjs from 'dayjs';
import { getAttributeValueByCode } from '@geomagic/geonam';

import ActionsComponent from './AssignmentListActions';
import AvatarComponent from './AssignmentListAvatar';
import TextComponent from './AssignmentListText';

import { PROPERTY_NAME_DUEDATE } from './consts';

const getGroups = ({ items, entityClasses, onClick }) => {
    const groups = [];

    items.forEach(item => {
        const { value: dueDate } = getAttributeValueByCode(entityClasses, item.entity, PROPERTY_NAME_DUEDATE) || {};
        const year = dayjs(dueDate).year();
        const index = groups.findIndex(({ id }) => id === year);

        if (index > -1) {
            groups[index].items.push(item);
        } else {
            groups.push({
                id: year,
                label: year,
                items: [item],
                onClick,
                ActionsComponent,
                AvatarComponent,
                TextComponent,
            });
        }
    });

    return groups.map(group => group).sort((a, b) => (a && a.id) - (b && b.id));
};

export default getGroups;
