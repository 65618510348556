/**
 * Get a parsed number from a string number
 * @param {string} stringNumber
 * @returns {number}
 */

const parseLocaleNumber = stringNumber => {
    const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
    const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');

    return Number(
        stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
    );
};

export default parseLocaleNumber;
