/**
 * A dialog to confirm an action with a log entry.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Trigger } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { AutoForm } from '@geomagic/forms';

import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';

import Dialog from '@geomagic/nam-react-core/components/Dialog';
import useIsMountedRef from '@geomagic/nam-react-core/utils/useIsMountedRef';

const DESCRIPTION_FIELD_ROWS = 3;

/**
 * Returns the Auto Form props.
 *
 * @returns {object}
 */
const getAutoFormProps = () => ({
    schema: {
        type: 'object',
        properties: {
            message: {
                type: 'string',
                title: i18n.t('label.message'),
            },
        },
    },
    ui: {
        message: {
            rows: DESCRIPTION_FIELD_ROWS,
            multiline: true,
            ...DEFAULT_TEXT_FIELD_PROPS,
        },
    },
});

const ConfirmationTaskDialog = props => {
    const { commandName, isCloseAction, isLoading, isMobile, onConfirm, onClose, open, task } = props;

    const { id: taskId, name: taskName } = task;

    const isMountedRef = useIsMountedRef();
    const [autoFormValues, setAutoFormValues] = useState({});
    const [autoFormProps] = useState(() => getAutoFormProps());

    const { message } = autoFormValues;

    /**
     *  EVENT HANDLER
     */

    const handleClick = () => {
        onConfirm(message).finally(() => {
            if (isMountedRef.current) {
                onClose();
            }
        });
    };

    /**
     *  COMPONENTS
     */

    const ContentComponent = (
        <>
            <DialogContentText>
                {isCloseAction
                    ? i18n.t('process.dialog.processCompletion.content', {
                          variables: {
                              taskName,
                          },
                      })
                    : i18n.t('process.dialog.processAction.content')}
            </DialogContentText>
            <AutoForm id={'confirmationTaskDialog' + taskId} onChange={setAutoFormValues} {...autoFormProps} />
        </>
    );

    const ActionsComponent = (
        <>
            <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={onClose}>
                {i18n.t('button.cancel')}
            </Trigger>

            <Trigger {...PRIMARY_TRIGGER_PROPS} onClick={handleClick} isLoading={isLoading}>
                {i18n.t('button.ok')}
            </Trigger>
        </>
    );

    return (
        <Dialog
            actions={ActionsComponent}
            content={ContentComponent}
            fullScreen={isMobile}
            handleClose={onClose}
            open={open}
            title={commandName}
        />
    );
};

ConfirmationTaskDialog.propTypes = {
    commandName: PropTypes.string.isRequired,
    isCloseAction: PropTypes.bool,
    isMobile: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    task: PropTypes.object.isRequired,
};

export default ConfirmationTaskDialog;
