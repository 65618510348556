/**
 * Clear all styles from features
 * @param {Array} layers - Refs of all layers
 * @returns {void}
 */

const clearFeatureStyle = layers => {
    layers.forEach(layerRef => {
        const layer = layerRef.current;
        const source = layer.getSource();
        const features = source.getFeatures();

        features.forEach(feature => {
            feature.setStyle(null);
        });
    });
};

export default clearFeatureStyle;
