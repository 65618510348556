import sortBy from 'lodash/sortBy';
import { getEntityClassesInfo, findByReference } from '@geomagic/geonam';

/**
 * Get geometry types by style id
 * @param {Object} entityType - GeoNAM entity type
 * @param {Array.<Object>} entityClasses - GeoNAM entity classes
 * @returns {Object}
 */

const getGeometryTypesByStyleId = (entityType, entityClasses) => {
    const stylesByShapeType = [];
    const typesByStyleId = [];
    const { entityTypes } = getEntityClassesInfo(entityClasses);
    const { geometryTypes } = findByReference(entityTypes, entityType);

    geometryTypes.forEach(geometryType => {
        geometryType.geometryStyles.forEach(geometryStyle => {
            const { id: styleId } = geometryStyle;
            stylesByShapeType.push({ ...geometryStyle, _geometryType: geometryType });
            typesByStyleId.push({ styleId, ...geometryType });
        });
    });

    return { stylesByShapeType: sortBy(stylesByShapeType, ({ type }) => type), typesByStyleId };
};

export default getGeometryTypesByStyleId;
