import { defaultDataIdFromObject } from '@apollo/client';
import { UNCACHED_TYPES } from './consts';

const getDataIdFromObject = result => {
    let dataId;

    if (UNCACHED_TYPES.includes(result.__typename)) {
        return null;
    }

    if (result.className) {
        dataId = `${defaultDataIdFromObject(result) || result.__typename}:${result.className}`;
    } else {
        dataId = defaultDataIdFromObject(result);
    }

    return dataId;
};

export default getDataIdFromObject;
