import { getCenter } from '@geomagic/ol/extent';
import { ZOOM_TYPE_CENTER, ZOOM_TYPE_CENTER_AND_RESIZE } from '@geomagic/nam-react-core/consts';
import centerView from './centerView';
import fitView from './fitView';
import isFiniteExtent from './isFiniteExtent';

/**
 * Center view by coordinates
 * @param {Object} params
 * @param {Object} params.map - OpenLayers map
 * @param {Array.<number>} params.extent - Extent to zoom
 * @param {string} params.type - Map behaviour (none, center, centerAndResize)
 * @param {number} params.maxExtentZoomLevel - MaxExtentZoomLevel
 * @returns {void}
 */

const animateZoom = ({ map, extent, type, maxExtentZoomLevel = 15 }) => {
    const view = map.getView();
    const isFinite = isFiniteExtent(extent);

    switch (type) {
        case ZOOM_TYPE_CENTER:
            if (isFinite) {
                const coordinates = getCenter(extent);
                centerView({ view, coordinates });
            }
            break;
        case ZOOM_TYPE_CENTER_AND_RESIZE:
            if (isFinite) {
                const currentZoom = map.getView().getZoom();
                const maxZoom = currentZoom > maxExtentZoomLevel ? currentZoom : maxExtentZoomLevel;
                fitView({ view, extent, options: { maxZoom } });
            }
            break;
        default:
        // type none
    }
};

export default animateZoom;
