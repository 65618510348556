/**
 * A component to display an action.
 *
 * Currently only one command is allowed per action.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Trigger } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import MutationExecuteProcessCommand from '@graphql/mutations/MutationExecuteProcessCommand';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import useShowPrompt from '@utils/useShowPrompt';

import ConfirmationTaskDialog from './ConfirmationTaskDialog';

const getFormHelperErrorText = ({ closeAction, closeableErrorText, isCloseable, isLoading, isOnline, isSelf }) => {
    if (!isOnline) {
        return i18n.t('process.description.notOnline');
    } else if (!isSelf || isLoading) {
        return '';
    } else if (closeAction && !isCloseable) {
        return closeableErrorText;
    } else {
        return '';
    }
};

const Action = props => {
    const {
        action,
        checkFailedMessage,
        className,
        closeableErrorText,
        data,
        isCloseable,
        isLoading,
        isMobile,
        isOnline,
        isSelf,
        onCheck,
        setLoading,
        syncEntity,
        task,
        updateEntity,
    } = props;

    const enqueueLoadingSnackbar = useLoadingSnackbar();

    const { enqueueSnackbar } = useSnackbar();
    const showPrompt = useShowPrompt();
    const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const { id: taskId, name: taskName } = task;

    const { closeAction, commands, id: actionId } = action;
    const { confirmation, name: commandName, type } = commands[0];
    const label = commandName;
    const isTaskDisabled = !isOnline || isLoading || !isSelf || (closeAction && !isCloseable);
    const formHelperText = getFormHelperErrorText({
        closeAction,
        closeableErrorText,
        isCloseable,
        isLoading,
        isOnline,
        isSelf,
    });

    /**
     * GRAPHQL MUTATIONS
     */

    const [executeProcessCommand] = useMutation(MutationExecuteProcessCommand);

    /**
     *  EVENT HANDLER
     */

    const handleExecuteProcessCommand = async (action, message) => {
        setLoading(true);
        const { closeAction: isCloseAction, commands } = action;
        const command = commands[0].command;

        const execute = () =>
            syncEntity()
                .then(() => executeProcessCommand({ variables: { taskId, command, message } }))
                .then(updateEntity);

        await enqueueLoadingSnackbar({
            loadingText: isCloseAction
                ? i18n.t('process.notification.completeTask', { variables: { taskName } })
                : i18n.t('process.notification.executeAction'),
            finishedText: isCloseAction
                ? i18n.t('process.notification.completedTask', {
                      variables: { taskName },
                  })
                : i18n.t('process.notification.executedAction'),
            finishedVariant: 'success',
            func: execute,
        });
        setLoading(false);
    };

    const handleClickProcessCommand = async action => {
        const isChecked = await onCheck(data);
        const { closeAction: isCloseAction, name: commandName } = action;

        if (!isChecked) {
            enqueueSnackbar(checkFailedMessage, {
                key: 'checkFailed',
                preventDuplicate: true,
                variant: 'info',
            });
        } else {
            showPrompt({
                title: commandName,
                content: isCloseAction
                    ? i18n.t('process.dialog.processCompletion.content', {
                          variables: {
                              taskName,
                          },
                      })
                    : i18n.t('process.dialog.processAction.content'),
                onOk: () => handleExecuteProcessCommand(action),
            });
        }
    };

    if (type === 'server') {
        if (confirmation) {
            return (
                <>
                    <Trigger
                        key={actionId}
                        className={className}
                        fullWidth
                        isDisabled={isTaskDisabled}
                        onClick={async () => {
                            const isChecked = await onCheck(data);

                            if (!isChecked) {
                                enqueueSnackbar(checkFailedMessage, {
                                    key: 'checkFailed',
                                    preventDuplicate: true,
                                    variant: 'info',
                                });
                            }
                            setConfirmationDialogOpen(true);
                        }}
                        {...PRIMARY_TRIGGER_PROPS}
                    >
                        {label}
                    </Trigger>
                    {isTaskDisabled && formHelperText ? <FormHelperText>{formHelperText}</FormHelperText> : null}
                    <ConfirmationTaskDialog
                        commandName={commandName}
                        isCloseAction={closeAction}
                        isLoading={isLoading}
                        isMobile={isMobile}
                        open={isConfirmationDialogOpen}
                        onConfirm={message => handleExecuteProcessCommand(action, message)}
                        onClose={() => setConfirmationDialogOpen(false)}
                        task={task}
                    />
                </>
            );
        } else {
            return (
                <>
                    <Trigger
                        key={actionId}
                        className={className}
                        disabled={isTaskDisabled}
                        fullWidth
                        onClick={() => handleClickProcessCommand(action)}
                        {...PRIMARY_TRIGGER_PROPS}
                    >
                        {label}
                    </Trigger>
                    {isTaskDisabled && formHelperText ? <FormHelperText>{formHelperText}</FormHelperText> : null}
                </>
            );
        }
    } else {
        return null;
    }
};

Action.propTypes = {
    action: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        description: PropTypes.string,
        disabled: PropTypes.bool,
        commands: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                command: PropTypes.string.isRequired,
            })
        ),
    }),
    checkFailedMessage: PropTypes.string,
    className: PropTypes.string,
    closeableErrorText: PropTypes.string,
    data: PropTypes.object.isRequired,
    isCloseable: PropTypes.bool,
    isOnline: PropTypes.bool,
    isMobile: PropTypes.bool,
    isSelf: PropTypes.bool,
    onCheck: PropTypes.func,
    setLoading: PropTypes.func,
    syncEntity: PropTypes.func,
    task: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        description: PropTypes.string,
        assignee: PropTypes.string,
        priority: PropTypes.string,
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                name: PropTypes.string,
                description: PropTypes.string,
                disabled: PropTypes.bool,
                commands: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.string.isRequired,
                        name: PropTypes.string.isRequired,
                        command: PropTypes.string.isRequired,
                    })
                ),
            })
        ),
    }),
    updateEntity: PropTypes.func,
};

export default Action;
