const isCompletedFormElement = formElement => {
    const { typename, statusValue, documents = [], locationRecords = [], value } = formElement;

    switch (typename) {
        case 'FormElementEnum':
            return !!statusValue;
        case 'FormElementPictures':
        case 'FormElementDocuments':
            return documents.length > 0;
        case 'FormElementField':
            return value && value.length > 0;
        case 'FormElementLocRecording':
            return locationRecords && locationRecords.length > 0;
        default:
            return false;
    }
};

export default isCompletedFormElement;
