import gql from 'graphql-tag';

const FragmentFormFeatureCollections = gql`
    fragment FormFeatureCollections on Form {
        featureCollections(srid: $srid, worldId: $worldId) {
            bbox
            features {
                geometry {
                    coordinates
                    type
                }
                geometryStyleId
                geometryTypeId
                id
                srid
                type
            }
            srid
            type
            worldId
        }
    }
`;

export default FragmentFormFeatureCollections;
