import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Progress from '@components/FormComponent/Progress';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
    root: {
        display: 'flex',
    },
}));

const AssignmentListAvatar = props => {
    const { data } = props;
    const entity = data.getPatchedEntity();
    const { forms } = entity;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Progress forms={forms} variant="circular" />
        </div>
    );
};

AssignmentListAvatar.propTypes = {
    data: PropTypes.object.isRequired,
    entityClasses: PropTypes.array.isRequired,
};

export default AssignmentListAvatar;
