import gql from 'graphql-tag';

import FragmentAttributeTypeAllAttributes from '../fragments/FragmentAttributeTypeAllAttributes';

const QueryEntityClasses = gql`
    ${FragmentAttributeTypeAllAttributes}

    query QueryEntityClasses($classNames: [String!]) {
        entityClasses(classNames: $classNames) {
            characteristic {
                hierarchical
                supportsAccessPermissions
            }
            className
            name
            namePlural
            entityTypes {
                id
                className
                name
                namePlural
                code
                shortName
                attributeTypes {
                    ...AttributeTypeAllAttributes2
                }
                geometryTypes {
                    id
                    className
                    name
                    description
                    minimumScale
                    maximumScale
                    geometryStyles {
                        id
                        className
                        name
                        description
                        type
                        searchable
                        editable
                    }
                }
                searchable
                creatable
                deprecated
                childTypes {
                    id
                    className
                }
            }
            attributeTypes {
                ...AttributeTypeAllAttributes2
            }
            relationTypes {
                id
                name
                targetEntityClassName
                cardinality
                code
                mandatory
                typeRestrictions {
                    sourceEntityTypeId
                    targetEntityTypeIds
                }
                preselected
                readOnly
                sourcePermissionType
                targetPermissionType
            }
            permissionTypes
        }
    }
`;

export default QueryEntityClasses;
