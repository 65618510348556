/**
 * Check if type is markdown
 * @param {object} options - Options
 * @returns {boolean}
 */

const isMarkdownDescriptionType = ({ options } = {}) => {
    const { descriptionType } = typeof options === 'string' && options ? JSON.parse(options) : {};

    return descriptionType === 'MARKDOWN';
};

export default isMarkdownDescriptionType;
