import uuidv4 from 'uuid/v4';

/**
 * Clone feature without original properties
 * @param {Object} feature - OL feature
 * @returns {Object}
 */

const cloneFeature = feature => {
    const featureId = feature.getId() || uuidv4();
    const clonedFeature = feature.clone();

    clonedFeature.setId(featureId);

    return clonedFeature;
};
export default cloneFeature;
