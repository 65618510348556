import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import CheckIcon from '@material-ui/icons/Check';
import DefaultAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { isAllowedGEOM } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import GeoJSON from '@geomagic/ol/format/GeoJSON';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import Map from '@components/Map';
import TriggerDrawInteractions from '@components/Map/TriggerDrawInteractions';
import TriggerModifyInteractions from '@components/Map/TriggerModifyInteractions';
import getGeometryTypesByStyleId from '@components/Map/utils/getGeometryTypesByStyleId';
import useFeatures from '@components/Map/utils/useFeatures';
import useDrawInteraction from '@components/Map/utils/useDrawInteraction';
import useModifyInteraction from '@components/Map/utils/useModifyInteraction';
import useShowPrompt from '@utils/useShowPrompt';

import getFeatures from './getFeatures';
import getDefaultFeatureStyle from './getFeatureStyle';

const useStyles = makeStyles(({ palette }) => ({
    bottomToolbar: {
        background: palette.background.default,
        borderTop: `1px solid ${palette.divider}`,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const DispatchMap = props => {
    const {
        CloseComponent,
        doc,
        entityClass,
        entityClasses,
        isReadOnly,
        mapProps,
        onChange,
        previousMap,
        triggerProps,
    } = props;
    const entity = doc.getPatchedEntity();
    const { entityType, featureCollections } = entity;
    const { primaryColor, maxExtentZoomLevel, selectColor, srid } = mapProps;

    const features = getFeatures([doc], entityClasses);
    const { stylesByShapeType } = getGeometryTypesByStyleId(entityType, entityClasses);
    const isInteractionAllowed = isAllowedGEOM(entityClass) && stylesByShapeType?.length > 0;

    const mapRef = useRef();
    const showPrompt = useShowPrompt();
    const { enqueueSnackbar } = useSnackbar();
    const [isEditable, setEditable] = useState(false);
    const [selectedGeometryStyle, setSelectedGeometryStyle] = useState();
    const classes = useStyles();
    const theme = useTheme();

    /**
     *  EVENT HANDLER
     */

    const getFeatureStyle = useCallback(getDefaultFeatureStyle(primaryColor, theme), [primaryColor, theme]);

    const handleChangeGeometryStyle = geometryStyle => {
        clearSelection();
        setSelectedGeometryStyle(prev => (!prev ? geometryStyle : null));
    };

    const handleClickSpeedDial = () => {
        setEditable(prev => !prev);
        setSelectedGeometryStyle(false);
    };

    const handleDelete = (...args) => {
        showPrompt({
            title: i18n.t('dialog.removeGeometry.title'),
            content: i18n.t('dialog.removeGeometry.content'),
            onOk: async () => {
                const currentFeatures = featureCollections[0]?.features;
                const selectedFeatureId = selectedFeature.getId();
                const newFeatures = currentFeatures.filter(feature => feature.id !== selectedFeatureId);

                const newPatch = {
                    op: 'replace',
                    path: `/featureCollections/0/features`,
                    value: newFeatures,
                };

                clearSelection();

                onChange(newPatch).then(() => {
                    enqueueSnackbar(i18n.t('notification.removedGeometry'), {
                        key: 'removedGeometry',
                        preventDuplicate: true,
                        variant: 'success',
                    });
                });
            },
        });
    };

    const handleDrawed = async (event, drawedFeature) => {
        if (selectedGeometryStyle) {
            const currentFeatures = featureCollections[0]?.features;
            const newGeoJSONFeature = JSON.parse(new GeoJSON().writeFeature(drawedFeature));
            const { properties, ...rest } = newGeoJSONFeature;

            const newFeatures = [
                ...currentFeatures,
                {
                    ...rest,
                    geometryStyleId: selectedGeometryStyle?.id,
                    srid,
                },
            ];

            const newPatch = {
                op: 'replace',
                path: '/featureCollections/0/features',
                value: newFeatures,
            };

            setSelectedGeometryStyle(false);

            onChange(newPatch).then(data => {
                enqueueSnackbar(i18n.t('notification.addedGeometry'), {
                    key: 'addedGeometry',
                    preventDuplicate: true,
                    variant: 'success',
                });
            });
        }
    };

    const handleModify = async () => {
        const currentFeatures = featureCollections[0]?.features;
        const modifiedFeatureId = modifiedFeature.getId();
        const newGeoJSONFeature = JSON.parse(new GeoJSON().writeFeature(modifiedFeature));
        const { properties, ...rest } = newGeoJSONFeature;
        const modifiedFeatures = currentFeatures.map(feature =>
            feature.id === modifiedFeatureId ? { ...feature, ...rest } : feature
        );

        clearSelection();

        const newPatch = {
            op: 'replace',
            path: '/featureCollections/0/features',
            value: modifiedFeatures,
        };

        onChange(newPatch).then(() => {
            enqueueSnackbar(i18n.t('notification.updatedGeometry'), {
                key: 'updatedGeometry',
                preventDuplicate: true,
                variant: 'success',
            });
        });
    };

    /**
     *  MAP
     */

    const drawerHandler = { onDrawed: handleDrawed };

    const { animateFeatures, clearSelection, selectedFeature } = useFeatures({
        mapRef,
        features,
        isSelectable: !selectedGeometryStyle,
        maxExtentZoomLevel,
        selectColor,
        style: getFeatureStyle,
        withZoom: false,
    });

    useDrawInteraction({ mapRef, geometryStyle: selectedGeometryStyle }, drawerHandler);

    const { modifiedFeature } = useModifyInteraction({
        mapRef,
        feature: selectedFeature,
        isEditable,
    });

    /**
     *  EFFECTS
     */

    useEffect(() => {
        animateFeatures();
    }, [animateFeatures]);

    return (
        <Fragment>
            <ContentRoot scrollable={false} withPadding={false}>
                <Map {...mapProps} mapRef={mapRef} previousMap={previousMap}>
                    {!isReadOnly && isInteractionAllowed && selectedFeature && (
                        <Fragment>
                            <TriggerModifyInteractions
                                modifiedFeature={modifiedFeature}
                                onDelete={handleDelete}
                                onModify={handleModify}
                                handleToggleOpen={handleClickSpeedDial}
                                open={isEditable}
                                selectedFeature={selectedFeature}
                                triggerProps={{ ...PRIMARY_TRIGGER_PROPS, ...triggerProps }}
                            />
                        </Fragment>
                    )}
                </Map>
            </ContentRoot>
            {!isReadOnly && (
                <DefaultAppBar position="static" color="inherit">
                    <Toolbar className={classes.bottomToolbar}>
                        {isInteractionAllowed && (
                            <TriggerDrawInteractions
                                icon={selectedGeometryStyle && <CheckIcon />}
                                onClick={handleChangeGeometryStyle}
                                stylesByShapeType={stylesByShapeType}
                                triggerProps={{ ...PRIMARY_TRIGGER_PROPS, ...triggerProps }}
                            />
                        )}
                        {CloseComponent}
                    </Toolbar>
                </DefaultAppBar>
            )}
        </Fragment>
    );
};

DispatchMap.propTypes = {
    doc: PropTypes.object.isRequired,
    entityClass: PropTypes.object.isRequired,
    entityClasses: PropTypes.array.isRequired,
    isReadOnly: PropTypes.bool,
    mapProps: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    previousMap: PropTypes.object.isRequired,
    triggerProps: PropTypes.object,
};

export default DispatchMap;
