import gql from 'graphql-tag';
import FragmentFormElementRow from './FragmentFormElementRow';

const FragmentFormElementBlock = gql`
    ${FragmentFormElementRow}

    fragment FormElementBlock on FormElementBlock {
        children {
            ...FormElementRow
        }
        description
        dispatchesCreatable
        id
        name
        options
        relevantDispatches {
            className
            id
        }
        seqNumber
        typename
    }
`;

export default FragmentFormElementBlock;
