import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@geomagic/i18n';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { DEFAULT_TEXT_FIELD_PROPS } from '@consts';

import ListBoxComponent from './ListBoxComponent';

const SingleSelect = props => {
    const {
        className,
        disableClearable = true,
        disableCloseOnSelect = false,
        disabled = false,
        error,
        getOptionLabel,
        getOptionSelected,
        label = '',
        loading,
        onBlur,
        onChange,
        onClose,
        onOpen,
        open,
        options = [],
        required = false,
        value,
        isAutocomplete = true,
    } = props;

    /**
     *  EVENT HANDLER
     */

    const handleChangeTextField = event => {
        onChange(event, event.target.value);
    };

    /**
     *  COMPONENTS
     */

    const renderInput = params => (
        <TextField
            {...params}
            error={error}
            fullWidth
            label={label}
            required={required}
            {...DEFAULT_TEXT_FIELD_PROPS}
        />
    );

    const renderValue = option => {
        return getOptionLabel ? getOptionLabel(option) : option.label || option;
    };

    return isAutocomplete ? (
        <Autocomplete
            className={className}
            clearText={i18n.t('tooltip.clear')}
            closeText={i18n.t('tooltip.close')}
            disableClearable={disableClearable}
            disableCloseOnSelect={disableCloseOnSelect}
            disabled={disabled}
            getOptionLabel={getOptionLabel ? getOptionLabel : option => option?.label || option}
            getOptionSelected={getOptionSelected ? getOptionSelected : (option, item) => option.id === item.id}
            ListboxComponent={ListBoxComponent}
            loading={loading}
            loadingText={i18n.t('placeholder.loading')}
            noOptionsText={i18n.t('placeholder.noOptions')}
            onBlur={onBlur}
            onChange={onChange}
            onClose={onClose}
            onOpen={onOpen}
            open={open}
            openText={i18n.t('tooltip.open')}
            options={options}
            renderInput={renderInput}
            value={value || null}
            includeInputInList
        />
    ) : (
        <TextField
            className={className}
            disabled={disabled}
            error={error}
            fullWidth
            label={label}
            onBlur={onBlur}
            onChange={handleChangeTextField}
            required={required}
            select
            SelectProps={{ renderValue: renderValue }}
            value={value || ''}
            {...DEFAULT_TEXT_FIELD_PROPS}
        >
            {options.map(option => (
                <MenuItem key={option.id || option} value={option}>
                    {getOptionLabel ? getOptionLabel(option) : option.label || option}
                </MenuItem>
            ))}
        </TextField>
    );
};

SingleSelect.propTypes = {
    className: PropTypes.string,
    disableClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    getOptionSelected: PropTypes.func,
    isAutocomplete: PropTypes.bool,
    label: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.object]),
};

export default SingleSelect;
