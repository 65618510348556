import getDefaultSync from '@database/getDefaultSync';
import selector from '@database/selector';
import QueryAssignment from '@graphql/queries/QueryAssignment';
import QueryDispatch from '@graphql/queries/QueryDispatch';

import assignmentSelector from './assignment/selector';
import getAssignmentSync from './assignment/sync';
import transformAssignment from './assignment/transform';

const getSyncConfig = () => {
    return [
        {
            collectionName: 'assignments',
            onSync: getAssignmentSync,
            refetchQuery: QueryAssignment,
            selector: assignmentSelector,
            transform: transformAssignment,
        },
        {
            collectionName: 'dispatches',
            onSync: getDefaultSync,
            selector,
            refetchQuery: QueryDispatch,
        },
    ];
};

export default getSyncConfig;
