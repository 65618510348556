import PropTypes from 'prop-types';
import Feature from '@geomagic/ol/Feature';
import Style from '@geomagic/ol/style/Style';
import Icon from '@geomagic/ol/style/Icon';
import Point from '@geomagic/ol/geom/Point';
import Circle from '@geomagic/ol/geom/Circle';
import { transform } from '@geomagic/ol/proj';

const HeadingIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="svgClass"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" fill="#008000" stroke="#ffffff" stroke-width="2"/></svg>`;

const TrackLocationFeature = ({
    isTrackLocation,
    map,
    trackingOverlay,
    rotation,
    currentLocation,
    accuracy,
    trackingLocationConfig = {},
}) => {
    const currentFeature = trackingOverlay.getSource().getFeatureById('currentPosition');
    const currentAccFeature = trackingOverlay.getSource().getFeatureById('currentPositionAccuracy');

    let accuracyFeature;
    let positionFeature;
    let positionHeadingStyle;
    if (!currentAccFeature) {
        accuracyFeature = new Feature();
        accuracyFeature.setId('currentPositionAccuracy');
        trackingOverlay.getSource().addFeature(accuracyFeature);
    } else {
        accuracyFeature = currentAccFeature;
    }
    if (!currentFeature) {
        positionFeature = new Feature();
        positionFeature.setId('currentPosition');
        trackingOverlay.getSource().addFeature(positionFeature);
    } else {
        positionFeature = currentFeature;
    }

    if (!!window.DeviceOrientationEvent && isTrackLocation) {
        if (!positionHeadingStyle) {
            positionHeadingStyle = new Style({
                image: new Icon({
                    rotation,
                    src: 'data:image/svg+xml,' + encodeURIComponent(HeadingIcon),
                    ...trackingLocationConfig.headingIcon,
                }),
            });
        }
        positionHeadingStyle.getImage().setRotation(rotation);
        positionFeature.setStyle(positionHeadingStyle);
    }
    const loc = currentLocation.location;
    if (loc) {
        positionFeature.setGeometry(
            new Point(
                transform(
                    loc,
                    'EPSG:4326',
                    map
                        .getView()
                        .getProjection()
                        .getCode()
                )
            )
        );
        const geom = new Circle(loc, (accuracy * 360) / (2 * Math.PI * 6400000));

        geom.transform(
            'EPSG:4326',
            map
                .getView()
                .getProjection()
                .getCode()
        );
        accuracyFeature.setGeometry(geom);
    }
    return '';
};

TrackLocationFeature.propTypes = {
    isTrackLocation: PropTypes.bool.isRequired,
    map: PropTypes.object.isRequired,
    trackingOverlay: PropTypes.object.isRequired,
    trackingLocationConfig: PropTypes.object,
    rotation: PropTypes.string.isRequired,
    currentLocation: PropTypes.object.isRequired,
    accuracy: PropTypes.number.isRequired,
};

export default TrackLocationFeature;
