import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import sanitizePath from '@utils/sanitizePath';

const useStyles = makeStyles(({ mixins, palette, spacing, zIndex }) => ({
    root: {
        display: 'flex',
        height: '100%',
        backgroundColor: palette.background.paper,
        borderRight: `1px solid ${palette.divider}`,
        padding: 0,
        flexDirection: 'column',
        zIndex: zIndex.drawer + 1,
    },
    listItem: {
        display: 'flex',
        height: 64,
        width: 64,
        padding: 0,
    },
    active: {
        color: palette.primary.main,
    },
    listItemIcon: {
        minWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const DesktopNavigation = props => {
    const { activeId, basePath, className, items, onChange } = props;
    const classes = useStyles();

    const handleChange = id => () => {
        onChange(id);
    };

    return (
        <List className={classNames(classes.root, className)}>
            {items.map(({ id, name, path, Icon: IconComponent }) => {
                return (
                    <Tooltip key={id} title={name}>
                        <ListItem
                            button
                            className={classes.listItem}
                            onClick={handleChange(id)}
                            {...(basePath && path && { component: NavLink, to: sanitizePath(`${basePath}${path}`) })}
                        >
                            <ListItemIcon
                                className={classNames(classes.listItemIcon, { [classes.active]: id === activeId })}
                            >
                                <IconComponent />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                );
            })}
        </List>
    );
};

DesktopNavigation.propTypes = {
    activeId: PropTypes.string.isRequired,
    basePath: PropTypes.string,
    className: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
            Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
            id: PropTypes.string.isRequired,
            path: PropTypes.string,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DesktopNavigation;
