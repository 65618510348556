import flattenDeepByKey from '@utils/flattenDeepByKey';
import { FORM_COMPONENT_CHILDREN_KEY } from '../consts';

const getFormElementsByKey = (forms = [], keys = ['FormElementDocuments', 'FormElementPictures']) => {
    const _forms = flattenDeepByKey(forms, FORM_COMPONENT_CHILDREN_KEY);
    const filteredForms = _forms.filter(({ typename }) => keys.includes(typename));

    return filteredForms;
};

export default getFormElementsByKey;
