import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import { useTheme } from '@material-ui/core/styles';

import PullToRefresh from '@components/PullToRefresh';
import QueryAssignment from '@graphql/queries/QueryAssignment';
import syncAssignment from '@synchronization/assignment/sync';
import transformAssignment from '@synchronization/assignment/transform';
import getUpdatedDoc from '@database/getUpdatedDoc';
import resetDoc from '@database/resetDoc';
import showModal from '@utils/showModal';
import { getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { checkFormIsFinished } from '@components/FormComponent';

import AssignmentDetail from './AssignmentDetail';
import AssignmentList from './AssignmentList';
import getDefaultFeatureStyle from './getFeatureStyle';
import Tasks from '../Tasks';

const queryConstructor = user => collection => {
    return collection.find({ selector: { userId: user.id }, sort: [{ modifiedOn: 'asc' }] });
};

const Assignment = props => {
    const {
        basePath,
        client,
        database,
        entityClasses,
        getPreviousMap,
        history,
        isLoading: isLoadingView,
        isMobile,
        isOnline,
        LoadingComponent,
        mapProps,
        onCloseSwipeableArea,
        onFetchData,
        stateRef,
        user,
        vectorTileServerUrl,
    } = props;

    const { primaryColor } = mapProps;

    const { id: paramsId } = useParams();
    const { result: assignments, isFetching } = useRxData('assignments', queryConstructor(user));
    const theme = useTheme();

    const assignment = paramsId ? assignments.find(item => item.entity.id.toString() === paramsId) : null;
    const isLoading = isLoadingView || isFetching;

    /**
     *  EVENT HANDLER
     */

    const getFeatureStyle = useCallback(getDefaultFeatureStyle(primaryColor, theme), [primaryColor, theme]);

    const handleClickAssignment = ({ entity }) => {
        history.push(`${basePath}/${entity.id}`);
    };

    const handleCheckForOpenDrafts = async doc => {
        const { relevantDispatches } = doc.getPatchedEntity();

        if (relevantDispatches.length === 0) return true;

        const selector = {
            'draft.closed': false,
            'entity.id': { $in: relevantDispatches.map(dispatch => dispatch.id) },
        };
        const collection = database.dispatches;
        const openDrafts = await collection.find({ selector: { ...selector, userId: user.id } }).exec();

        return openDrafts?.length === 0;
    };

    const handleSyncAssignment = async doc => {
        const syncProps = {
            client,
            database,
            doc,
            entityClasses,
            mapProps,
            refetchQuery: QueryAssignment,
            transform: transformAssignment,
        };
        await syncAssignment(syncProps);
    };

    const handleUpdateAssignment = async doc => {
        const entityReference = getReference(doc?.entity);
        const updatedEntity = await getUpdatedDoc(client, QueryAssignment, entityReference, mapProps);

        if (updatedEntity) {
            await resetDoc(doc, updatedEntity);
        }
    };

    const handleDeleteAssignment = async doc => {
        await doc.remove();
    };

    const handleOpenTasks = data => {
        const entity = data?.getPatchedEntity();
        const name = entity?.displayName;

        const { forms } = entity;

        const isFormFinished = checkFormIsFinished(forms);
        const isCloseable = isFormFinished && isOnline;

        showModal({
            title: i18n.t('dialog.tasks.title', { variables: { name } }),
            content: (
                <ApolloProvider client={client}>
                    <Tasks
                        checkFailedMessage={i18n.t('assignment.notification.openDraft')}
                        closeableErrorText={i18n.t('assignment.description.formNotFinished')}
                        data={data}
                        entityClasses={entityClasses}
                        isCloseable={isCloseable}
                        isMobile={isMobile}
                        isOnline={isOnline}
                        onCheck={handleCheckForOpenDrafts}
                        onSyncEntity={handleSyncAssignment}
                        onUpdateEntity={handleUpdateAssignment}
                        user={user}
                    />
                </ApolloProvider>
            ),
            isFullScreen: isMobile,
        });
    };

    return assignment ? (
        <AssignmentDetail
            assignment={assignment}
            basePath={basePath}
            client={client}
            entityClasses={entityClasses}
            getFeatureStyle={getFeatureStyle}
            getPreviousMap={getPreviousMap}
            handleCheckForOpenDrafts={handleCheckForOpenDrafts}
            isMobile={isMobile}
            isOnline={isOnline}
            mapProps={mapProps}
            onDeleteAssignment={handleDeleteAssignment}
            onOpenTasks={handleOpenTasks}
            onSyncAssignment={handleSyncAssignment}
            stateRef={stateRef}
            user={user}
        />
    ) : (
        <PullToRefresh
            isPullable={!!(!isLoading && isOnline)}
            LoadingComponent={LoadingComponent}
            onRefresh={onFetchData}
        >
            <AssignmentList
                assignments={assignments}
                client={client}
                entityClasses={entityClasses}
                getFeatureStyle={getFeatureStyle}
                handleCheckForOpenDrafts={handleCheckForOpenDrafts}
                isLoading={isLoading}
                isMobile={isMobile}
                isOnline={isOnline}
                mapProps={mapProps}
                onClick={handleClickAssignment}
                onCloseSwipeableArea={onCloseSwipeableArea}
                onDeleteAssignment={handleDeleteAssignment}
                onOpenTasks={handleOpenTasks}
                onSyncAssignment={handleSyncAssignment}
                user={user}
                vectorTileServerUrl={vectorTileServerUrl}
            />
        </PullToRefresh>
    );
};

Assignment.propTypes = {
    basePath: PropTypes.string.isRequired,
    entityClasses: PropTypes.array.isRequired,
    getPreviousMap: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
    isOnline: PropTypes.bool,
    LoadingComponent: PropTypes.func,
    mapProps: PropTypes.object.isRequired,
    onCloseSwipeableArea: PropTypes.func.isRequired,
    onFetchData: PropTypes.func.isRequired,
    user: PropTypes.object,
};

export default Assignment;
