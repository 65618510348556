import { i18n } from '@geomagic/i18n';

import GeneralPreferences from './modals/GeneralPreferences';
import MapPreferences from './modals/MapPreferences';

const getMenuItems = () => {
    return [
        {
            id: 'general',
            label: i18n.t('label.preference.general'),
            Component: GeneralPreferences,
        },
        {
            id: 'map',
            label: i18n.t('label.preference.map'),
            Component: MapPreferences,
        },
        // {
        //     id: 'offlineMap',
        //     Component: OfflineMapPreferences,
        //     label: i18n.t('label.offlineMap'),
        // },
        // {
        //     id: 'offlineDocuments',
        //     label: i18n.t('label.offlineDocuments'),
        //     Component: OfflineDocumentStoreList,
        // },
    ];
};

export default getMenuItems;
