import checkValidServiceWorker from './checkValidServiceWorker';
import isLocalhost from './isLocalhost';
import registerValidSW from './registerValidSW';

const register = config => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        // const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
        const swUrl = `${window.BASE_PATH}/service-worker.js`.replace(/\/\/+/g, '/');

        if (isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            checkValidServiceWorker(swUrl, config);

            // Add some additional logging to localhost, pointing developers to the
            // service worker/PWA documentation.
            navigator.serviceWorker.ready.then(() => {
                console.log(
                    'This web app is being served cache-first by a service ' +
                        'worker. To learn more, visit https://bit.ly/CRA-PWA'
                );
            });
        } else {
            // Is not localhost. Just register service worker
            registerValidSW(swUrl, config);
        }
    }
};

export default register;
