/**
 * A component to display a checklist item in a list item.
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { i18n } from '@geomagic/i18n';
import { Trigger } from '@geomagic/core';
import AssignmentIcon from '@geomagic/nam-react-core/icons/Assignment';
import EntityState from '@geomagic/nam-react-core/components/EntityState';
import SingleSelect from '@geomagic/nam-react-core/components/SingleSelect';

import { MOBILE_TRIGGER_SIZE } from '@consts';

const useStyles = makeStyles(({ breakpoints, palette, spacing, shape, typography }) => ({
    root: {
        minHeight: 60,
        paddingRight: 106,
    },
    badge: {
        borderRadius: shape.borderRadius,
        display: 'flex',
        whiteSpace: 'nowrap',
    },
    listItemText: {
        flex: 1,
    },
    iconContainer: {
        position: 'relative',
        display: 'flex',
        width: 32,
        height: 32,
        color: palette.primary.main,
    },
    icon: {
        width: 'inherit',
        height: 'inherit',
    },
    iconDescription: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'inherit',
        height: 'inherit',
        fontSize: typography.pxToRem(12),
    },
    input: {
        display: 'flex',
        flex: 1,
        maxWidth: 400,
        [breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    },
    trigger: {
        marginLeft: spacing(),
        [breakpoints.down('md')]: {
            height: MOBILE_TRIGGER_SIZE,
            marginLeft: 0,
            width: MOBILE_TRIGGER_SIZE,
        },
    },
}));

const ChecklistItem = props => {
    const {
        assignmentEntityTypes = [],
        editItem,
        entityTypesSelectOptions,
        handleDelete,
        handleFinishEdit,
        handleToogleCheck,
        handleToogleEditItem,
        isAllowedUpdateEntity,
        isEditMode = false,
        isLastItem,
        item,
        setEditItem,
    } = props;

    const classes = useStyles(props);
    const { id, checked = false, name, assignment, assignmentTypeCatId } = item;
    const hasAssignment = !!assignment;

    const assignmentType = assignmentTypeCatId
        ? entityTypesSelectOptions.find(option => option.id === assignmentTypeCatId)
        : null;

    /*
     *  EVENT HANDLER
     */

    const handleChangeName = event => {
        setEditItem({ ...editItem, name: event.target.value });
    };

    const handleSelectAssignmentType = (event, newValue) => {
        setEditItem({ ...editItem, assignmentTypeCatId: newValue ? newValue.id : null });
    };

    /*
     *  COMPONENTS
     */

    const EditListItemActions = isEditMode ? (
        <Fragment>
            <Tooltip title={i18n.t('button.save')}>
                <span>
                    <Trigger
                        className={classes.trigger}
                        disabled={editItem.name.length === 0}
                        icon={<CheckIcon />}
                        onClick={event => handleFinishEdit()}
                    />
                </span>
            </Tooltip>
            <Trigger
                className={classes.trigger}
                icon={<ClearIcon />}
                onClick={handleToogleEditItem}
                tooltip={i18n.t('button.cancel')}
            />
        </Fragment>
    ) : (
        <Fragment>
            <Trigger
                className={classes.trigger}
                icon={<EditIcon />}
                onClick={event => handleToogleEditItem(event, item)}
                tooltip={i18n.t('checklist.tooltip.editItem')}
            />
            <Tooltip title={i18n.t('checklist.tooltip.deleteItem')}>
                <span>
                    <Trigger className={classes.trigger} icon={<DeleteIcon />} onClick={() => handleDelete(id)} />
                </span>
            </Tooltip>
        </Fragment>
    );

    const AvatarComponent = hasAssignment && (
        <Badge
            className={classes.badge}
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={<EntityState entity={assignment} size="extraSmall" variant="circle" />}
        >
            <Tooltip title={assignment.displayName}>
                <div className={classes.iconContainer}>
                    <AssignmentIcon className={classes.icon} />
                    <Typography className={classes.iconDescription} variant="caption">
                        {assignmentEntityTypes.find(option => option.id === assignment.entityType.id)?.shortName}
                    </Typography>
                </div>
            </Tooltip>
        </Badge>
    );

    const SecondaryActionComponent =
        checked || hasAssignment
            ? hasAssignment
                ? AvatarComponent
                : null
            : isAllowedUpdateEntity
            ? EditListItemActions
            : null;

    return (
        <ListItem className={classes.root} divider={!isLastItem}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={checked}
                    color="primary"
                    disabled={!!assignmentType || isEditMode || !isAllowedUpdateEntity}
                    onChange={event => handleToogleCheck(event.target.checked, id)}
                />
            </ListItemIcon>
            {isEditMode ? (
                <div className={classes.listItemText}>
                    <TextField
                        autoFocus
                        className={classes.input}
                        label={i18n.t('checklist.label.name')}
                        onChange={handleChangeName}
                        required
                        variant="outlined"
                        value={editItem.name}
                    />
                    <SingleSelect
                        disableClearable={false}
                        className={classes.input}
                        label={i18n.t('default.type')}
                        onChange={handleSelectAssignmentType}
                        options={entityTypesSelectOptions}
                        value={
                            editItem.assignmentTypeCatId
                                ? entityTypesSelectOptions.find(option => option.id === editItem.assignmentTypeCatId)
                                : null
                        }
                    />
                </div>
            ) : (
                <ListItemText
                    className={classes.listItemText}
                    primary={checked ? <s>{name}</s> : name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={assignmentType ? checked ? <s>{assignmentType.label}</s> : assignmentType.label : null}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            )}
            <ListItemSecondaryAction>{SecondaryActionComponent}</ListItemSecondaryAction>
        </ListItem>
    );
};

ChecklistItem.propTypes = {
    assignmentEntityTypes: PropTypes.array,
    editItem: PropTypes.object,
    entityTypesSelectOptions: PropTypes.array,
    handleDelete: PropTypes.func,
    handleFinishEdit: PropTypes.func,
    handleToogleCheck: PropTypes.func,
    handleToogleEditItem: PropTypes.func,
    isAllowedUpdateEntity: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool,
    isLastItem: PropTypes.bool,
    item: PropTypes.object,
    setEditItem: PropTypes.func,
};

export default ChecklistItem;
