import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import useServiceWorker from '@serviceWorker/useServiceWorker';

const useStyles = makeStyles(({ breakpoints, palette, spacing, zIndex }) => {
    return {
        root: {
            alignItems: 'center',
            background: palette.info.main,
            bottom: 0,
            color: palette.getContrastText(palette.info.main),
            display: 'flex',
            height: 100,
            justifyContent: 'space-between',
            padding: spacing(2),
            position: 'absolute',
            width: '100%',
            zIndex: zIndex.appBar + 1,
            [breakpoints.down('sm')]: {
                padding: spacing(1),
            },
        },
        container: {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            marginRight: spacing(),
        },
        icon: {
            marginRight: spacing(),
        },
    };
});

const ServiceWorkerUpdate = () => {
    const { isUpdateAvailable, updateAssets } = useServiceWorker();
    const classes = useStyles();

    /**
     *  EVENT HANDLER
     */

    const handleClick = () => {
        updateAssets();
    };

    return (
        <Slide direction="up" in={isUpdateAvailable} timeout={250}>
            <Paper className={classes.root} elevation={12} square>
                <div className={classes.container}>
                    <NewReleasesIcon className={classes.icon} fontSize="large" />
                    <Typography>{i18n.t('label.updateAvailable')}</Typography>
                </div>
                <Trigger color="inherit" onClick={handleClick} size="small" variant="outlined">
                    {i18n.t('button.refresh')}
                </Trigger>
            </Paper>
        </Slide>
    );
};

export default ServiceWorkerUpdate;
