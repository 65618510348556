import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useRxDB } from 'rxdb-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Swipeable } from 'react-swipeable';

import CloseIcon from '@material-ui/icons/Close';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import AppBarProvider from '@components/AppBar/AppBarProvider';
import ConflictResolution from '@components/ConflictResolution';
import Map from '@components/Map';
import RouteContainer from '@components/RouteContainer';
import pull from '@database/pull';
import push from '@database/push';
import useSyncCount from '@database/useSyncCount';
import getFetchConfig from '@synchronization/getFetchConfig';
import getSyncConfig from '@synchronization/getSyncConfig';
import showModal from '@utils/showModal';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

const useStyles = makeStyles(({ breakpoints, mixins, palette, shape, spacing, transitions }) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        },
        toolbar: {
            minHeight: 56,
        },
        container: {
            display: 'flex',
            flex: 1,
            position: 'relative',
            overflow: 'hidden',
        },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row-reverse',
            position: 'relative',
            overflow: 'hidden',
            [breakpoints.down('md')]: {
                flexDirection: 'row',
            },
            '&$mobile': {
                marginBottom: 56,
            },
        },
        map: {
            borderLeft: `1px solid ${palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '55%',
            [breakpoints.down('md')]: {
                border: 'none',
                height: '45%',
                width: '100%',
            },
            '&$fullScreen': {
                display: 'none',
                height: '45%',
                width: 0,
            },
        },
        routes: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '45%',
            transition: transitions.create('height', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),
            [breakpoints.down('md')]: {
                position: 'absolute',
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflow: 'hidden',
                height: `calc(55% + ${shape.borderRadius * 2}px)`,
                backgroundColor: palette.background.paper,
                borderTop: '1px solid rgba(0, 0, 0, .125)',
                borderTopLeftRadius: shape.borderRadius * 2,
                borderTopRightRadius: shape.borderRadius * 2,
            },
            '&$fullScreen': {
                borderRadius: 0,
                borderTop: 'none',
                height: '100%',
                width: '100%',
            },
        },
        swipeableArea: {
            borderBottom: `1px solid ${palette.divider}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 36,
            cursor: 'pointer',
        },
        icon: {
            color: palette.text.primary,
            transform: 'scaleX(1.5) rotate(0deg)',
            transition: transitions.create('transform', transitions.duration.shortest),
            '&$fullScreen': {
                transform: 'scaleX(1.5) rotate(180deg)',
            },
        },
        trigger: {
            marginRight: spacing(),
        },
        fullScreen: {},
        mobile: {},
    };
});

const getConflicts = conflicts => {
    const groups = [];

    if (conflicts) {
        const keys = Object.keys(conflicts);

        keys.forEach(key => {
            const item = conflicts[key];

            item?.conflicts?.length > 0 && groups.push({ id: key, items: item?.conflicts });
        });
    }

    return groups;
};

const ViewContainer = props => {
    const { config, state } = props;
    const { entityClasses, isMobile, preferences, user } = state;
    const {
        srid,
        view: mapView,
        systemInformation,
        updateGlobalState,
        vectorTileServerUrl,
        world,
        worlds,
        ...rest
    } = state;
    const isMapVisible = !preferences.isMapVisible;
    const syncConfig = getSyncConfig();
    const userId = user.id;

    const client = useApolloClient();
    const conflictRef = useRef();
    const mapRef = useRef();
    const stateRef = useRef();
    const database = useRxDB();
    const enqueueLoadingSnackbar = useLoadingSnackbar();
    //const showPrompt = useShowPrompt();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [isFullScreen, setFullScreen] = useState(isMapVisible);
    const [isSyncing, setSyncing] = useState(false);
    const [title, setTitle] = useState();
    const [actions, setActions] = useState();
    const classes = useStyles();
    const syncCount = useSyncCount(database, syncConfig, userId);

    /**
     *  EVENT HANDLER
     */

    const getPreviousMap = useCallback(() => {
        const previousMap = mapRef.current?.map;

        return previousMap;
    }, []);

    const handleCloseSwipeableArea = () => {
        setFullScreen(false);
    };

    const handleClickSwipeableArea = () => {
        setFullScreen(!isFullScreen);
    };

    // const handleSendToService = (log, createdOn, snackbarKey) => () => {
    //     const mail = systemInformation?.serviceMail;
    //     const date = dayjs(createdOn).format(DATETIME_DISPLAY_FORMAT);

    //     const execute = () => {
    //         window.open(
    //             `mailto:${mail}?subject=${i18n.t('synchronization.mail.subject.syncError', {
    //                 variables: { date },
    //             })}&body=${log}`
    //         );

    //         closeSnackbar(snackbarKey);
    //     };

    //     showPrompt({
    //         title: i18n.t('synchronization.dialog.sendToService.title'),
    //         content: i18n.t('synchronization.dialog.sendToService.content', { variables: { mail } }),
    //         onOk: execute,
    //     });
    // };

    const handleSynchronisationError = error => {
        const snackbarKey = 'synchronizationErrors';
        //const createdOn = +new Date();

        if (error) {
            enqueueSnackbar(i18n.t('synchronization.notification.push.errors'), {
                action: (
                    <div>
                        {/* <Trigger
                            className={classes.trigger}
                            onClick={handleSendToService(error, createdOn, snackbarKey)}
                            variant="text"
                        >
                            {i18n.t('synchronization.button.sendToService')}
                        </Trigger> */}
                        <Trigger
                            icon={<CloseIcon />}
                            onClick={() => closeSnackbar(snackbarKey)}
                            size="small"
                            variant="icon"
                        />
                    </div>
                ),
                key: snackbarKey,
                persist: true,
                variant: 'warning',
            });
        }
    };

    const handleSwipe = ({ dir }) => {
        setFullScreen(dir === 'Up');
    };

    const handleUpdateConflicts = () => {
        const execute = conflictRef.current?.onUpdateConflicts;

        execute && execute();
    };

    /**
     *  EVENT HANDLER SYNCRONIZATION
     */

    const handleFetch = async () => {
        const config = getFetchConfig(mapProps);

        setSyncing(true);

        const result = await enqueueLoadingSnackbar({
            loadingText: i18n.t('synchronization.notification.pull.loading'),
            finishedText: i18n.t('synchronization.notification.pull.loadingFinished'),
            func: () => pull({ database, client, config, mapProps, userId }),
        });

        setSyncing(false);
        updateGlobalState({ lastFetch: +new Date() });

        const conflicts = getConflicts(result);

        if (conflicts.length > 0) {
            showModal({
                title: i18n.t('synchronization.label.conflicts'),
                content: (
                    <ConflictResolution
                        config={config}
                        conflictRef={conflictRef}
                        conflicts={conflicts}
                        mapProps={mapProps}
                    />
                ),
                isFullScreen: true,
                okAction: (
                    <Trigger color="inherit" onClick={handleUpdateConflicts} size="medium" variant="text">
                        {i18n.t('button.refresh')}
                    </Trigger>
                ),
            });
        }
    };

    const handleSync = async () => {
        const config = syncConfig;

        setSyncing(true);

        await enqueueLoadingSnackbar({
            loadingText: i18n.t('synchronization.notification.push.loading'),
            finishedText: i18n.t('synchronization.notification.push.loadingFinished'),
            func: () => push({ database, client, config, entityClasses, mapProps, userId }),
            onError: handleSynchronisationError,
        });

        setSyncing(false);
    };

    /**
     *  EFFECTS
     */

    useEffect(() => {
        setFullScreen(isMapVisible);
    }, [isMapVisible]);

    /**
     *  CONTEXT
     */

    const appBarValue = useMemo(() => ({ title, setTitle, actions, setActions }), [actions, title]);

    /**
     *  APP STATE
     */

    const componentConfig = useMemo(() => config(), [config]);
    const { AppBarComponent, NavigationComponent, views } = componentConfig;
    const hasNavigation = views && views.length > 1;

    const overlayLayers = mapView?.overlayLayers || [];
    const vectorTileLayers = overlayLayers.filter(
        layer => layer.type === 'VectorTileLayer' && !!layer.sourceOptions?.url
    );

    const mapProps = {
        markingColor: systemInformation?.spatial?.geometryColors?.marking,
        primaryColor: systemInformation?.spatial?.geometryColors?.primary,
        selectColor: systemInformation?.spatial?.geometryColors?.selection,
        mapRef,
        maxExtentZoomLevel: mapView?.maxExtentZoomLevel,
        srid,
        vectorTileLayers,
        vectorTileServerUrl,
        view: mapView,
        world,
    };

    const appState = {
        ...rest,
        client,
        database,
        getPreviousMap,
        isLoading: isSyncing,
        mapProps,
        onCloseSwipeableArea: handleCloseSwipeableArea,
        onFetchData: handleFetch,
        onSyncData: handleSync,
        syncCount,
        systemInformation,
        updateGlobalState,
    };

    return (
        <AppBarProvider value={appBarValue}>
            <div className={classes.root}>
                {AppBarComponent && (
                    <Fragment>
                        <RouteContainer
                            items={views.map(view => ({ ...view, Component: AppBarComponent }))}
                            state={appState}
                        />
                        <div className={classes.toolbar} />
                    </Fragment>
                )}
                <div className={classes.container}>
                    {hasNavigation && NavigationComponent && <NavigationComponent items={views} state={appState} />}
                    <div ref={stateRef} />
                    <div className={classNames(classes.content, { [classes.mobile]: hasNavigation && isMobile })}>
                        <div className={classNames(classes.map, { [classes.fullScreen]: isFullScreen })}>
                            <Map {...rest} {...mapProps} />
                        </div>
                        <div
                            className={classNames(classes.routes, {
                                [classes.fullScreen]: isFullScreen,
                            })}
                        >
                            {isMobile && (
                                <Swipeable onSwiped={handleSwipe} trackMouse={true}>
                                    <div className={classes.swipeableArea} onClick={handleClickSwipeableArea}>
                                        <KeyboardArrowUpIcon
                                            className={classNames(classes.icon, { [classes.fullScreen]: isFullScreen })}
                                        />
                                    </div>
                                </Swipeable>
                            )}
                            <RouteContainer items={views} state={{ ...appState, stateRef }} />
                        </div>
                    </div>
                </div>
            </div>
        </AppBarProvider>
    );
};

ViewContainer.propTypes = {
    config: PropTypes.func.isRequired,
    entityClasses: PropTypes.array,
    state: PropTypes.object,
};

export default ViewContainer;
