import get from 'lodash/get';
import { notifyUser } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

/**
 * Get Autoform schema and ui by attribute type
 * @param {Object} _
 * @param {Object} attributeType - GeoNAM attribute type
 * @returns {void}
 */

const formValidationHandler = (_, formContext) => {
    const { errors } = formContext;
    let requiredMessage = false;
    if (errors && errors.length) {
        const errorMessages = [];
        errors.forEach((error, index) => {
            const { dataPath, message, keyword } = error;
            if (keyword === 'required' && !requiredMessage) {
                errorMessages.push(i18n.t('notification.populateMandatoryFields'));
                requiredMessage = true;
            } else if (dataPath) {
                const errorProperty = get(formContext.getSchema().properties, dataPath);
                const { title } = errorProperty || {};
                errorMessages.push(title + ' ' + message);
            }
        });
        const message = errorMessages.join('; ');
        notifyUser({
            type: 'error',
            message,
        });
    }
};

export default formValidationHandler;
