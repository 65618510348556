import getDownloadUrl from './getDownloadUrl';
import getFileByDownloadUrl from './getFileByDownloadUrl';
import getFilePropsFromDocumentEntity from './getFilePropsFromDocumentEntity';

const fetchAndStoreAttachments = async (documents = [], databaseDoc) => {
    for (let i = 0; i < documents.length; i++) {
        const doc = documents[i];
        const { hash, name, type } = getFilePropsFromDocumentEntity(doc);

        const baseUrl = getDownloadUrl();
        const url = `${baseUrl}${doc.id}`;
        const file = await getFileByDownloadUrl({ url, name, options: { type } });

        if (file && hash) {
            await databaseDoc.putAttachment({ id: hash, data: file, type });
            console.log(name, 'inserted');
        }
    }
};

export default fetchAndStoreAttachments;
