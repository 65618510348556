/**
 * Add features to specific layer
 * @param {Object} layer - OpenLayers layer
 * @param {Array.<Object>} features - Array of OpenLayers features
 * @returns {void}
 */

const addFeaturesToLayer = (layer, features) => {
  features.forEach((feature) => {
    layer.getSource().addFeature(feature);
  });
};

export default addFeaturesToLayer;
