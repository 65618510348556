import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import MutationTokenLogin from '@graphql/mutations/MutationTokenLogin';

const timeout = (ms, promise) => {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            reject(new Error('timeout'));
        }, ms);
        promise.then(resolve, reject);
    });
};

const useSSO = ({
    location,
    path,
    portalUrl,
    redirectPath,
    redirectParamName = 'redirectPath',
    tokenParamName,
    onLogin,
    urlCheckErrorPath,
}) => {
    const currentPathname = location.pathname;
    const searchParams = location.search;

    const isExecutable = currentPathname === path;

    /**
     * MUTATIONS
     */

    const [loginWithToken] = useMutation(MutationTokenLogin);

    /**
     * EFFECTS
     */

    useEffect(() => {
        if (isExecutable && portalUrl && searchParams.length === 0) {
            const isProduction = process.env.NODE_ENV === 'production';
            const params = redirectParamName ? `?${redirectParamName}=${window.btoa(redirectPath)}` : '';
            const url = `${portalUrl}${params}`;

            if (!!urlCheckErrorPath) {
                const checkTimeout = 10000;

                timeout(checkTimeout, fetch(isProduction ? portalUrl : '/devPortal'))
                    .then(response => {
                        if (response.status === 200) {
                            window.location.replace(url);
                        } else {
                            window.location.replace(urlCheckErrorPath);
                        }
                    })
                    .catch(error => {
                        window.location.replace(urlCheckErrorPath);
                    });
            } else {
                window.location.replace(url);
            }
        }
    }, [isExecutable, portalUrl, redirectParamName, redirectPath, searchParams, urlCheckErrorPath]);

    useEffect(() => {
        if (isExecutable && searchParams.length > 0 && tokenParamName) {
            const parsedParams = new URLSearchParams(searchParams);
            const token = parsedParams.get(tokenParamName);
            const appRedirectPath = parsedParams.get(redirectParamName);

            if (token) {
                loginWithToken({ variables: { token } }).then(({ data }) => {
                    onLogin && onLogin(data?.tokenLogin, appRedirectPath ? window.atob(appRedirectPath) : undefined);
                });
            }
        }
    }, [isExecutable, loginWithToken, onLogin, redirectParamName, searchParams, tokenParamName]);
};

export default useSSO;
