import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';

import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

import { MutationLogin } from '@geomagic/geonam-graphql';
import { ContentRoot } from '@geomagic/layout';
import { Login as DefaultLogin } from '@geomagic/login';

import { PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        padding: spacing(2),
        width: 500,
    },
    logo: {
        marginTop: spacing(2),
        marginBottom: spacing(),
        height: spacing(3),
        width: 'auto',
    },
}));

const Login = props => {
    const { onLogin, ssoLink } = props;
    const classes = useStyles(props);

    /**
     *  MUTATIONS
     */

    const [login, { loading }] = useMutation(MutationLogin);

    /**
     *  EVENT HANDLER
     */

    const handleLogin = variables =>
        login({ variables }).then(({ data }) => {
            onLogin(data?.login);
        });

    return (
        <ContentRoot className={classes.root}>
            <Slide in timeout={500}>
                <Paper className={classes.paper} variant="outlined">
                    <DefaultLogin
                        triggerProps={{ disabled: loading, ...PRIMARY_TRIGGER_PROPS }}
                        isSubmitOnEnter={false}
                        onLogin={handleLogin}
                    />
                    {ssoLink}
                </Paper>
            </Slide>
        </ContentRoot>
    );
};

Login.propTypes = {
    onLogin: PropTypes.func.isRequired,
};

export default Login;
