import React, { useEffect, useMemo, useState } from 'react';
import register from './register';
import ServiceWorkerContext from './ServiceWorkerContext';

const ServiceWorkerProvider = ({ children }) => {
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false);

    useEffect(() => {
        console.log('REGISTER SERVICEWORKER');
        register({
            onUpdate: registration => {
                setWaitingServiceWorker(registration.waiting);
                setUpdateAvailable(true);
            },
            onWaiting: waiting => {
                setWaitingServiceWorker(waiting);
                setUpdateAvailable(true);
            },
        });
    }, []);

    useEffect(() => {
        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }, [waitingServiceWorker]);

    const value = useMemo(
        () => ({
            isUpdateAvailable,
            updateAssets: () => {
                waitingServiceWorker && waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
            },
        }),
        [isUpdateAvailable, waitingServiceWorker]
    );

    return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

export default ServiceWorkerProvider;
