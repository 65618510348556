import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';
import { i18n } from '@geomagic/i18n';
import { DATETIME_DISPLAY_FORMAT } from '@consts';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
    root: {},
}));

const ConflictResolutionText = props => {
    const { data } = props;
    const formattedDate = dayjs(data?.modifiedOn).format(DATETIME_DISPLAY_FORMAT);
    const title = data?.entity?.displayName;
    const subtitle = formattedDate
        ? i18n.t('synchronization.label.modifiedOn', { variables: { modifiedOn: formattedDate } })
        : '';

    const classes = useStyles();

    return (
        <ListItemText
            className={classes.root}
            primary={title}
            secondary={subtitle}
            primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
            secondaryTypographyProps={{ noWrap: true, variant: 'body2' }}
        />
    );
};

ConflictResolutionText.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ConflictResolutionText;
