import gql from 'graphql-tag';

import FragmentLayer from '../fragments/FragmentLayer';

const QueryWorld = gql`
    ${FragmentLayer}

    query WorldQuery($viewCodes: [String!]) {
        worlds(viewCodes: $viewCodes) {
            id
            className
            code
            name
            views {
                id
                code
                className
                name
                maxExtentZoomLevel
                bbox
                epsgCode
                baseLayers {
                    ...Layer
                }
                overlayLayers {
                    ...Layer
                }
            }
            projections {
                key
                name
                wkt
                aliases
            }
        }
    }
`;

export default QueryWorld;
