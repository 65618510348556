/**
 * Convert lowercase to uppercase for d and y
 * @param {string} format - Date formatted string
 * @returns {string}
 */

const convertDateFormat = format => {
    return format.replace(/d/g, 'D').replace(/y/g, 'Y');
};

export default convertDateFormat;
