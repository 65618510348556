/**
 * Remove interactions from map
 * @param {Object} map - OpenLayers map
 * @param {Array.<Object>} interactions - OpenLayers interactions
 * @returns {void}
 */

const removeInteractions = (map, interactions) => {
    if (Array.isArray(interactions)) {
        interactions.forEach(interaction => {
            map.removeInteraction(interaction);
        });
    } else {
        map.removeInteraction(interactions);
    }
};

export default removeInteractions;
