import groupBy from 'lodash/groupBy';
import ActionsComponent from './DispatchListActions';
import AvatarComponent from './DispatchListAvatar';
import TextComponent from './DispatchListText';

const getGroups = ({ items, onClick }) => {
    const groups = [];

    if (items && items.length > 0) {
        const { drafts, dispatches } = groupBy(items, ({ draft }) => (!!draft ? 'drafts' : 'dispatches'));

        const componentProps = {
            onClick,
            ActionsComponent,
            AvatarComponent,
            TextComponent,
        };

        if (drafts) {
            groups.push({
                id: 'dispatchDrafts',
                label: `Entwürfe (${drafts.length})`,
                items: drafts,
                ...componentProps,
            });
        }

        if (dispatches) {
            groups.push({
                id: 'dispatches',
                label: `Meldungen (${dispatches.length})`,
                items: dispatches,
                ...componentProps,
            });
        }
    }

    return groups;
};

export default getGroups;
