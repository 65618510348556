import gql from 'graphql-tag';

const FragmentFormElementField = gql`
    fragment FormElementField on FormElementField {
        allowedValues {
            id
            className
            code
            name
            value
        }
        description
        dataType
        displayedIfDefect
        id
        funclocEditable
        mandatory
        name
        oldValue
        options
        readOnly
        seqNumber
        typename
        unit
        value
    }
`;

export default FragmentFormElementField;
