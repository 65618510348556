import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { i18n } from '@geomagic/i18n';
import { DEFAULT_TRIGGER_ICON_PROPS } from '@consts';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        pointerEvents: 'auto',
    },
    triggerFab: {
        backgroundColor: palette.background.paper,
        marginTop: spacing(),
    },
    speedDial: {
        backgroundColor: palette.background.paper,
    },
}));
const TriggerModifyInteractions = props => {
    const { handleToggleOpen, modifiedFeature, onDelete, onModify, open, selectedFeature } = props;
    const classes = useStyles(props);

    return (
        <SpeedDial
            ariaLabel="modifyInteractions"
            className={classes.root}
            FabProps={{
                ...DEFAULT_TRIGGER_ICON_PROPS,
                className: classes.speedDial,
                onClick: handleToggleOpen,
            }}
            icon={<SpeedDialIcon icon={<EditIcon />} openIcon={<CloseIcon />} />}
            open={open}
        >
            {open && [
                <SpeedDialAction
                    key="saveGeometryAction"
                    icon={<SaveIcon />}
                    tooltipTitle={i18n.t('map.tooltip.saveSelectedGeometry')}
                    FabProps={{
                        ...DEFAULT_TRIGGER_ICON_PROPS,
                        className: classes.triggerFab,
                        disabled: !modifiedFeature,
                    }}
                    onClick={onModify}
                />,
                <SpeedDialAction
                    key="deleteGeometryAction"
                    icon={<DeleteIcon />}
                    tooltipTitle={i18n.t('map.tooltip.removeSelectedGeometry')}
                    FabProps={{
                        ...DEFAULT_TRIGGER_ICON_PROPS,
                        className: classes.triggerFab,
                        disabled: !selectedFeature,
                    }}
                    onClick={onDelete}
                />,
            ]}
        </SpeedDial>
    );
};

TriggerModifyInteractions.propTypes = {
    modifiedFeature: PropTypes.object,
    onDelete: PropTypes.func.isRequired,
    onModify: PropTypes.func.isRequired,
    selectedFeature: PropTypes.object,
};

export default TriggerModifyInteractions;
