/**
 * Get File by specific url
 * @param {string} url - Download url
 * @param {string} name - File name
 * @param {Object} options - File options
 * @returns {File}
 */

const getFileByDownloadUrl = async ({ url, name, options }) => {
    try {
        const response = await fetch(url);

        if (response.status >= 200 && response.status <= 299) {
            const blob = await response.blob();
            const file = new File([blob], name, options);

            return file;
        }
    } catch (error) {
        console.error(`COULD NOT FETCH FILE FROM URL: ${url}`);
    }
};

export default getFileByDownloadUrl;
