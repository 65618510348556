import compact from 'lodash/compact';

/**
 * Create groups by GeoNAM grouping array
 * @param {Array} grouping - GeoNAM grouping array
 * @returns {Array.<Object>}
 */

const getLayerGroups = (grouping, isVisible) => {
    let layerGroups;
    if (grouping) {
        layerGroups = compact(grouping).reduce((prev, next) => {
            const groupingVar = [
                ...prev,
                {
                    _id: next,
                    name: next,
                    layerPackage: 'default',
                    isVisible,
                    isBackground: false,
                    values: null,
                    layerValues: { opacity: 1 },
                    type: 'Group',
                    filter: null,
                    ...(prev.length > 0 && { parent: prev[prev.length - 1]._id }),
                },
            ];
            return groupingVar;
        }, []);
    }

    return layerGroups;
};

export default getLayerGroups;
