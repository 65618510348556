import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import DocumentIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';

import DocumentList from '@components/DocumentList';
import Placeholder from '@components/Placeholder';
import getFilePropsFromDocumentEntity from '@database/getFilePropsFromDocumentEntity';
import getImagesFromAttachments from '@database/getImagesFromAttachments';
import ImageCarousel from '@image/ImageCarousel';
import ImageStepper from '@image/ImageStepper';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing, transitions, typography }) => {
    return {
        root: {
            display: 'flex',
            height: '100%',
            width: '100%',
            [breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        documents: {
            display: 'flex',
            transition: transitions.create(['height', 'width'], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),
            '&$open': {
                borderRight: `1px solid ${palette.divider}`,
                height: '100%',
                width: '55%',
                [breakpoints.down('sm')]: {
                    borderBottom: `1px solid ${palette.divider}`,
                    height: '50%',
                    width: '100%',
                },
            },
        },
        images: {
            backgroundColor: palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
            transition: transitions.create(['height', 'width'], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),
            '&$open': {
                height: '100%',
                width: '45%',
                [breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    height: '50%',
                    width: '100%',
                },
            },
        },
        imageCarousel: {
            flex: 1,
            position: 'relative',
            overflow: 'hidden',
        },
        triggerClose: {
            background: palette.action.active,
            color: palette.getContrastText(palette.action.active),
            position: 'absolute',
            right: spacing(),
            top: spacing(),
            zIndex: 1,
            '&:hover': {
                background: palette.action.active,
                color: palette.getContrastText(palette.action.active),
            },
        },
        open: {},
    };
});

const DocumentListImageViewer = props => {
    const classes = useStyles(props);
    const {
        doc: databaseDoc,
        documents: initialDocuments = [],
        imageType = 'image/',
        isMobile,
        onDelete,
        placeholderAction,
        PlaceholderIconComponent,
        placeholderSubtitle,
        placeholderTitle,
    } = props;

    const [activeRowId, setActiveRowId] = useState(null);
    const [activeStep, setActiveStep] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const maxSteps = images.length;
    const open = activeStep !== null;

    /**
     *  EVENT HANDLER
     */

    const handleBack = () => {
        handleStepChange(activeStep - 1);
    };

    const handleClose = () => {
        handleStepChange(null);
    };

    const handleDelete = filteredDocuments => {
        setActiveStep(null);
        setActiveRowId(null);
        setDocuments(filteredDocuments);
        onDelete && onDelete(filteredDocuments);
    };

    const handleNext = () => {
        handleStepChange(activeStep + 1);
    };

    const handleRowClick = item => {
        const imageIndex = images.findIndex(image => image.id === item.hash);
        handleStepChange(imageIndex);
    };

    const handleStepChange = step => {
        if (isNumber(step)) {
            const image = images[step];
            setActiveRowId(image.id);
            setActiveStep(step);
        } else {
            setActiveRowId(null);
            setActiveStep(null);
        }
    };

    /**
     *  EFFECTS
     */

    useEffect(() => {
        setDocuments(initialDocuments);
    }, [initialDocuments]);

    useEffect(() => {
        const getImages = async () => {
            const imageDocuments = documents
                .map(doc => getFilePropsFromDocumentEntity(doc))
                .filter(doc => doc?.type?.startsWith(imageType));

            if (imageDocuments.length > 0) {
                const _images = await getImagesFromAttachments(imageDocuments, databaseDoc);

                setImages(_images);
            }
        };

        getImages();
    }, [databaseDoc, documents, imageType]);

    const enhancedDocuments = documents.map(doc => {
        const enhancedDoc = getFilePropsFromDocumentEntity(doc);
        const isImage = enhancedDoc?.type?.startsWith(imageType);

        return {
            item: enhancedDoc,
            ...(isImage && { button: true, onClick: handleRowClick }),
        };
    });

    return (
        <div className={classes.root}>
            <ContentRoot
                className={classNames(classes.documents, { [classes.open]: open })}
                scrollable
                withPadding={false}
            >
                {enhancedDocuments.length > 0 ? (
                    <DocumentList
                        activeId={activeRowId}
                        doc={databaseDoc}
                        documents={enhancedDocuments}
                        isOnline
                        onDelete={handleDelete}
                    />
                ) : (
                    <Placeholder
                        action={placeholderAction}
                        icon={PlaceholderIconComponent ? <PlaceholderIconComponent /> : <DocumentIcon />}
                        subtitle={placeholderSubtitle || i18n.t('placeholder.uploadDocument.subtitle')}
                        title={placeholderTitle || i18n.t('placeholder.uploadDocument.title')}
                    />
                )}
            </ContentRoot>

            <div className={classNames(classes.images, { [classes.open]: open })}>
                <div className={classes.imageCarousel}>
                    <Trigger
                        className={classes.triggerClose}
                        color="inherit"
                        icon={<CloseIcon />}
                        onClick={handleClose}
                        variant="icon"
                    />
                    <ImageCarousel activeStep={activeStep} images={images} onChangeStep={handleStepChange} />
                </div>
                <div className={classes.imageStepper}>
                    <ImageStepper
                        activeStep={activeStep}
                        isMobile={isMobile}
                        maxSteps={maxSteps}
                        onBack={handleBack}
                        onNext={handleNext}
                    />
                </div>
            </div>
        </div>
    );
};

DocumentListImageViewer.propTypes = {
    className: PropTypes.string,
    doc: PropTypes.object.isRequired,
    documents: PropTypes.array,
    imageType: PropTypes.string,
    isMobile: PropTypes.bool,
    onDelete: PropTypes.func,
    placeholderAction: PropTypes.node,
    PlaceholderIconComponent: PropTypes.object,
    placeholderSubtitle: PropTypes.string,
    placeholderTitle: PropTypes.string,
};

export default DocumentListImageViewer;
