import { Draw, Snap } from '@geomagic/ol/interaction';

/**
 * Add draw and snap interaction to OpenLayers map
 * @param {Object} params
 * @param {Object} params.map - OpenLayers map
 * @param {Object} params.featureLayer - OpenLayers layer
 * @param {Object} params.geometryStyle - GeoNAM FunclocGeomStyleCat
 * @param {function} params.onDrawed - Function which is called after drawing
 * @returns {Array.<Object>}
 */

const enableDrawInteraction = ({ map, featureLayer, geometryStyle, onDrawed }) => {
    const source = featureLayer.getSource();

    let geometryType; // TODO check if it's viable to use GeoJSON types in geometryStyle.type directly
    if (geometryStyle.type === 'LINE') {
        geometryType = 'LineString';
    } else if (geometryStyle.type === 'POINT') {
        geometryType = 'Point';
    } else if (geometryStyle.type === 'POLYGON') {
        geometryType = 'Polygon';
    } else {
        console.log('Interaction for', geometryStyle.type, 'not supported');
        return [];
    }

    const snap = new Snap({ source });
    const draw = new Draw({
        source,
        type: geometryType,
    });

    draw.on('drawend', event => {
        onDrawed(event, event.feature);
    });

    map.addInteraction(snap);
    map.addInteraction(draw);

    return [draw, snap];
};

export default enableDrawInteraction;
