import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import ForumIcon from '@material-ui/icons/Forum';

import { i18n } from '@geomagic/i18n';

import Placeholder from '@components/Placeholder';

const useStyles = makeStyles(({ palette, shape, spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '&$reverse': {
            flexDirection: 'column-reverse',
        },
    },

    chat: {
        display: 'flex',
        flexDirection: 'column',
        margin: spacing(),
        padding: spacing(),
        borderRadius: shape.borderRadius,
        backgroundColor: palette.type === 'light' ? palette.grey[200] : palette.grey[700],
        width: '80%',
        '&$isSelf': {
            backgroundColor: palette.type === 'light' ? lighten(palette.primary.main, 0.75) : palette.primary.main,
            alignSelf: 'flex-end',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: spacing(),
    },
    isSelf: {},
    footer: {
        marginTop: spacing(),
    },
    message: {
        marginBottom: spacing(),
        whiteSpace: 'pre-line',
    },
    placeholderContainter: {
        display: 'flex',
        height: '100%',
    },
    reverse: {},
}));

const Chat = props => {
    const classes = useStyles(props);
    const { chat, className, id, onFormatFooter, onFormatHeader, user, reverse } = props;

    return chat.length > 0 ? (
        <div id={id} className={classNames(classes.root, { [classes.reverse]: reverse }, className)}>
            {chat.map(({ message, ...rest }, index) => {
                const { date, user: messageUser } = rest;
                const isLastMessage = index === 0;
                const isSelf = messageUser.id === user.id;
                const params = { ...rest, isLastMessage, isSelf, message };

                return (
                    <div key={`${date}_${index}`} className={classNames(classes.chat, { [classes.isSelf]: isSelf })}>
                        {onFormatHeader && <div className={classes.header}>{onFormatHeader(params, index)}</div>}
                        <Typography variant="body2" className={classes.message}>
                            {message}
                        </Typography>
                        {onFormatFooter && <div className={classes.footer}>{onFormatFooter(params, index)}</div>}
                    </div>
                );
            })}
        </div>
    ) : (
        <div className={classes.placeholderContainter}>
            <Placeholder
                icon={<ForumIcon />}
                title={i18n.t('placeholder.noEntries.title')}
                subtitle={i18n.t('placeholder.noEntries.subtitle')}
            />
        </div>
    );
};

Chat.propTypes = {
    className: PropTypes.string,
    chat: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            date: PropTypes.string,
            user: PropTypes.object,
            message: PropTypes.string,
        }).isRequired
    ),
    id: PropTypes.string,
    onFormatFooter: PropTypes.func,
    onFormatHeader: PropTypes.func,
    user: PropTypes.object,
};

export default Chat;
