/**
 * A form to create and update an entity.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import { AutoForm } from '@geomagic/forms';
import {
    getRawAttributeValue,
    getTypedAttributeValue,
    getAttributeTypesByClassAndType,
    compareByReference,
    getEntityClass,
    getReference,
} from '@geomagic/geonam';

import useResizeObserver from '@utils/useResizeObserver';
import { getAutoFormPropsByAttributeType, getDataGroupsFromFields, formValidationHandler } from '../utils';
import DataGroupItem from './DataGroupItem';

const ADDITIONAL_WIDTH = 40;

const createFormSchemaAndUI = (attributeTypes, entity, isReadOnly) => {
    const requiredList = [];
    const properties = {};

    const ui = {};

    attributeTypes.forEach((attributeType, index) => {
        if (!attributeType.readOnly || entity) {
            const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType(attributeType, entity, isReadOnly);

            if (fieldSchema) {
                properties[index] = { ...fieldSchema, identifier: attributeType.id };
            }

            ui[index] = fieldUI;

            if (attributeType.mandatory && !attributeType.readOnly) {
                requiredList.push(index.toString());
            }
        }
    });

    return {
        schema: {
            type: 'object',
            required: requiredList,
            properties,
        },
        ui,
    };
};

const getAttributeValues = (attributeTypes, values) => {
    return attributeTypes.map((attributeType, index) => {
        const typedValue = values[String(index)];

        return {
            attributeType: getReference(attributeType),
            value: getRawAttributeValue(attributeType, typedValue),
        };
    });
};

const EntityForm = props => {
    const {
        children,
        defaultValues: initialValues,
        entity,
        entityClasses,
        entityTypeCode,
        expandedGroups = {},
        formId,
        onCancel,
        prefilledAttributeValues = [],
        isReadOnly,
        isRequiredFieldsOnly,
        setExpandedGroups = () => {},
        ...autoFormProps
    } = props;
    let { entityClassName, entityTypeId } = props;
    entityClassName = entity ? entity.className : entityClassName;
    entityTypeId = (entity && entity.entityType && entity.entityType.id) || entityTypeId;

    const [ref, { width }] = useResizeObserver();

    const entityClass = getEntityClass(entityClasses, entityClassName);
    const entityType = entityClass.entityTypes.find(({ id, code }) => {
        return (entityTypeCode && code === entityTypeCode) || (entityTypeId && id === entityTypeId);
    });

    if (!entityType) {
        throw new Error('noEntityType');
    }

    const attributeTypes = getAttributeTypesByClassAndType(entityClasses, entityClassName, entityType?.id);
    const [schemaUI, setSchemaUI] = useState(() => createFormSchemaAndUI(attributeTypes, entity));
    const { schema, ui } = schemaUI;

    const defaultValues = {};

    let entityId;
    if (entity) {
        entityId = entity && entity.id;

        attributeTypes.forEach((aT, index) => {
            const attributeValue = entity?.attributeValues?.find(aV => compareByReference(aT, aV.attributeType));
            const typedValue = getTypedAttributeValue(aT, attributeValue?.value);

            if (typedValue !== null && typedValue !== undefined) {
                defaultValues[index] = typedValue;
            }
        });
    } else {
        initialValues
            ? initialValues.forEach((item, index) => {
                  const attributeType = attributeTypes.find(({ id }) => id === item?.attributeType?.id);
                  const value = item?.value;

                  if (attributeType && value) {
                      defaultValues[index] = getTypedAttributeValue(attributeType, value);
                  }
              })
            : attributeTypes.forEach((aT, index) => {
                  const presetDefault = prefilledAttributeValues.find(PAV => PAV.attributeTypeId === aT.id);
                  const defaultValue = (presetDefault && presetDefault.value) || aT.defaultValue;
                  const typedValue = getTypedAttributeValue(aT, defaultValue);

                  if (typedValue !== null && typedValue !== undefined) {
                      defaultValues[index] = typedValue;
                  }
              });
    }

    /**
     *  EVENT HANDLER
     */

    const buildNewEntity = values => {
        return getAttributeValues(attributeTypes, values);
    };

    const isCustomChildren = isFunction(children);

    /**
     *  EFFECTS
     */

    useEffect(() => {
        setSchemaUI(createFormSchemaAndUI(attributeTypes, entity, isReadOnly));
        // Only change if the entityTypeId is changed. AttributeTypes is changing constantly.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityTypeId, entity]);

    return (
        <div ref={ref}>
            <AutoForm
                id={formId ? formId : 'entityForm_' + entityId}
                schema={schema}
                ui={ui}
                defaultValues={defaultValues}
                onError={formValidationHandler}
                {...autoFormProps}
                onSubmit={(values, formContext) => {
                    autoFormProps.onSubmit && autoFormProps.onSubmit(buildNewEntity(values), formContext);
                }}
                onChange={(values, formContext) => {
                    autoFormProps.onChange && autoFormProps.onChange(buildNewEntity(values), formContext);
                }}
            >
                {(fields, formContext) => {
                    const attributeFieldsSorted = [];

                    attributeTypes.forEach(attributeType => {
                        const fieldComponent = fields.find(
                            field => field?.props?.definition?.identifier === attributeType.id
                        );

                        if (fieldComponent) {
                            if (isRequiredFieldsOnly) {
                                if (fieldComponent.props?.definition?._isRequired) {
                                    attributeFieldsSorted.push(fieldComponent);
                                }
                            } else {
                                attributeFieldsSorted.push(fieldComponent);
                            }
                        }
                    });

                    const dataGroups =
                        expandedGroups && setExpandedGroups
                            ? getDataGroupsFromFields(attributeFieldsSorted)
                            : attributeFieldsSorted;

                    const extendedFields = dataGroups.map(dataGroup => (
                        <DataGroupItem
                            dataGroup={dataGroup}
                            depth={0}
                            elements={fields}
                            expandedGroups={expandedGroups}
                            getId={element => element.key}
                            key={dataGroup.key}
                            path={[dataGroup.name]}
                            setExpandedGroups={setExpandedGroups}
                            width={width + ADDITIONAL_WIDTH}
                        />
                    ));

                    return isCustomChildren
                        ? children(
                              extendedFields,
                              {
                                  ...formContext,
                                  submit: async () => {
                                      const values = await formContext.submit();
                                      return buildNewEntity(values);
                                  },
                                  attributeTypes,
                                  previousValues: buildNewEntity(defaultValues),
                              },
                              fields
                          )
                        : extendedFields;
                }}
            </AutoForm>
        </div>
    );
};

EntityForm.propTypes = {
    entityClasses: PropTypes.array.isRequired,
    entityClassName: PropTypes.string,
    entityTypeCode: PropTypes.string,
    entityTypeId: PropTypes.number,
    entity: PropTypes.object,
    expandedGroups: PropTypes.object,
    isRequiredFieldsOnly: PropTypes.bool,
    onCancel: PropTypes.func,
    children: PropTypes.func,
    formId: PropTypes.string,
    prefilledAttributeValues: PropTypes.array,
    relations: PropTypes.array,
    setExpandedGroups: PropTypes.func,
};

export default EntityForm;
