import { useEffect, useState } from 'react';
import addVectorLayer from './addVectorLayer';
import cloneFeature from './cloneFeature';
import enableDrawInteraction from './enableDrawInteraction';
import removeInteractions from './removeInteractions';

/**
 * A hook to add a draw interaction to the map
 * @param {Object} params
 * @param {Object} params.mapRef - Ref of the map
 * @param {Object} params.geometryStyle - GeoNAM FunclocGeomStyleCat
 * @param {Object} params.style - Geometry style
 * @param {Object} params.handler - Interaction event handler
 * @returns {Object}
 */

const useDrawInteraction = ({ mapRef, geometryStyle, style }, handler = {}) => {
    const [drawedFeature, setDrawedFeature] = useState();
    const { onDrawed } = handler;

    useEffect(() => {
        const map = mapRef?.current?.map;
        const interactions = [];
        let featureLayer;

        if (map && geometryStyle) {
            featureLayer = addVectorLayer(map, style);

            const interaction = enableDrawInteraction({
                map,
                featureLayer,
                geometryStyle,
                onDrawed: (event, feature) => {
                    const clonedFeature = cloneFeature(feature);

                    onDrawed && onDrawed(event, clonedFeature);
                    setDrawedFeature(clonedFeature);
                },
            });

            interactions.push(...interaction);
        }

        return () => {
            // console.log('CLEARED DRAW INTERACTION');
            if (map) {
                setDrawedFeature(null);
                removeInteractions(map, interactions);
                map.removeLayer(featureLayer);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, onDrawed, geometryStyle]);

    return { drawedFeature };
};

export default useDrawInteraction;
