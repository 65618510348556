import pointer from 'json-pointer';
import cloneDeep from 'lodash/cloneDeep';
import { getReference } from '@geomagic/geonam';

import { RELEVANT_DISPATCH_PATH } from '@graphql/consts';

const transform = async (database, assignment) => {
    const clonedAssignment = cloneDeep(assignment);
    const { id, relevantDispatches = [] } = assignment;
    const currentRelevantDispatches = relevantDispatches.map(relevantDispatch => getReference(relevantDispatch));

    const selector = { 'draft.closed': false, relations: { $elemMatch: { id } } };
    const docs = await database.dispatches.find({ selector }).exec();

    if (docs?.length > 0) {
        docs.forEach(doc => {
            const block = doc?.relations.find(({ type }) => type === 'FormElementBlock');

            const patchedDispactch = doc.getPatchedEntity();
            let newDispatch = getReference(patchedDispactch);

            if (block) {
                const { relevantDispatches: relevantBlockDispatches = [] } = pointer.get(assignment, block.path);

                pointer.set(clonedAssignment, `${block.path}${RELEVANT_DISPATCH_PATH}`, [
                    ...relevantBlockDispatches,
                    newDispatch,
                ]);

                newDispatch = { ...newDispatch, blockId: block?.id };
            }

            pointer.set(clonedAssignment, RELEVANT_DISPATCH_PATH, [...currentRelevantDispatches, newDispatch]);
        });
    }

    return clonedAssignment;
};

export default transform;
