import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Group from '@geomagic/ol/layer/Group';
import { getLayerStoreInstance, getRootLayerGroup, isFeatureVisible, setFeatureVisible } from '@geomagic/map';

import GroupListItem from './GroupListItem';
import LayerListItem from './LayerListItem';
import List from '@material-ui/core/List';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: spacing(),
        pointerEvents: 'auto',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(2),
        paddingTop: 0,
    },
    button: {
        flex: 1,
        marginRight: spacing(2),
        '&:last-child': {
            marginRight: 0,
        },
    },
    listPadding: {
        paddingLeft: '0px',
    },
    list: {
        width: '100%',
    },
}));

const getNestedIds = layer => {
    const layerIds = [];
    getNestedIdsLayers(layerIds, layer);

    return layerIds;
};
const getNestedIdsLayers = (layerIds, layer) => {
    if (layer instanceof Group) {
        layerIds.push(layer.get('layerId'));
        layer
            .getLayers()
            .getArray()
            .forEach(l => {
                getNestedIdsLayers(layerIds, l);
            });
    } else {
        layerIds.push(layer.get('layerId'));
    }
};

const isLayerInRoot = (layers, layerId) => {
    return layers.map(layer => layer.get('layerId')).includes(layerId);
};

const getLayerStructure = (layers, olLayers, parent) => {
    return layers.reduce((acc, layer) => {
        const { _id, parent: layerParent } = layer;
        if ((!parent && !layerParent) || parent === layerParent) {
            const olLayer = olLayers.find(item => item.get('mongoDbId') === _id);
            acc.push({
                id: olLayer.values_.layerId,
                childs: olLayer instanceof Group ? getLayerStructure(layers, olLayer.getLayers().getArray(), _id) : 0,
                olLayer,
            });
        }
        return acc;
    }, []);
};

const findLayerInArray = (layers, layerId) => {
    return layers.find(layer => layer.layerId === layerId);
};

/**
 * Search for the parent and ancestors of this parent layer in a structure recursively.
 *
 * Set the visibility to the new child value and adjacent layers.
 *
 * @param {object} map
 * @param {array} layerStructure
 * @param {string} layerParentID
 * @returns {array} parentLayers
 */
const searchForParentLayersAndSetVisibility = (map, layerStructure, layerParentID) => {
    const parentLayers = [];
    const descendantsVisibility = [];

    layerStructure.forEach(layer => {
        let isParentFound = false;
        const { childs, id: layerId } = layer;

        if (childs) {
            if (layerId === layerParentID) {
                isParentFound = true;
            }

            if (!isParentFound) {
                const descendants = searchForParentLayersAndSetVisibility(map, childs, layerParentID);
                if (descendants.length) {
                    isParentFound = true;
                    parentLayers.push(...descendants);
                }
            }

            if (isParentFound) {
                childs.forEach(childLayer => {
                    const { id: childLayerId } = childLayer;
                    const foundParentLayer = parentLayers.find(parentLayer => parentLayer.id === childLayerId);

                    if (foundParentLayer) {
                        descendantsVisibility.push(foundParentLayer.isVisible ? 1 : 0);
                    } else {
                        descendantsVisibility.push(isFeatureVisible(childLayer.id, map) ? 1 : 0);
                    }
                });
                parentLayers.push({
                    id: layerId,
                    isVisible: Math.max(...descendantsVisibility) === 1,
                });
            }
        }
    });

    return parentLayers;
};

const LayerTreeView = props => {
    const { className, layers = [], map, subheader, subheaderProps } = props;
    const classes = useStyles(props);

    const allLayers = getRootLayerGroup(map).filter(item => !item.get('isBackground'));
    const layerSelectionStore = getLayerStoreInstance(map.values_.view.options_.mapId);
    const overlayLayers = layers.filter(item => !item.isBackground);

    const currentSavedLayers = useRef([]);
    const [layerStructure] = useState(() => getLayerStructure(overlayLayers, map.getLayers().getArray()));
    const [selectedLayers, setSelectedLayers] = useState(layerSelectionStore.getLayerSelection().selectedLayers);

    /**
     *  EVENT HANDLER
     */

    const handleLayerClick = layer => {
        const layerId = layer.get('layerId');
        const isVisible = isFeatureVisible(layerId, map);
        const newSelectedLayers = [];

        if (isLayerInRoot(allLayers, layerId)) {
            const nestedLayerIDs = getNestedIds(layer);
            nestedLayerIDs.forEach(id => {
                newSelectedLayers.push({ layerId: id, isVisible: !isVisible });
                setFeatureVisible(map, id, !isVisible);
            });
        } else {
            const layerParentID = layer.get('parentId');
            if (layer instanceof Group) {
                const nestedLayerIDs = getNestedIds(layer);
                nestedLayerIDs.forEach(id => {
                    newSelectedLayers.push({ layerId: id, isVisible: !isVisible });
                    setFeatureVisible(map, id, !isVisible);
                });
            } else {
                newSelectedLayers.push({ layerId, isVisible: !isVisible });
                setFeatureVisible(map, layerId, !isVisible);
            }
            const foundParentLayers = searchForParentLayersAndSetVisibility(map, layerStructure, layerParentID);
            foundParentLayers.forEach(element => {
                newSelectedLayers.push({ layerId: element.id, isVisible: element.isVisible });
                setFeatureVisible(map, element.id, element.isVisible);
            });
        }
        setSelectedLayers(
            selectedLayers.map(selectedLayer => {
                return (
                    findLayerInArray(newSelectedLayers, selectedLayer.layerId) ||
                    findLayerInArray(currentSavedLayers.current, selectedLayer.layerId)
                );
            })
        );
    };

    const getState = layer => {
        /**
         * state 0: checkbox is not checked
         * state 1: checkbox is checked
         * state -1: is only for groups ->  checkbox is indeterminate
         * stateArray is pushed with the states of their childs
         * the math max/min operations returns the max/min state of all their childs
         * a.e. [0,1,0,1,0,0,0] means, that not all layer must be visible --> state is -1
         * a.e. [0,0,0,0,0,0,0] means, that all layers are not visible --> state is 0
         * a.e. [1,1,1,1,1,1,1] means, that all layers are visible --> state is 1
         */
        const stateArray = [];

        if (layer instanceof Group) {
            const children = layer.getLayers().array_;
            children.forEach(child => {
                if (Math.min(...stateArray) === -1 || Math.min(...stateArray) === 1) {
                    setFeatureVisible(map, layer.values_.layerId, true);
                }
                stateArray.push(getState(child));
            });

            const minOfStateArray = Math.min(...stateArray);
            const maxOfStateArray = Math.max(...stateArray);

            return minOfStateArray !== maxOfStateArray ? -1 : minOfStateArray;
        } else {
            stateArray.push(layer.getVisible() ? 1 : 0);
            return layer.getVisible() ? 1 : 0;
        }
    };

    /**
     *  EFFECTS
     */

    useEffect(() => {
        currentSavedLayers.current = selectedLayers;
        layerSelectionStore.saveLayerSelection({ selectedLayers });
    }, [layerSelectionStore, selectedLayers]);

    return (
        <Paper className={classNames(classes.root, className)} square>
            {subheader && (
                <ListSubheader className={classes.subheader} {...subheaderProps}>
                    {subheader}
                </ListSubheader>
            )}
            <div className={classes.content}>
                <List className={classes.list} disablePadding>
                    {layerStructure.map(({ id, olLayer: layer, childs }) => {
                        if (layer instanceof Group) {
                            return (
                                <GroupListItem
                                    key={id}
                                    level={0}
                                    layer={layer}
                                    childs={childs}
                                    map={map}
                                    openGroups={overlayLayers.length < 10}
                                    onHandleLayerClick={handleLayerClick}
                                    isState={getState(layer)}
                                    getState={getState}
                                />
                            );
                        } else {
                            return (
                                <LayerListItem
                                    key={id}
                                    level={0}
                                    layer={layer}
                                    map={map}
                                    onHandleLayerClick={handleLayerClick}
                                    isState={getState(layer)}
                                />
                            );
                        }
                    })}
                </List>
            </div>
        </Paper>
    );
};

LayerTreeView.propTypes = {
    className: PropTypes.string,
    layers: PropTypes.array,
    map: PropTypes.object.isRequired,
    subheader: PropTypes.node,
    subheaderProps: PropTypes.object,
};

export default LayerTreeView;
