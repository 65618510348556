/**
 * Get a specific key
 * @param {string} value - All available forms
 * @param {number} index - Index
 * @returns {Object}
 */

const getKey = (value, index) => {
    return `${value}_${index}`;
};

export default getKey;
