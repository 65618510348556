import Vector from '@geomagic/ol/layer/Vector';
import VectorSource from '@geomagic/ol/source/Vector';
import { Fill, Circle, Stroke, Style } from '@geomagic/ol/style';

/**
 * Get a custom tracking overlay layer
 * @returns {Object}
 */

const getTrackingOverlay = () => {
    const trackingOverlay = new Vector({
        zIndex: 1000,
        source: new VectorSource(),
        visible: true,
        updateWhileAnimating: true,
        updateWhileInteracting: true,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.3)',
            }),
            stroke: new Stroke({
                color: '#008000',
                width: 1,
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#008000',
                }),
            }),
        }),
    });

    trackingOverlay.set('name', 'careTrackingOverlay');

    return trackingOverlay;
};

export default getTrackingOverlay;
