import { useEffect, useState } from 'react';
import Collection from '@geomagic/ol/Collection';
import addVectorLayer from './addVectorLayer';
import cloneFeature from './cloneFeature';
import enableModifyInteraction from './enableModifyInteraction';
import removeInteractions from './removeInteractions';

/**
 * A hook to add a modify interaction to the map
 * @param {Object} params
 * @param {Object} params.mapRef - Ref of the map
 * @param {Object} params.feature - Openlayers feature
 * @param {boolean} params.isEditable -
 * @param {Object} params.style - Geometry style
 * @param {Object} params.handler - Interaction event handler
 * @returns {Object}
 */

const useModifyInteraction = ({ mapRef, feature, isEditable, style }, handler = {}) => {
    const [modifiedFeature, setModifiedFeature] = useState();
    const { onModified } = handler;

    useEffect(() => {
        const map = mapRef?.current?.map;
        const interactions = [];
        let featureLayer;

        if (map && isEditable && feature) {
            const clonedFeature = cloneFeature(feature);

            const features = new Collection([clonedFeature]);
            featureLayer = addVectorLayer(map, style);
            featureLayer.getSource().addFeature(clonedFeature);

            const interaction = enableModifyInteraction({
                map,
                features,
                onModified: (event, modifyFeature) => {
                    onModified && onModified(event, modifyFeature);
                    setModifiedFeature(modifyFeature);
                },
            });
            interactions.push(...interaction);
        }

        return () => {
            console.log('CLEARED MODIFY INTERACTION');
            if (map) {
                setModifiedFeature(null);
                removeInteractions(map, interactions);
                map.removeLayer(featureLayer);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, onModified, isEditable, feature]);

    return { modifiedFeature };
};

export default useModifyInteraction;
