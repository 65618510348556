import React from 'react';
import PropTypes from 'prop-types';
import FormElementFieldInput from './FormElementFieldInput';

const FormElementField = props => {
    return <FormElementFieldInput {...props} />;
};

FormElementField.propTypes = {
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    doc: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default FormElementField;
