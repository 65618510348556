import gql from 'graphql-tag';

export const FragmentLayer = gql`
    fragment Layer on Layer {
        className
        filterable
        grouping
        id
        name
        options
        seqNumber
        sourceOptions
        sourceType
        type
        visible
    }
`;

export default FragmentLayer;
