import { VIEW_CODE } from '../consts';

/**
 * Get a view by code
 * @param {Array.<Object>} worlds
 * @param {string|undefined} code
 * @returns {Object|undefined}
 */

const getViewByCode = (views = [], code) => {
    const view = views.find(view => view?.code === (code || VIEW_CODE));

    return view;
};

export default getViewByCode;
