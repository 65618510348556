import { Stroke, Style } from '@geomagic/ol/style';

/**
 * Get an object with styles for geometry types
 * @param {Object} params
 * @returns {Object}
 */

const getFeatureStyle = (params = {}) => {
    const { backgroundColor, borderColor = '#ffffff', borderWidth = 2, width = 5, zIndex = 2 } = params;

    return [
        new Style({
            stroke: new Stroke({
                color: borderColor,
                width: width + borderWidth,
            }),
            zIndex: zIndex,
        }),
        new Style({
            stroke: new Stroke({
                color: backgroundColor,
                width,
            }),
            zIndex,
        }),
    ];
};

export default getFeatureStyle;
