import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(
    theme =>
        createStyles({
            errorMessage: {
                backgroundColor:
                    (theme.palette.notification && theme.palette.notification.error) || theme.palette.error.dark,
            },
            iconStyle: {
                fontSize: 20,
                opacity: 0.9,
                marginRight: theme.spacing(),
            },
        }),
    { name: 'MapErrorNotification' }
);

const onClose = setError => {
    setError(false);
};

const MapErrorNotification = props => {
    const classes = useStyles(props);
    const { message, isError, setError } = props;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            autoHideDuration={null}
            open={isError}
            onClose={() => onClose(setError)}
        >
            <SnackbarContent
                className={classes.errorMessage}
                message={
                    <span className={classes.message}>
                        <ErrorIcon className={classes.iconStyle} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={() => onClose(setError)}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

MapErrorNotification.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string.isRequired,
    isError: PropTypes.bool.isRequired,
    setError: PropTypes.func.isRequired,
};

export default MapErrorNotification;
