/**
 * Get a specific form by a featureId
 * @param {Array} forms - All available forms
 * @param {string} featureId - FeatureId to find
 * @returns {Object}
 */

const getFormByFeatureId = (forms, featureId) => {
    let entity = {};

    if (featureId) {
        entity = forms.find(({ featureCollections = [] }) => {
            let hasFeature = false;
            const hasFeatureCollections = featureCollections.length > 0;

            if (hasFeatureCollections) {
                hasFeature = featureCollections[0].features.map(({ id }) => id).includes(featureId);
            }

            return hasFeature;
        });
    }

    return entity;
};

export default getFormByFeatureId;
