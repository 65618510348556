import gql from 'graphql-tag';
import FragmentDocument from './FragmentDocument';

const FragmentFormElementPictures = gql`
    ${FragmentDocument}

    fragment FormElementPictures on FormElementPictures {
        description
        displayedIfDefect
        documents {
            ...Document
        }
        id
        mandatory
        name
        options
        seqNumber
        typename
    }
`;

export default FragmentFormElementPictures;
