import convertImage from './convertImage';
import getHash from './getHash';
import getMetaData from './getMetaData';
import resizeImage from './resizeImage';
import { DEFAULT_IMAGE_TYPE } from './consts';

const getCompressedHashedFile = async file => {
    const { orientation } = await getMetaData(file);
    const canvas = await resizeImage(file, { orientation });
    const blob = await convertImage(canvas, { format: DEFAULT_IMAGE_TYPE });
    const compressedFile = new File([blob], file.name, { type: DEFAULT_IMAGE_TYPE });
    const hash = await getHash(compressedFile);

    return { hash, file: compressedFile };
};

export default getCompressedHashedFile;
