import { Vector as VectorLayer } from '@geomagic/ol/layer';
import { Vector as VectorSource } from '@geomagic/ol/source';

/**
 * Add a vectorLayer to map and style features
 * @param {Object} map - OpenLayers map
 * @param {Object|function} style - Feature styles
 * @returns {Object}
 */

const addVectorLayer = (map, style) => {
    const vectorSource = new VectorSource();
    const vectorLayer = new VectorLayer({
        source: vectorSource,
        ...(style && { style }),
    });

    map.addLayer(vectorLayer);

    return vectorLayer;
};

export default addVectorLayer;
