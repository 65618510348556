import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';

import ListSkeleton from './ListSkeleton';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    const isLightTheme = palette.type === 'light';

    return {
        root: {
            backgroundColor: palette.background.paper,
            flex: 1,
            overflow: 'auto',
            padding: 0,
            position: 'relative',
        },
        listSubheader: {
            backgroundColor: isLightTheme ? palette.grey[100] : palette.grey.A400,
            borderBottom: `1px solid ${palette.divider}`,
            zIndex: 2,
        },
        li: {
            listStyle: 'none',
        },
        ul: {
            margin: 0,
            padding: 0,
            backgroundColor: palette.background.paper,
        },
        listItem: {
            borderBottom: `1px solid ${palette.divider}`,
            backgroundColor: palette.background.paper,
            minHeight: 70,
            paddingLeft: spacing(),
            '&$firstItem': {
                borderTop: `1px solid ${palette.divider}`,
            },
        },
        listItemAvatar: {
            minWidth: 0,
        },
        listItemSecondaryAction: {
            right: spacing(),
        },
    };
});

const GroupedList = props => {
    const { activeId, additionalProps, className, groups = [], idKey, loading, PlaceholderComponent } = props;

    const listItemRefs = useRef([]);
    const classes = useStyles(props);

    /**
     *  EFFECTS
     */

    useEffect(() => {
        if (activeId) {
            // listItemRefs.current[activeId].scrollIntoView({
            //     block: 'center',
            //     behavior: 'smooth',
            // });
        }
    }, [activeId]);

    /**
     *  COMPONENTS
     */

    const list = groups.map(({ id, label, items = [], onClick, ActionsComponent, AvatarComponent, TextComponent }) => {
        return (
            <li key={id} className={classes.li}>
                <ul className={classes.ul}>
                    <ListSubheader className={classes.listSubheader}>{label}</ListSubheader>
                    {items.map(item => {
                        const itemId = idKey ? get(item, idKey) : item.id;

                        return (
                            <ListItem
                                ref={el => (listItemRefs.current[itemId] = el)}
                                key={itemId}
                                className={classes.listItem}
                                selected={itemId === activeId}
                                {...(onClick && { button: true, onClick: () => onClick(item) })}
                            >
                                {AvatarComponent && (
                                    <ListItemAvatar className={classes.listItemAvatar}>
                                        <AvatarComponent data={item} {...additionalProps} />
                                    </ListItemAvatar>
                                )}
                                {TextComponent && <TextComponent data={item} {...additionalProps} />}
                                {ActionsComponent && (
                                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                        <ActionsComponent data={item} {...additionalProps} />
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        );
                    })}
                    {items.length < 1 && PlaceholderComponent}
                </ul>
            </li>
        );
    });

    return (
        <List className={classNames(classes.root, className)} subheader={<li />}>
            {loading ? <ListSkeleton /> : list}
        </List>
    );
};

GroupedList.propTypes = {
    activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    groups: PropTypes.array,
    idKey: PropTypes.string,
    loading: PropTypes.bool,
    PlaceholderComponent: PropTypes.node,
};

export default GroupedList;
