import React, { Fragment } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import { toggleSidebar } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: 'auto !important',
        top: 'auto !important',
        width: '100% !important',

        '& > div': {
            width: '100% !important',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 'bold',
        padding: 8,
        textAlign: 'center',
    },
    listItem: {
        minHeight: 50,
    },
    listItemGroup: {
        paddingLeft: 24,
    },
    listSubheader: {
        backgroundColor: palette.type === 'light' ? palette.grey[100] : palette.grey.A400,
        borderBottom: `1px solid ${palette.divider}`,
        zIndex: 2,
    },
}));

/**
 * Provides an custom show modal function
 *
 * @param {Object} params
 * @param {*} params.content - modal content
 * @param {*} params.title - modal title
 * @param {function} params.onOk - modal ok function
 * @returns {void}
 */

const useToggleActionDrawer = () => {
    const classes = useStyles();

    const listItemTextProps = {
        noWrap: true,
        variant: 'body1',
    };

    const handleClick = onClick => event => {
        onClick && onClick(event);
        handleClose();
    };

    const handleClose = () => {
        toggleSidebar({ open: false });
    };

    const handleToogleSidebar = params => {
        const { items, title } = params;
        let currentGroup;

        toggleSidebar({
            anchor: 'bottom',
            isWithAppBar: false,
            PaperProps: { classes: { root: classes.root } },
            content: (
                <div className={classes.content}>
                    {title && (
                        <Typography className={classes.title} noWrap variant="body2">
                            {title}
                        </Typography>
                    )}
                    <List>
                        {items.map(({ color, disabled = false, group, id, label, onClick }) => {
                            const isNewGroup = group && group !== currentGroup;
                            if (isNewGroup) {
                                currentGroup = group;
                            }

                            return (
                                <Fragment key={id}>
                                    {isNewGroup && (
                                        <ListSubheader className={classes.listSubheader}>{group}</ListSubheader>
                                    )}
                                    <ListItem
                                        button
                                        className={classNames(classes.listItem, { [classes.listItemGroup]: !!group })}
                                        disabled={disabled}
                                        divider
                                        onClick={handleClick(onClick)}
                                    >
                                        <ListItemText
                                            primary={label}
                                            primaryTypographyProps={{ color, ...listItemTextProps }}
                                        />
                                    </ListItem>
                                </Fragment>
                            );
                        })}
                        <ListItem button onClick={handleClose}>
                            <ListItemText
                                primary={i18n.t('button.cancel')}
                                primaryTypographyProps={listItemTextProps}
                            />
                        </ListItem>
                    </List>
                </div>
            ),
        });
    };

    return handleToogleSidebar;
};

export default useToggleActionDrawer;
